import { AnyAction } from "redux";
import { ThunkAction } from "redux-thunk";
import { getDashboardData } from "../../services/dashboard.service";
import * as dashboardTypes from "./types";

const types = {
  ...dashboardTypes,
};


/**
 * action to fetch dashboard data
 * @returns 
 */
export const fetchDashboardData =
  (): ThunkAction<void, any, unknown, AnyAction> =>
  async(dispatch): Promise<void> => {
    const dashboardResponse = await getDashboardData();
    if (dashboardResponse.status === 200) {
      dispatch({ type: types.SHOW_DASHBOARD_SUCCESS, payload: dashboardResponse.data });
    } else {
      dispatch({ type: types.SHOW_DASHBOARD_ERROR });
    }
  };
