import { useState, useRef, useEffect } from "react";
import clsx from "clsx";
import { useDispatch, useSelector } from "react-redux";
import {
  AppBar,
  Toolbar,
  IconButton,
  Typography,
  Drawer,
  Divider,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Badge,
  Snackbar,
} from "@material-ui/core";
import {
  Menu as MenuIcon,
  AccountCircleRounded as AccountCircleRoundedIcon,
  ChevronLeft as ChevronLeftIcon,
  ChevronRight as ChevronRightIcon,
  Home as HomeIcon,
  History as HistoryIcon,
  LocationOn as LocationIcon,
  AssignmentInd as DriverIcon,
  LocalShipping as VehicleIcon,
  // Description as DocumentsIcon,
  Notifications as NotificationsIcon,
  Mail as MailIcon,
  SupervisorAccountOutlined as CustomerIcon,
  CreateNewFolderOutlined as ServiceRequestsIcon,
  KeyboardArrowDown,
} from "@material-ui/icons";
import { makeStyles, useTheme, Theme, alpha } from "@material-ui/core/styles";
import { logout } from "../redux/user/actions";
import AppLogo from "../assets/images/entuber-logo.png";
import Notifications from "./Notifications";
import Messages from "./Messages";
import { hideAlert } from "../redux/alerts/actions";
import { Alert } from "@material-ui/lab";
import { fetchProfileAction } from "../redux/profile/actions";
// import { showAllMessages } from "../redux/messages/actions";
import { Menu } from "primereact/menu";
import ForumIcon from "@material-ui/icons/Forum";
import { getDatabase, ref, onValue, set, child } from "firebase/database";

const drawerWidth = 240;

const useStyles = makeStyles((theme: Theme) => ({
  title: {
    flexGrow: 1,
  },
  containerStyle: {
    display: "flex",
    flexGrow: 1,
    width: "99vw",
  },
  appBar: {
    zIndex: 2,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerOpen: {
    zIndex: 1,
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: "nowrap",
  },
  drawerClose: {
    zIndex: 1,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: "hidden",
    width: theme.spacing(7) + 1,
    [theme.breakpoints.up("sm")]: {
      width: theme.spacing(9) + 1,
    },
  },
  toolbar: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
  },
  menuButton: {
    marginRight: 36,
  },
  hide: {
    display: "none",
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
  username: {
    fontSize: "14px",
    marginLeft: "10px",
  },
  verticalDivider: {
    backgroundColor: theme.palette.text.primary,
  },
  search: {
    position: "relative",
    borderRadius: theme.shape.borderRadius,
    backgroundColor: alpha(theme.palette.common.white, 0.15),
    "&:hover": {
      backgroundColor: alpha(theme.palette.common.white, 0.25),
    },
    marginRight: theme.spacing(2),
    marginLeft: 0,
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      marginLeft: theme.spacing(3),
      width: "auto",
    },
  },
  searchIcon: {
    padding: theme.spacing(0, 2),
    height: "100%",
    position: "absolute",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("md")]: {
      width: "20ch",
    },
  },
  switchLabel: {
    fontSize: "14px",
    fontWeight: "bold",
  },
  appLogo: {
    width: "150px",
    height: "90px",
  },
  userNameSpacing: {
    padding: theme.spacing(1, 1, 1, 1),
  },
  profileBadge: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    marginLeft: "1rem",
    backgroundColor: "inherit",
    color: "inherit",
    padding: "0 1rem",
    borderRadius: "5px",
    border: "1px solid lightgrey",
  },
}));

type AppBarType = {
  children?: JSX.Element;
  history: any;
};

const pathNameRef: any = {
  "/orders/order-summary": "Work Order Summary",
  "/view-service-request": "Service Request",
  "/service-requests": "Service Requests",
  "/add-schedule": "Add Schedule",
  "/customers": "Customer Management",
  "/admins": "Admin Management",
  "/dashboard": "Dashboard",
  "/orders": "Work Orders",
  "/order-tracker": "Asset Tracker",
  "/drivers": "Drivers",
  "/vehicles": "Vehicles",
  "/documents": "Documents Corner",
  "/user-profile": "Profile",
  "/messages": "Messages",
};

export default function AppBarComponent({
  children,
  history,
}: AppBarType): JSX.Element {
  const classes = useStyles();
  const theme = useTheme();
  const [open, setOpen] = useState(false);
  const [openNotifications, setOpenNotifications] = useState(false);
  const [openMessages, setOpenMessages] = useState(false);

  const [newMessages, setNewMessages] = useState([]);
  const [isDeletingAllMessages, setIsDeletingAllMessages] = useState(false);
  const [isDeletingMessageNoti, setIsDeletingMessageNoti] =
    useState<any>(false);

  const [newNotifications, setNewNotifications] = useState([]);
  const [isDeletingAllNotifications, setIsDeletingAllNotifications] =
    useState(false);
  const [isDeletingNoti, setIsDeletingNoti] = useState<any>(false);

  const anchorRef = useRef<HTMLButtonElement>(null);
  const menu = useRef<any>();
  const dispatch = useDispatch();
  const messages = useSelector((state: any) => state?.messages?.list);
  const currentTheme = useSelector((state: any) => state?.user?.theme);
  const alertState = useSelector((state: any) => state?.alerts);
  const userProfile = useSelector((state: any) => state?.profile);
  const userId = useSelector((state: any) => state.user?.userId);

  const serviceRequestSelected = useSelector(
    (state: any) => state?.serviceRequests?.serviceRequestSelected
  );
  const serviceRequests = useSelector(
    (state: any) => state?.serviceRequests?.list
  );

  const serviceRequest =
    serviceRequests &&
    serviceRequests.find((x: any) => x.requestId === serviceRequestSelected);

  // Fetch messages
  const db = getDatabase();

  useEffect(() => {
    (async function fetchDashboard() {
      await dispatch(fetchProfileAction());
    })();

    onValue(
      ref(
        db,
        `${process.env.REACT_APP_FIREBASE_DATABSE_MESSAGE_NOTIFICATIONS_PATH}/${userId}`
      ),
      (snapshot) => {
        setNewMessages(snapshot?.val() || []);
      }
    );

    onValue(
      ref(
        db,
        `${process.env.REACT_APP_FIREBASE_DATABSE_ALERTS_PATH}/${userId}`
      ),
      (snapshot) => {
        setNewNotifications(snapshot?.val() || []);
      }
    );

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const deleteSingleMessageNotification = (messageId: string) => {
    const itemIndex = newMessages?.findIndex(
      (mess: any) => mess.id === messageId
    );

    const itemRef = child(
      ref(
        db,
        `${process.env.REACT_APP_FIREBASE_DATABSE_MESSAGE_NOTIFICATIONS_PATH}/${userId}`
      ),
      itemIndex + ""
    );

    setIsDeletingMessageNoti(messageId);
    set(itemRef, null).then(() => {
      setIsDeletingMessageNoti(false);
    });
  };

  const deleteAllMessages = () => {
    setIsDeletingAllMessages(true);
    set(
      ref(
        db,
        `${process.env.REACT_APP_FIREBASE_DATABSE_MESSAGE_NOTIFICATIONS_PATH}/${userId}`
      ),
      null
    ).then(() => {
      setIsDeletingAllMessages(false);
    });
  };

  const deleteSingleNotification = (messageId: string) => {
    const itemIndex = newMessages?.findIndex(
      (mess: any) => mess.id === messageId
    );

    const itemRef = child(
      ref(
        db,
        `${process.env.REACT_APP_FIREBASE_DATABSE_ALERTS_PATH}/${userId}`
      ),
      itemIndex + ""
    );

    setIsDeletingNoti(messageId);
    set(itemRef, null).then(() => {
      setIsDeletingNoti(false);
    });
  };

  const deleteAllNotifications = () => {
    setIsDeletingAllNotifications(true);
    set(
      ref(
        db,
        `${process.env.REACT_APP_FIREBASE_DATABSE_ALERTS_PATH}/${userId}`
      ),
      null
    ).then(() => {
      setIsDeletingAllNotifications(false);
    });
  };

  useEffect(() => {
    if (messages.length === 0) {
      setOpenMessages(false);
    }
  }, [messages]);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const handleCloseAlerts = () => {
    dispatch(hideAlert());
  };

  const currentPathName = history?.location?.pathname;

  const filterPathName = ["/view-service-request"];

  const showExtraText = filterPathName.some((filteredPath: string) =>
    currentPathName.includes(filteredPath)
  );

  return (
    <div className={classes.containerStyle}>
      <AppBar
        position="fixed"
        className={clsx(classes.appBar, {
          [classes.appBarShift]: open,
        })}
        color={currentTheme === "dark" ? "default" : "primary"}
      >
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            className={clsx(classes.menuButton, {
              [classes.hide]: open,
            })}
          >
            <MenuIcon />
          </IconButton>
          <Typography variant="h6" className={classes.title}>
            {pathNameRef[currentPathName]
              ? pathNameRef[currentPathName]
              : pathNameRef[
                  Object.keys(pathNameRef).find((path: string) =>
                    currentPathName.includes(path)
                  ) || ""
                ]}{" "}
            {showExtraText
              ? `Number - ${serviceRequest?.requestId} (Status - ${serviceRequest?.status})`
              : ""}
          </Typography>

          <IconButton
            color="inherit"
            aria-label="notifications"
            onClick={() => {
              setOpenMessages((state) => !state);
            }}
          >
            <Badge badgeContent={newMessages?.length} color="secondary">
              <MailIcon />
            </Badge>
          </IconButton>

          <IconButton
            color="inherit"
            aria-label="notifications"
            onClick={() => {
              setOpenNotifications((state) => !state);
            }}
          >
            <Badge badgeContent={newNotifications?.length} color="secondary">
              <NotificationsIcon />
            </Badge>
          </IconButton>

          <div>
            <IconButton
              ref={anchorRef}
              color="inherit"
              aria-label="account options"
              onClick={(event) => menu.current.toggle(event)}
              className={classes.profileBadge}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "flex-end",
                  marginRight: "1rem",
                }}
              >
                <Typography>
                  {`${userProfile?.firstName || ""} ${
                    userProfile?.lastName || ""
                  }`}
                </Typography>
                <Typography>{userProfile?.companyName}</Typography>
              </div>
              <AccountCircleRoundedIcon style={{ fontSize: "40px" }} />

              <KeyboardArrowDown />
            </IconButton>

            <Menu
              model={[
                {
                  label: "Profile",
                  icon: "pi pi-user",
                  command: () => {
                    history.push("/user-profile");
                  },
                },
                {
                  label: "Sign Out",
                  icon: "pi pi-sign-out",
                  command: () => {
                    dispatch(logout());
                  },
                },
              ]}
              popup
              ref={menu}
              id="popup_menu"
              style={{
                marginTop: "1rem",
              }}
            />
          </div>
        </Toolbar>
        {/* <LinearProgress/> */}
      </AppBar>
      <Drawer
        variant="permanent"
        className={clsx(classes.drawer, {
          [classes.drawerOpen]: open,
          [classes.drawerClose]: !open,
        })}
        classes={{
          paper: clsx({
            [classes.drawerOpen]: open,
            [classes.drawerClose]: !open,
          }),
        }}
      >
        <div className={classes.toolbar}>
          <img src={AppLogo} alt="App Logo" className={classes.appLogo} />
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === "rtl" ? (
              <ChevronRightIcon />
            ) : (
              <ChevronLeftIcon />
            )}
          </IconButton>
        </div>
        <Divider />
        <List>
          {[
            { name: "Dashboard", Icon: HomeIcon, path: "/dashboard" },
            {
              name: "Service Request",
              Icon: ServiceRequestsIcon,
              path: "/service-requests",
            },
            { name: "Work Orders", Icon: HistoryIcon, path: "/orders" },
            {
              name: "Asset Tracker",
              Icon: LocationIcon,
              path: "/order-tracker",
            },
            {
              name: "Drivers",
              Icon: DriverIcon,
              path: "/drivers",
            },
            {
              name: "Vehicles",
              Icon: VehicleIcon,
              path: "/vehicles",
            },
            // {
            //   name: "Documents",
            //   Icon: DocumentsIcon,
            //   path: "/documents",
            // },
            {
              name: "Admins",
              Icon: CustomerIcon,
              path: "/admins",
            },
            {
              name: "Customers",
              Icon: CustomerIcon,
              path: "/customers",
            },
            {
              name: "Messages",
              Icon: ForumIcon,
              path: "/messages",
            },
          ].map(({ name, Icon, path }) => (
            <ListItem
              button
              key={name}
              selected={currentPathName === path}
              onClick={() => {
                history.push(path);
              }}
            >
              <ListItemIcon>
                <Icon />
              </ListItemIcon>
              <ListItemText primary={name} />
            </ListItem>
          ))}
        </List>
      </Drawer>
      <Notifications
        open={openNotifications}
        onOpen={() => setOpenNotifications(true)}
        onClose={() => setOpenNotifications(false)}
        newMessages={newNotifications}
        deleteAllNotifications={deleteAllNotifications}
        isDeletingAllNotifications={isDeletingAllNotifications}
        deleteSingleNotification={deleteSingleNotification}
        isDeletingNoti={isDeletingNoti}
      />

      <Messages
        newMessages={newMessages}
        open={openMessages}
        onOpen={() => setOpenMessages(true)}
        onClose={() => setOpenMessages(false)}
        deleteAllMessages={deleteAllMessages}
        isDeletingAllMessages={isDeletingAllMessages}
        deleteSingleMessageNotification={deleteSingleMessageNotification}
        isDeletingMessageNoti={isDeletingMessageNoti}
        onMessageNotiClick={(e: any, messageGroupId: string) => {
          e.stopPropagation();
          history.push("/messages", {
            messageGroupId,
          });
          setOpenMessages(false);
        }}
      />
      <main className={classes.content}>
        <div className={classes.toolbar} />
        {children}
        <div
          style={{
            position: "fixed",
            display: "flex",
            bottom: 0,
            left: 0,
            right: 0,
            backgroundColor: "#17212d",
            zIndex: 1000000000,
            color: "#fff",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Typography variant="subtitle2">
            © 2020 CAEGIS Solutions. All Rights Reserved.
          </Typography>
        </div>
        {alertState?.show && (
          <Snackbar
            anchorOrigin={{ vertical: "top", horizontal: "center" }}
            open={alertState?.show}
            autoHideDuration={1000}
            onClose={handleCloseAlerts}
          >
            <Alert
              variant="filled"
              onClose={handleCloseAlerts}
              severity={alertState?.type}
            >
              {alertState?.message}
            </Alert>
          </Snackbar>
        )}
      </main>
    </div>
  );
}
