import { BrowserRouter as Router, Switch /*,Route*/ } from "react-router-dom";

import LoginScreen from "./Login";
import DashBoard from "./DashBoard";
import Orders from "./Orders";
import OrderSummary from "./Orders/OrderSummary";
import Tracker from "./Tracker";
import Drivers from "./Drivers";
import Vehicles from "./Vehicles";
// import Documents from "./Documents";
// import AddSchedule from "./AddSchedule";
import Customers from "./Customers";
import ServiceRequests from "./ServiceRequests";
import ProtectedRoute from "../components/ProtectedRoute";
import ViewServiceRequest from "./ServiceRequests/ViewServiceRequest";
// import ViewQoutedServiceRequest from "./ServiceRequests/ViewServiceRequest/ViewQuotedServiceRequest";
import UnprotectedRoute from "../components/UnprotectedRoute";
import UnderConstruction from "../components/UnderConstruction";
import UserProfile from "./Profile";
import Messages from "./Messages";
import DriverRoute from "./DriverMap";
import Admins from "./Admins";

export default function Routes() {
  return (
    <Router>
      <Switch>
        <UnprotectedRoute path="/" exact component={LoginScreen} />
        <UnprotectedRoute path="/driver-route" exact component={DriverRoute} />
        <ProtectedRoute path="/dashboard" component={DashBoard} />
        <ProtectedRoute
          path="/service-requests"
          exact
          component={ServiceRequests}
        />
        <ProtectedRoute path="/orders" exact component={Orders} />
        <ProtectedRoute
          path="/orders/order-summary/:id"
          exact
          component={OrderSummary}
        />
        <ProtectedRoute path="/order-tracker" component={Tracker} />
        <ProtectedRoute path="/user-profile" component={UserProfile} />
        <ProtectedRoute path="/drivers" component={Drivers} />
        <ProtectedRoute path="/vehicles" component={Vehicles} />
        <ProtectedRoute path="/admins" component={Admins} />
        <ProtectedRoute path="/documents" component={UnderConstruction} />
        <ProtectedRoute path="/customers" component={Customers} />
        <ProtectedRoute path="/add-schedule" component={UnderConstruction} />
        <ProtectedRoute
          path="/view-service-request/:id"
          component={ViewServiceRequest}
        />
        <ProtectedRoute path="/messages" component={Messages} />
      </Switch>
    </Router>
  );
}
