/* eslint-disable react-hooks/exhaustive-deps */
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ThemeProvider, createTheme } from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";
import { getAuth, onAuthStateChanged } from "firebase/auth";

import Routes from "./routes";
import { useEffect } from "react";
import * as userType from "./redux/user/types";
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';

const types = {
  ...userType,
};

export default function AppWithTheme() {
  const [shouldLoadApp, setShouldLoadApp] = useState(false);
  const currentTheme = useSelector((state: any) => state?.user?.theme);
  const dispatch = useDispatch();
  const theme = createTheme({
    palette: {
      type: currentTheme,
      primary: {
        main: "#1769aa",
      },
      secondary: {
        main: "#d65c49",
      },
    },
  });

  useEffect(() => {
    const authUser = getAuth();
    const unsubscribeUser = onAuthStateChanged(authUser, async (user: any) => {
      // detaching the listener
      if (user && JSON.parse(user.reloadUserInfo.customAttributes).role === 'admin') {
        const tokenDetails = await user?.getIdToken();
        const accessTokenDetails = user?.accessToken;
        const refreshTokenDetails = user?.stsTokenManager?.refreshToken;
        localStorage.setItem("id_token", tokenDetails || "");
        localStorage.setItem("access_token", accessTokenDetails || "");
        localStorage.setItem("refresh_token", refreshTokenDetails || "");

        const userPayload = {
          userId: user?.uid || "",
          emailAddress: user?.email || "",
          role: "ADMIN",
          theme: "light",
        };
        dispatch({
          type: types.AUTHENTICATE_USER_SUCCESS,
          payload: { user: userPayload },
        });
        setShouldLoadApp(true);
      } else {
        dispatch({ type: types.LOGOUT_USER_SUCCESS });
        setShouldLoadApp(true);
      }
    });
    return () => unsubscribeUser(); // unsubscribing from the listener when the component is unmounting.
  }, []);

  if (!shouldLoadApp) {
    return null;
  }

  return (
    <MuiPickersUtilsProvider utils={MomentUtils}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <Routes />
      </ThemeProvider>
    </MuiPickersUtilsProvider>
  );
}
