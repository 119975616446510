import { useEffect, useState } from "react";
import { getOrderDataByID } from "../../../services/orders.service";
import { Card } from "primereact/card";
import {  Typography } from "@material-ui/core";
import { ProgressSpinner } from "primereact/progressspinner";
import { Link } from "react-router-dom";


export const OrderSummaryPanel = (props: any) => {
    const [order,setOrder] = useState<any>(null);
    const [loading,setLoading] = useState<boolean>(false);
    useEffect(() => {
       if (props.orderDetails) {
            (async function fetchOrderDetails() {
                setLoading(true);
                const orderDetailsResponse = await getOrderDataByID(props.orderDetails.orderId);
                if (orderDetailsResponse.status === 200) {
                    const orderDetails = orderDetailsResponse.data;
                    setOrder(orderDetails);
                }
                setLoading(false);
            })();
       }
    }, [props.orderDetails])

    return (
        <Card style={{marginBottom:'1rem'}}>
             {
                loading &&  
                (<div style={{ 
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center'
                }}>
                <ProgressSpinner />
                </div>)
            }
            {!loading &&
                <div
                style={{
                    display: "flex",
                    flexDirection: "row",
                    width: "100%"
                }}
                >
                <div
                    style={{
                    display: "flex",
                    flexDirection: "column",
                    width: "100%",
                    marginRight: "0.5rem",
                    }}
                >
                    <Typography
                    variant="h6"
                    style={{
                        borderBottom: "1px solid lightgrey",
                        marginBottom: "0.5rem",
                    }}
                    >
                    Order Details
                    </Typography>

                    <div
                    style={{
                        display: "flex",
                        flexDirection: "row",
                        width: "100%",
                        marginTop: "1rem",
                    }}
                    >
                    <div
                        style={{
                        display: "flex",
                        flexDirection: "column",
                        width: "100%",
                        }}
                    >
                        <Typography style={{ fontSize: "0.85rem" }}>
                            <b>Order ID:</b>{" "}
                            <Link to={`/orders/order-summary/${order && order.orderId}`}>{order && order.orderId || 'N/A'}</Link>
                        </Typography>
                        <Typography style={{ fontSize: "0.85rem" }}>
                            <b>Company Name:</b>{" "}
                            {order && order.customerDetails?.profileDetails?.companyName || 'N/A'}
                        </Typography>
                        <Typography style={{ fontSize: "0.85rem" }}>
                            <b>Company Address:</b>{" "}
                            {`${order && order?.customerDetails?.profileDetails?.addressLine1}, ${order && order?.customerDetails?.profileDetails?.addressLine2}, ${order && order?.customerDetails?.profileDetails?.city}, ${order && order?.customerDetails?.profileDetails?.zipCode}`}
                        </Typography>
                        <Typography style={{ fontSize: "0.85rem" }}>
                            <b>Driver Name:</b>{" "}
                            {`${order && order.drivers[0] && order.drivers[0]?.profile?.firstName || ''} ${order && order.drivers[0] && order.drivers[0]?.profile?.lastName || ''}`}
                        </Typography>
                    </div>
                    <div
                        style={{
                        display: "flex",
                        flexDirection: "column",
                        width: "100%",
                        marginBottom: "1rem",
                        }}
                    > 
                        <Typography style={{ fontSize: "0.85rem" }}>
                            <b>Vehicle Number:</b>{" "}
                            {`${order && order.vehicles[0] && order.vehicles[0]?.vehicleLicensePlates || 'N/A'}`}
                        </Typography>
                        <Typography style={{ fontSize: "0.85rem" }}>
                            <b>Pickup Address:</b>{" "}
                            {`${order && order?.pickupDetails?.primaryProfile?.addressLine1}, ${order && order?.pickupDetails?.primaryProfile?.addressLine2}, ${order && order?.pickupDetails?.primaryProfile?.city}, ${order && order?.pickupDetails?.primaryProfile?.zipCode}`}
                        </Typography>
                        <Typography style={{ fontSize: "0.85rem" }}>
                            <b>Destination Address:</b>{" "}
                            {`${order && order?.destinationDetails?.primaryProfile?.addressLine1}, ${order && order?.destinationDetails?.primaryProfile?.addressLine2}, ${order && order?.destinationDetails?.primaryProfile?.city}, ${order && order?.destinationDetails?.primaryProfile?.zipCode}`}
                        </Typography>
                        <Typography style={{ fontSize: "0.85rem" }}>
                            <b>General Instructions:</b>{" "}
                            {order && order?.pickupDetails?.generalInstructions || 'N/A'}
                        </Typography>
                    </div>
                    </div>
                </div>
                </div>
            }
          </Card>
    )
}

export default OrderSummaryPanel;