import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { Paper } from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";

import Confirmation from "./Confirmation";
import {
  fetchServiceRequest,
  provideQuote,
} from "../../../services/serviceRequests.service";
import { extractServiceRequestDataFromAPI } from "./extractServiceRequestDataFromAPI";
import { showAlert } from "../../../redux/alerts/actions";

export default function ViewServiceRequest({ history }: any) {
  const [serviceRequestData, setServiceRequestData] = useState<any>(null);
  const [isLoadingData, setIsLoadingData] = useState(false);
  const [isSubmittingForm, setIsSubmittingForm] = useState(false);
  const params = useParams<any>();
  const dispatch = useDispatch();

  useEffect(() => {
    (async () => {
      setIsLoadingData(true);
      const serviceDetailsResponse = await fetchServiceRequest(params.id);
      if (serviceDetailsResponse.status === 200) {
        const formattedData: any = extractServiceRequestDataFromAPI(
          serviceDetailsResponse.data
        );

        setServiceRequestData(formattedData);
      }
      setIsLoadingData(false);
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const provideQoute = async (provideQuotePayload: any) => {
    try {
      const payload = {
        serviceRequestId: params.id,
        scheduledDate: provideQuotePayload.scheduledDate,
        scheduledTimeFrom: provideQuotePayload.scheduledTimeFrom,
        scheduledTimeTo: provideQuotePayload.scheduledTimeTo,
        serviceItems: provideQuotePayload.serviceItems,
      };
      setIsSubmittingForm(true);
      const provideQuoteResponse = await provideQuote(payload);
      if (provideQuoteResponse.status === 200) {
        dispatch(
          showAlert({ message: "Quotation Successful.", type: "success" })
        );
      } else {
        dispatch(
          showAlert({
            message: "Oops! something went wrong. Please try again",
            type: "error",
          })
        );
      }
      setIsSubmittingForm(false);
      history.push('/service-requests');
    } catch (error: any) {
      console.error(error);
    }
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        width: "100%",
        alignItems: "center",
      }}
    >
      {isLoadingData ? (
        <Skeleton width={50} />
      ) : (
        <Paper
          style={{
            width: "80%",
            padding: "2rem",
            margin: "1rem 0 1rem 0",
          }}
        >
          <Confirmation
            serviceRequestData={serviceRequestData}
            handleOnProvideQoute={provideQoute}
            isSubmittingForm={isSubmittingForm}
          />
        </Paper>
      )}
    </div>
  );
}
