import React, { useEffect, useState } from "react";
import TextField from "@material-ui/core/TextField";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import { updateDriver } from "../../../services/driver.service";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";


import moment from "moment";
import {
  validateRequired,
  emailValidator,
} from "../../../formUtils/validations";
import { IconButton, Typography } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import { useDispatch } from "react-redux";
import { showAlert } from "../../../redux/alerts/actions";
import { useFormik } from "formik";
import { Clear } from "@material-ui/icons";
import { Calendar } from "primereact/calendar";
import { InputText } from "primereact/inputtext";
import { classNames } from "primereact/utils";
import { normalizePhoneNumber } from "../../../formUtils/normalizations";

interface IProps {
  open: boolean;
  handleClose: (event: { show: boolean; refreshPage: boolean }) => void;
  driverToBeEdited: DriverEdit | null;
}

interface DriverEdit {
  driverId: string;
  profileId: string;
  companyName: string;
  firstName: string;
  lastName: string;
  emailAddress: string;
  primaryPhoneNumber: string;
  secondaryPhoneNumber: string;
  dateOfBirth: any;
  licenseNumber: string;
}


const EditDriverModal = (props: IProps) => {
  /**
   * Variable declaration
   */
  // const [driverDetails, setDriverDetails] = useState<DriverEdit>({
  //   ...initial_driver_details,
  // });
  const [editingDriver, setEditingDriver] = useState<boolean>(false);
  const dispatch = useDispatch();
  const formik: any = useFormik({
    initialValues: {
      driverId: "",
      profileId: "",
      companyName: "",
      firstName: "",
      lastName: "",
      emailAddress: "",
      primaryPhoneNumber: "",
      secondaryPhoneNumber: "",
      dateOfBirth: "",
      licenseNumber: "",
    },
    validate: (data: DriverEdit) => {
      let errors: any = {};

      if (!data.companyName) {
        errors.companyName = "This Field is required.";
      }

      if (!data.firstName) {
        errors.firstName = "This Field is required.";
      }
      if (!data.lastName) {
        errors.lastName = "This Field is required.";
      }
      if (!data.licenseNumber) {
        errors.licenseNumber = "This Field is required.";
      }
      if (!data.primaryPhoneNumber) {
        errors.primaryPhoneNumber = "This Field is required.";
      }
      if (!data.dateOfBirth) {
        errors.dateOfBirth = "This Field is required.";
      }

      if (data.emailAddress) {
        if (emailValidator(data.emailAddress)) {
          errors.emailAddress = emailValidator(data.emailAddress);
        }
      }

      return errors;
    },
    onSubmit: async (data: DriverEdit) => {
      try {
        await editDriver(data);
      } catch (error: any) {
        console.error(error);
      }
    },
  });
  type driverStringTypes =
    | "companyName"
    | "firstName"
    | "lastName"
    | "emailAddress"
    | "primaryPhoneNumber"
    | "secondaryPhoneNumber"
    | "secondaryPhoneNumber"
    | "dateOfBirth"
    | "licenseNumber";

  const isFormFieldValid = (name: driverStringTypes) =>
    !!(formik.touched[name] && formik.errors[name]);
  const getFormErrorMessage = (name: driverStringTypes) => {
    return (
      isFormFieldValid(name) && (
        <small className="p-error">{formik.errors[name]}</small>
      )
    );
  };

  useEffect(() => {
    if (props.driverToBeEdited) {
      formik.setValues({
        ...props.driverToBeEdited,
        primaryPhoneNumber: normalizePhoneNumber(
          props.driverToBeEdited?.primaryPhoneNumber?.replaceAll("-", "") || ""
        ),
        secondaryPhoneNumber: normalizePhoneNumber(
          props.driverToBeEdited?.secondaryPhoneNumber?.replaceAll("-", "") || ""
        ),
        dateOfBirth: moment(props.driverToBeEdited.dateOfBirth).toDate(),
      })
      // setDriverDetails({ ...props.driverToBeEdited });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  /**
   * Update driver api call.
   */
  const editDriver = async (driverDetails: DriverEdit) => {
    try {
      setEditingDriver(true);
      const payload = {
        ...driverDetails,
        primaryPhoneNumber: formik.values?.primaryPhoneNumber
        ? "+1" + formik.values?.primaryPhoneNumber?.trim().replaceAll("-", "")
        : "",
        secondaryPhoneNumber: formik.values?.secondaryPhoneNumber
        ? "+1" + formik.values?.secondaryPhoneNumber?.trim().replaceAll("-", "")
        : "",
        dateOfBirth: moment(driverDetails.dateOfBirth).format("YYYY-MM-DD"),
      };
      const editDriverResponse = await updateDriver(payload);
      setEditingDriver(false);
      if (editDriverResponse.status === 200) {
        dispatch(
          showAlert({ message: "Driver edited successfully", type: "success" })
        );
        formik.resetForm();
        props.handleClose({ show: false, refreshPage: true });
      } else {
        dispatch(
          showAlert({
            message: "Oops! something went wrong. Please try again.",
            type: "error",
          })
        );
      }
    } catch (error: any) {
      console.error(error);
    }
  };

  /**
   * On change handler of input elements.
   * @param event
   */
  // const onChangeHandler = (event: any) => {
  //   const payload: any = {
  //     ...driverDetails,
  //   };
  //   payload[event.target.id] = event.target.value;
  //   setDriverDetails(payload);
  // };

  // const validateFields = () => {
  //   if (
  //     !driverDetails.companyName ||
  //     !driverDetails.firstName ||
  //     !driverDetails.lastName ||
  //     !!emailValidator(driverDetails.emailAddress) ||
  //     !driverDetails.dateOfBirth ||
  //     !driverDetails.licenseNumber ||
  //     !driverDetails.primaryPhoneNumber
  //   ) {
  //     return true;
  //   }
  //   return false;
  // };

  return (
    <Dialog
      visible={props.open}
      onHide={() => {
        formik.resetForm();
        props.handleClose({ show: false, refreshPage: false })
      }}
      modal
      draggable={false}
      style={{
        width: "50%",
      }}
      contentStyle={{
        padding: 0,
        borderRadius: "5px",
      }}
      showHeader={false}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "space-between",
          padding: "1rem 2rem 0 2rem",
        }}
      >
        <Typography variant="h6">Edit Driver Details</Typography>

        <IconButton
          onClick={() => {
            formik.resetForm();
            props.handleClose({ show: false, refreshPage: false });
          }}
          style={{
            position: "absolute",
            right: "-1rem",
            top: "-1rem",
            backgroundColor: "#1769aa",
            color: "#fff",
          }}
        >
          <Clear color="inherit" />
        </IconButton>
      </div>
      <form
        style={{
          width: "100%",
        }}
        onSubmit={formik.handleSubmit}
      >
        <div
          style={{
            width: "100%",
            padding: "2rem 2rem 0 2rem",
            maxHeight: "60vh",
            overflowY: "auto",
          }}
        >
          <div
            className="p-field"
            style={{
              width: "100%",
              marginBottom: "2rem",
            }}
          >
            <span
              className="p-float-label"
              style={{
                width: "100%",
              }}
            >
              <InputText
                id="companyName"
                name="companyName"
                value={formik.values.companyName}
                onChange={formik.handleChange}
                className={classNames({
                  "p-invalid": isFormFieldValid("companyName"),
                })}
                style={{
                  width: "100%",
                }}
              />
              <label
                htmlFor="companyName"
                className={classNames({
                  "p-error": isFormFieldValid("companyName"),
                })}
              >
                Company Name*
              </label>
            </span>
            {getFormErrorMessage("companyName")}
          </div>

          <div
            style={{
              display: "flex",
              flexDirection: "row",
            }}
          >
            <div
              className="p-field"
              style={{
                width: "100%",
                marginRight: "0.5rem",
              }}
            >
              <span
                className="p-float-label"
                style={{
                  width: "100%",
                }}
              >
                <InputText
                  id="firstName"
                  name="firstName"
                  value={formik.values.firstName}
                  onChange={formik.handleChange}
                  className={classNames({
                    "p-invalid": isFormFieldValid("firstName"),
                  })}
                  style={{
                    width: "100%",
                  }}
                />
                <label
                  htmlFor="firstName"
                  className={classNames({
                    "p-error": isFormFieldValid("firstName"),
                  })}
                >
                  First Name*
                </label>
              </span>
              {getFormErrorMessage("firstName")}
            </div>

            <div
              className="p-field"
              style={{
                width: "100%",
                marginLeft: "0.5rem",
              }}
            >
              <span
                className="p-float-label"
                style={{
                  width: "100%",
                }}
              >
                <InputText
                  id="lastName"
                  name="lastName"
                  value={formik.values.lastName}
                  onChange={formik.handleChange}
                  className={classNames({
                    "p-invalid": isFormFieldValid("lastName"),
                  })}
                  style={{
                    width: "100%",
                  }}
                />
                <label
                  htmlFor="lastName"
                  className={classNames({
                    "p-error": isFormFieldValid("lastName"),
                  })}
                >
                  Last Name*
                </label>
              </span>
              {getFormErrorMessage("lastName")}
            </div>
          </div>

          <div
            style={{
              display: "flex",
              flexDirection: "row",
            }}
          >
            <div
              className="p-field"
              style={{
                width: "100%",
                marginRight:'0.5rem'
              }}
            >
              <span
                className="p-float-label p-input-icon-right"
                style={{
                  width: "100%",
                }}
              >
                <i className="pi pi-envelope" />
                <InputText
                  id="emailAddress"
                  name="emailAddress"
                  value={formik.values.emailAddress}
                  onChange={formik.handleChange}
                  className={classNames({
                    "p-invalid": isFormFieldValid("emailAddress"),
                  })}
                  style={{
                    width: "100%",
                  }}
                />
                <label
                  htmlFor="emailAddress"
                  className={classNames({
                    "p-error": isFormFieldValid("emailAddress"),
                  })}
                >
                  Email Address*
                </label>
              </span>
              {getFormErrorMessage("emailAddress")}
            </div>
            <div
              className="p-field"
              style={{
                width: "100%",
                marginLeft:'0.5rem'
              }}
            >
              <span
                className="p-float-label p-input-icon-right"
                style={{
                  width: "100%",
                }}
              >
              <Calendar
                  id="dateOfBirth"
                  name="dateOfBirth"
                  value={formik.values.dateOfBirth}
                  onChange={(event: any) => {
                    formik.handleChange(event);
                    if (formik.values.vehicleRentedTill) {
                      formik.setFieldValue('vehicleRentedTill', null);
                    }
                  }}
                  className={classNames({
                    "p-invalid": isFormFieldValid("dateOfBirth"),
                  })}
                  style={{
                    width: "100%",
                  }}
                  showIcon
                  
                  hideOnDateTimeSelect
                  readOnlyInput
                  monthNavigator
                  yearNavigator
                  yearRange={`1900:${moment().year()}`}
                />
                <label
                  htmlFor="dateOfBirth"
                  className={classNames({
                    "p-error": isFormFieldValid("dateOfBirth"),
                  })}
                >
                  DOB*
                </label>
              </span>
              {getFormErrorMessage("dateOfBirth")}
            </div>
          </div>

          
          <div
            className="p-field"
            style={{
              width: "100%",
              marginBottom: "2rem",
            }}
          >
            <span
              className="p-float-label"
              style={{
                width: "100%",
              }}
            >
              <InputText
                id="licenseNumber"
                name="licenseNumber"
                value={formik.values.licenseNumber}
                onChange={formik.handleChange}
                className={classNames({
                  "p-invalid": isFormFieldValid("licenseNumber"),
                })}
                style={{
                  width: "100%",
                }}
              />
              <label
                htmlFor="licenseNumber"
                className={classNames({
                  "p-error": isFormFieldValid("licenseNumber"),
                })}
              >
                License Number*
              </label>
            </span>
            {getFormErrorMessage("licenseNumber")}
          </div>

          <div
            style={{
              display: "flex",
              flexDirection: "row",
              marginBottom: "2rem",
            }}
          >
            <div
              className="p-field"
              style={{
                width: "100%",
                marginRight: "0.5rem",
              }}
            >
              <span
                className="p-input-icon-left p-float-label"
                style={{
                  width: "100%",
                }}
              >
                <i className="pi">+1</i>
                <InputText
                  id="primaryPhoneNumber"
                  name="primaryPhoneNumber"
                  value={formik.values.primaryPhoneNumber}
                  onChange={(e) => {
                    e.target.value = normalizePhoneNumber(e.target.value);
                    formik.handleChange(e);
                  }}
                  className={classNames({
                    "p-invalid": isFormFieldValid("primaryPhoneNumber"),
                  })}
                  style={{
                    width: "100%",
                  }}
                />
                <label
                  htmlFor="primaryPhoneNumber"
                  className={classNames({
                    "p-error": isFormFieldValid("primaryPhoneNumber"),
                  })}
                  style={{
                    marginLeft: formik.values.primaryPhoneNumber
                      ? "-1.5rem"
                      : 0,
                  }}
                >
                  Primary Phone Number*
                </label>
              </span>
              {getFormErrorMessage("primaryPhoneNumber")}
            </div>

            <div
              className="p-field"
              style={{
                width: "100%",
                marginLeft: "0.5rem",
              }}
            >
              <span
                className="p-input-icon-left p-float-label"
                style={{
                  width: "100%",
                }}
              >
                <i className="pi">+1</i>
                <InputText
                  id="secondaryPhoneNumber"
                  name="secondaryPhoneNumber"
                  value={formik.values.secondaryPhoneNumber}
                  onChange={(e) => {
                    e.target.value = normalizePhoneNumber(e.target.value);
                    formik.handleChange(e);
                  }}
                  className={classNames({
                    "p-invalid": isFormFieldValid("secondaryPhoneNumber"),
                  })}
                  style={{
                    width: "100%",
                  }}
                />
                <label
                  htmlFor="secondaryPhoneNumber"
                  className={classNames({
                    "p-error": isFormFieldValid("secondaryPhoneNumber"),
                  })}
                  style={{
                    marginLeft: formik.values.secondaryPhoneNumber
                      ? "-1.5rem"
                      : 0,
                  }}
                >
                  Alternate Phone Number
                </label>
              </span>
              {getFormErrorMessage("secondaryPhoneNumber")}
            </div>
          </div>
        </div>


         

        <div
          style={{
            display: "flex",
            flexDirection: "row",
            padding: "2rem 3rem 2rem 2rem",
            justifyContent: "flex-end",
          }}
        >
          <Button
            label="Cancel"
            icon="pi pi-times"
            style={{
              borderColor: "#d65c49",
              backgroundColor: "#d65c49",
            }}
            onClick={() => {
              formik.resetForm();
              props.handleClose({ show: false, refreshPage: false })
            }}
            disabled={editingDriver}
          />

          <Button
            type="submit"
            label="Continue"
            icon="pi pi-check"
            loading={editingDriver}
            style={{
              marginLeft: "2rem",
              backgroundColor: "#1769aa",
            }}
          />
        </div>
      </form>
    </Dialog>
    // <Dialog
    //   open={props.open}
    //   disableBackdropClick={true}
    //   onClose={() => props.handleClose({ show: false, refreshPage: false })}
    //   aria-labelledby="form-dialog-title"
    // >
    //   <DialogTitle id="form-dialog-title">
    //     <div style={{ display: "flex", justifyContent: "space-between" }}>
    //       <div style={{ alignSelf: "center" }}>Edit Driver Details</div>
    //       <IconButton
    //         aria-label="close"
    //         onClick={() =>
    //           props.handleClose({ show: false, refreshPage: false })
    //         }
    //       >
    //         <CloseIcon />
    //       </IconButton>
    //     </div>
    //   </DialogTitle>
    //   <DialogContent>
    //     <TextField
    //       autoFocus
    //       error={!driverDetails.companyName}
    //       margin="dense"
    //       id="companyName"
    //       label="Company Name "
    //       type="text"
    //       value={driverDetails.companyName || ""}
    //       fullWidth
    //       onChange={onChangeHandler}
    //       helperText={validateRequired(driverDetails.companyName)}
    //     />
    //     <TextField
    //       error={!driverDetails.firstName}
    //       margin="dense"
    //       id="firstName"
    //       label="First Name"
    //       type="text"
    //       fullWidth
    //       value={driverDetails.firstName || ""}
    //       onChange={onChangeHandler}
    //       helperText={validateRequired(driverDetails.firstName)}
    //     />

    //     <TextField
    //       error={!driverDetails.lastName}
    //       margin="dense"
    //       id="lastName"
    //       label="Last Name "
    //       type="text"
    //       fullWidth
    //       value={driverDetails.lastName || ""}
    //       onChange={onChangeHandler}
    //       helperText={validateRequired(driverDetails.lastName)}
    //     />
    //     <TextField
    //       error={!driverDetails.dateOfBirth}
    //       id="dateOfBirth"
    //       label="Date Of Birth"
    //       type="date"
    //       margin="dense"
    //       fullWidth
    //       value={driverDetails.dateOfBirth || ""}
    //       onChange={onChangeHandler}
    //       InputLabelProps={{
    //         shrink: true,
    //       }}
    //       helperText={validateRequired(driverDetails.dateOfBirth)}
    //     />

    //     <TextField
    //       error={!!emailValidator(driverDetails.emailAddress)}
    //       margin="dense"
    //       id="emailAddress"
    //       label="Email Address"
    //       type="email"
    //       fullWidth
    //       value={driverDetails.emailAddress || ""}
    //       onChange={onChangeHandler}
    //       helperText={emailValidator(driverDetails.emailAddress)}
    //     />
    //     <TextField
    //       error={!driverDetails.licenseNumber}
    //       margin="dense"
    //       id="licenseNumber"
    //       label="License Number"
    //       type="text"
    //       fullWidth
    //       value={driverDetails.licenseNumber || ""}
    //       onChange={onChangeHandler}
    //       helperText={validateRequired(driverDetails.licenseNumber)}
    //     />
    //     <TextField
    //       error={!driverDetails.primaryPhoneNumber}
    //       margin="dense"
    //       id="primaryPhoneNumber"
    //       label="Phone Number"
    //       type="number"
    //       fullWidth
    //       value={driverDetails.primaryPhoneNumber || ""}
    //       onChange={onChangeHandler}
    //       helperText={validateRequired(driverDetails.primaryPhoneNumber)}
    //     />
    //     <TextField
    //       margin="dense"
    //       id="secondaryPhoneNumber"
    //       label="Address Line 1"
    //       type="text"
    //       fullWidth
    //       value={driverDetails.secondaryPhoneNumber || ""}
    //       onChange={onChangeHandler}
    //     />
    //   </DialogContent>
    //   <DialogActions>
    //     <Button
    //       onClick={() => props.handleClose({ show: false, refreshPage: false })}
    //       color="primary"
    //     >
    //       Cancel
    //     </Button>
    //     <Button
    //       disabled={validateFields()}
    //       onClick={editDriver}
    //       color="primary"
    //     >
    //       Save
    //     </Button>
    //   </DialogActions>
    // </Dialog>
  );
};

export default EditDriverModal;
