import Axios from "../config/interceptors/axiosInterceptor";

/**
 * This function is used to fetch all service request
 * @returns
 */
export const getAllServiceRequests = async (
  searchText: string,
  pageNumber: number,
  pageSize: number,
  filters: string
) => {
  try {
    return await Axios.get(
      `${process.env.REACT_APP_API_BASE_URL}/service-request/get-all?page_number=${pageNumber}&page_size=${pageSize}&search_term=${
        searchText || ""
      }&filters=${filters || ''}`
    );
  } catch (error: any) {
    return {
      status: 500,
      data: null,
      statusText: "Oops! something went wrong",
    };
  }
};
/**
 * This function is used to fetch a service request
 * @returns
 */
 export const fetchServiceRequest = async (serviceRequestID: string) => {
    try {
        return await Axios.get(`${process.env.REACT_APP_API_BASE_URL}/service-request/get?request_id=${serviceRequestID}`);
    } catch (error: any) {
        return {status: 500, data: null, statusText: 'Oops! something went wrong'}
    }
};
/**
 * This function is used to provide quote to a service id
 * @returns 
 */
 export const provideQuote = async (payload: any) => {
    try {
        return await Axios.post(`${process.env.REACT_APP_API_BASE_URL}​/service-request/quote-service-request`,payload);
    } catch (error: any) {
        return {status: 500, data: null, statusText: 'Oops! something went wrong'}
    }
};
