import  { useEffect, useState } from "react";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import { IconButton, Typography } from "@material-ui/core";
import { useDispatch } from "react-redux";
import { useFormik } from "formik";
import { Clear } from "@material-ui/icons";
import { classNames } from "primereact/utils";
import { AutoComplete } from "primereact/autocomplete";
import { getDrivers } from "../../../services/driver.service";
import { getVehicles } from "../../../services/vehicle.service";
import { Card } from "primereact/card";
import { showAlert } from "../../../redux/alerts/actions";
import { assignDriverToWorkOrder, getOrderDataByID } from "../../../services/orders.service";
import OrderSummary from ".";

interface IProps {
  open: boolean;
  handleClose: (event: { show: boolean; refreshPage: boolean }) => void;
  selectedOrder: any
  history: any
}

const OrderSummaryModal = (props: IProps) => {


  return (
    <Dialog
      visible={props.open}
      onHide={() => {
        props.handleClose({ show: false, refreshPage: false })
      }}
      modal
      draggable={false}
      style={{
        width: "75%",
      }}
      contentStyle={{
        padding: 0,
        borderRadius: "5px",
        overflowX: 'hidden'
      }}
      showHeader={false}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "space-between",
          padding: "1rem 2rem 1rem 2rem",
        }}
      >
        <Typography variant="h6">Order Summary</Typography>

        <IconButton
          onClick={() => {
            props.handleClose({ show: false, refreshPage: false });
          }}
          style={{
            position: "absolute",
            right: "-1rem",
            top: "-1rem",
            backgroundColor: "#1769aa",
            color: "#fff",
          }}
        >
          <Clear color="inherit" />
        </IconButton>
      </div>
      <OrderSummary 
        orderDetails={props.selectedOrder} 
        history={props.history}
        isModal
      />
    </Dialog>
  );
};

export default OrderSummaryModal;
