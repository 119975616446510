import * as driverTypes from "./types";

const types = {
  ...driverTypes,
};

const initialState = {
  totalDrivers: 0,
  list: [],
  totalPages: 1,
  currentPage: 1,
};

export default function driversReducer(
  state = initialState,
  action: { type: string; payload: any }
) {
  switch (action.type) {
    case types.FETCH_ALL_DRIVER_ERROR:
      return {
        ...state,
        totalDrivers: 0,
        list: [],
        totalPages: 0,
        currentPage: 0,
      };
    case types.FETCH_ALL_DRIVER_SUCCESS:
      return {
        ...state,
        totalDrivers: action.payload.totalCount,
        list: action.payload.records,
        totalPages: action.payload.totalPages,
        currentPage: action.payload.currentPage,
      };
    default:
      return state;
  }
}
