import moment from "moment";
import { appointments } from "../../routes/DashBoard/Scheduler/data/appointments";
import { LOCATIONS } from "../../routes/DashBoard/Scheduler";

import * as types from "./types";

const schedulerInitialState = {
  data: appointments,
  currentDate: moment().format("YYYY-MM-DD"),
  currentViewName: "Week",
  currentFilter: "",
  locations: LOCATIONS,
};

const schedulerReducer = (state = schedulerInitialState, action: any) => {
  switch (action.type) {
    case types.SCHEDULER_STATE_CHANGE_ACTION:
      return {
        ...state,
        [action.payload.partialStateName]: action.payload.partialStateValue,
      };
    default:
      return state;
  }
};

export default schedulerReducer;
