import * as adminTypes from "./types";

const types = {
  ...adminTypes,
};

const initialState = {
  totalAdmins: 0,
  list: [],
  totalPages: 1,
  currentPage: 1,
};

export default function adminReducer(
  state = initialState,
  action: { type: string; payload: any }
) {
  switch (action.type) {
    case types.FETCH_ALL_ADMIN_ERROR:
      return {
        ...state,
        totalAdmins: 0,
        list: [],
        totalPages: 0,
        currentPage: 0,
      };
    case types.FETCH_ALL_ADMIN_SUCCESS:
      return {
        ...state,
        totalAdmins: action.payload.totalCount,
        list: action.payload.records,
        totalPages: action.payload.totalPages,
        currentPage: action.payload.currentPage,
      };
    default:
      return state;
  }
}
