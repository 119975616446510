import axios from 'axios';
import { refreshAccessToken } from '../../services/auth.service';
const Axios = axios.create();

// Request interceptor for API calls
Axios.interceptors.request.use(
  (config: any) => {
    const token = localStorage.getItem('id_token')  
    config.headers = { 
      'Authorization': `Bearer ${token}`,
    }
    return config;
  },
  (error:any) => {
    Promise.reject(error)
});

Axios.interceptors.response.use((response: any) => {
  return response
}, async function (error) {
  const originalRequest = error.config;
  if (error.response.status === 401 && !originalRequest._retry) {
    originalRequest._retry = true;
    const access_token = await refreshAccessToken();            
    axios.defaults.headers.common['Authorization'] = 'Bearer ' + access_token;
    return Axios(originalRequest);
  }
  return Promise.reject(error);
});

export default Axios;