import Axios from "../config/interceptors/axiosInterceptor";

/**
 * This function is used to fetch drivers
 * @returns
 */
export const getDrivers = async (
  searchText: string,
  pageNumber: number,
  pageSize: number
) => {
  try {
    return await Axios.get(
      `${
        process.env.REACT_APP_API_BASE_URL
      }/driver/get?page_number=${pageNumber}&page_size=${pageSize}&search_term=${
        searchText || ""
      }`
    );
  } catch (error: any) {
    return {
      status: 500,
      data: null,
      statusText: "Oops! something went wrong",
    };
  }
};
/**
 * This function is used to add a new driver
 * @returns
 */
export const addNewDriver = async (payload: any) => {
  try {
    return await Axios.post(
      `${process.env.REACT_APP_API_BASE_URL}/driver/create`,
      payload
    );
  } catch (error: any) {
    return {
      status: 500,
      data: null,
      statusText: "Oops! something went wrong",
    };
  }
};
/**
 * This function is used to update an existing driver
 * @returns
 */
export const updateDriver = async (payload: any) => {
  try {
    return await Axios.post(
      `${process.env.REACT_APP_API_BASE_URL}/driver/update`,
      payload
    );
  } catch (error: any) {
    return {
      status: 500,
      data: null,
      statusText: "Oops! something went wrong",
    };
  }
};
/**
 * This function is used to delete a driver
 * @returns
 */
export const deleteDriver = async (driverID: string) => {
  try {
    return await Axios.delete(
      `${process.env.REACT_APP_API_BASE_URL}/driver/delete?driverId=${driverID}`
    );
  } catch (error: any) {
    return {
      status: 500,
      data: null,
      statusText: "Oops! something went wrong",
    };
  }
};

/**
 * get all driver locations.
 * @returns
 */
 export const getAllDriverLocations = async (
    orderID: Number
) => {
  try {
    return await Axios.get(
      `${process.env.REACT_APP_API_BASE_URL}/driver/get-all-driver-location?order_id=${orderID}`
    );
  } catch (error: any) {
    return {
      status: 500,
      data: null,
      statusText: "Oops! something went wrong",
    };
  }
};

/**
 * get driver latest position
 * @param orderID 
 * @returns 
 */
export const getDriverLatestPosition = async (orderID: Number) => {
  try {
    return await Axios.get(
      `${process.env.REACT_APP_API_BASE_URL}/driver/get-latest-driver-location?order_id=${orderID}`
    );
  } catch (error: any) {
    return {
      status: 500,
      data: null,
      statusText: "Oops! something went wrong",
    };
  }
}