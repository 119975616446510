import { combineReducers } from "@reduxjs/toolkit";

import usersReducer from "./users/reducer";
import userReducer from "./user/reducer";
import ordersReducer from "./orders/reducer";
import driversReducer from "./drivers/reducer";
import vehiclesReducer from "./vehicles/reducer";
import documentsReducer from "./documents/reducer";
import schedulerReducer from "./scheduler/reducer";
import customerReducer from "./customers/reducer";
import serviceRequestReducer from "./serviceRequests/reducer";
import alertReducer from "./alerts/reducer";
import dashboardReducer from "./dashboard/reducer";
import messageReducer from "./messages/reducer";
import profileReducer from "./profile/reducer";
import adminReducer from "./admin/reducer";


const rootReducer = combineReducers({
  users: usersReducer,
  user: userReducer,
  profile: profileReducer,
  messages: messageReducer,
  dashboard: dashboardReducer,
  orders: ordersReducer,
  drivers: driversReducer,
  vehicles: vehiclesReducer,
  documents: documentsReducer,
  schedulerReducer,
  customers: customerReducer,
  serviceRequests: serviceRequestReducer,
  admins: adminReducer,
  alerts: alertReducer
});

export default rootReducer;
