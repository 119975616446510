import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import { InputText } from "primereact/inputtext";
import { InputTextarea } from "primereact/inputtextarea";
import { classNames } from "primereact/utils";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import { Button } from "primereact/button";
import { TimePicker } from "@material-ui/pickers";

import { normalizeOnlyNums } from "../../../formUtils/normalizations";
import { getServiceItems } from "../../../services/serviceItems.service";
import { Calendar } from "primereact/calendar";
import { InputMask } from "primereact/inputmask";
import moment from "moment";
import { Typography, Tooltip, IconButton } from "@material-ui/core";
import InfoIcon from "@material-ui/icons/Info";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    dataTableColumnStyle: {
      fontSize: "0.9rem",
      padding: "0.75rem !important",
      border: "1px solid #e9ecef !important",
    },
    dataTableHeaderStyle: {
      padding: "0.75rem !important",
      // border: "1px solid lightgrey !important",
      //backgroundColor: `${theme.palette.primary.main} !important`,
      //color: "#fff !important",
      whiteSpace: "nowrap",
    },
    dataTableRowStyle: {
      "&:nth-child(even)": {
        backgroundColor: "#BDBDBD !important",
      },
    },
  })
);

type ShipmentDetailsType = {
  totalWeight: string;
  totalPalletsRequired: string;
  itemsToDeliver: string;
  laborsNeeded?: string;
  laborTime?: string;
  noOfVehiclesNeeded?: string;
  vehicleType?: string;
  scheduledDate: any;
  scheduledTimeFrom: any;
  scheduledTimeTo: any;
  customOrderId: string;
};

export default function ShipmentDetails({
  initialValues,
  formName,
  requireAllFields,
  currentFormRef,
  handleEachStepNext,
  readOnly,
  handleOnProvideQoute,
  isSubmittingForm,
}: any) {
  const [serviceItems, setServiceItems] = useState<any>([]);
  const [totalServiceCost, setTotalServiceCost] = useState<any>({
    totalServiceCost: "Total Service Cost: ",
    amount: 0,
    checked: true,
  });
  const [isTooltipOpen, setIsTooltipOpen] = useState<any>(false);

  const classes = useStyles();

  const formatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
  });

  const formik = useFormik({
    initialValues: initialValues || {
      totalWeight: "",
      totalPalletsRequired: "",
      itemsToDeliver: "",
      scheduledDate: "",
      scheduledTimeFrom: null,
      scheduledTimeTo: null,
      customOrderId: "",
    },
    validate: (data: ShipmentDetailsType) => {
      let errors: any = {};

      if (!data.totalWeight) {
        errors.totalWeight = "This Field is required.";
      }

      if (!data.totalPalletsRequired) {
        errors.totalPalletsRequired = "This Field is required.";
      }

      if (!data.customOrderId) {
        errors.customOrderId = 'This Field is required.';
      }

      if (!data.scheduledDate) {
        errors.scheduledDate = "This Field is required.";
      }
      if (!data.scheduledTimeFrom) {
        errors.scheduledTimeFrom = "This Field is required.";
      }
      if (!data.scheduledTimeTo) {
        errors.scheduledTimeTo = "This Field is required.";
      }

      if (data.scheduledTimeFrom && data.scheduledTimeTo) {
        const scheduledTimeTo = moment(data.scheduledTimeTo);
        const scheduledTimeFrom = moment(data.scheduledTimeFrom);
        if (
          Math.sign(
            moment.duration(scheduledTimeTo.diff(scheduledTimeFrom)).asHours()
          ) === -1
        ) {
          errors.scheduledTimeTo = "Please select a different time.";
        }
      }

      if (
        (
          serviceItems.find((x: any) => x.serviceName === "Labor") || {
            checked: false,
          }
        )?.checked
      ) {
        if (!data.laborsNeeded) {
          errors.laborsNeeded = "This Field is required.";
        }

        if (!data.laborTime) {
          errors.laborTime = "This Field is required.";
        }
      }

      if (
        (
          serviceItems.find((x: any) => x.serviceName === "Trucking") || {
            checked: false,
          }
        )?.checked
      ) {
        if (!data.noOfVehiclesNeeded) {
          errors.noOfVehiclesNeeded = "This Field is required.";
        }

        if (!data.vehicleType) {
          errors.vehicleType = "This Field is required.";
        }
      }

      return errors;
    },
    onSubmit: (data: ShipmentDetailsType) => {
      handleEachStepNext(formName, {
        ...data,
        serviceItems,
      });
    },
    enableReinitialize: true,
  });

  useEffect(() => {
    (async function fetchAllServiceItems() {
      const serviceItemResponse = await getServiceItems();
      if (serviceItemResponse.status === 200) {
        const alreadyCheckedItems = [
          "Pickup",
          "Fuel",
          "Pack",
          "Labor",
          "Inside",
        ];
        const serviceItemData = serviceItemResponse.data.map((data: any) => {
          const storedServiceItem = initialValues?.serviceItems?.find(
            (x: any) => x.id === data.id && x.checked
          );
          if (storedServiceItem) {
            const result = {
              amount: "0",
              ...data,
              ...storedServiceItem,
            };

            if (storedServiceItem.amount) {
              result.amount = formatter.format(storedServiceItem.amount);
            }

            return result;
          }

          return {
            ...data,
            checked: alreadyCheckedItems.includes(data.serviceName),
          };
        });
        setServiceItems(serviceItemData);
      } else {
        setServiceItems([]);
      }
    })();
    currentFormRef(formik);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (serviceItems && serviceItems.length > 0) {
      setTotalServiceCost({
        totalServiceCost: "Total Service Cost: ",
        amount: formatter.format(
          serviceItems?.reduce((a: any, c: any) => {
            return normalizeOnlyNums(c.amount) / 100
              ? Number(normalizeOnlyNums(c.amount) / 100) + Number(a)
              : Number(a);
          }, 0)
        ),
        checked: true,
      });
    }
  }, [serviceItems]);

  type instructionsKeyType =
    | "totalWeight"
    | "totalPalletsRequired"
    | "itemsToDeliver"
    | "laborsNeeded"
    | "laborTime"
    | "noOfVehiclesNeeded"
    | "vehicleType"
    | "scheduledDate"
    | "scheduledTimeFrom"
    | "scheduledTimeTo"
    | "customOrderId";

  const isFormFieldValid = (name: instructionsKeyType) =>
    !!(formik.touched[name] && formik.errors[name]);
  const getFormErrorMessage = (name: instructionsKeyType) => {
    return (
      isFormFieldValid(name) && (
        <small className="p-error">{formik.errors[name]}</small>
      )
    );
  };

  const inputOneTemplate = (rowData: any) => {
    return (
      <>
        {rowData.serviceName === "Labor" && rowData.checked ? (
          <div
            className="p-field"
            style={{
              width: "100%",
              margin: 0,
              marginTop: "0.75rem",
            }}
          >
            <span className="p-float-label">
              <InputText
                id="laborsNeeded"
                name="laborsNeeded"
                value={formik.values.laborsNeeded}
                onChange={(e) => {
                  e.target.value = normalizeOnlyNums(e.target.value);
                  formik.handleChange(e);
                }}
                className={classNames({
                  "p-invalid": isFormFieldValid("laborsNeeded"),
                })}
                style={{ width: "100%" }}
                disabled={readOnly}
              />
              <label
                htmlFor="laborsNeeded"
                className={classNames({
                  "p-error": isFormFieldValid("laborsNeeded"),
                })}
              >
                Men*
              </label>
            </span>
          </div>
        ) : null}

        {rowData.serviceName === "Trucking" && rowData.checked ? (
          <div
            className="p-field"
            style={{
              width: "100%",
              margin: 0,
              marginTop: "0.75rem",
            }}
          >
            <span className="p-float-label">
              <InputText
                id="noOfVehiclesNeeded"
                name="noOfVehiclesNeeded"
                value={formik.values.noOfVehiclesNeeded}
                onChange={(e) => {
                  e.target.value = normalizeOnlyNums(e.target.value);
                  formik.handleChange(e);
                }}
                className={classNames({
                  "p-invalid": isFormFieldValid("noOfVehiclesNeeded"),
                })}
                style={{ width: "100%" }}
                disabled={readOnly}
              />
              <label
                htmlFor="noOfVehiclesNeeded"
                className={classNames({
                  "p-error": isFormFieldValid("noOfVehiclesNeeded"),
                })}
              >
                No Of Vehicles Needed*
              </label>
            </span>
          </div>
        ) : null}
      </>
    );
  };

  const inputTwoTemplate = (rowData: any) => {
    return (
      <>
        {rowData.serviceName === "Labor" && rowData.checked ? (
          <div
            className="p-field"
            style={{
              width: "100%",
              margin: 0,
              marginTop: "0.75rem",
            }}
          >
            <span className="p-float-label">
              <InputText
                id="laborTime"
                name="laborTime"
                value={formik.values.laborTime}
                onChange={(e) => {
                  e.target.value = normalizeOnlyNums(e.target.value);
                  formik.handleChange(e);
                }}
                className={classNames({
                  "p-invalid": isFormFieldValid("laborTime"),
                })}
                style={{ width: "100%" }}
                disabled={readOnly}
              />
              <label
                htmlFor="laborTime"
                className={classNames({
                  "p-error": isFormFieldValid("laborTime"),
                })}
              >
                Hours*
              </label>
            </span>
          </div>
        ) : null}

        {rowData.serviceName === "Trucking" && rowData.checked ? (
          <div
            className="p-field"
            style={{
              width: "100%",
              margin: 0,
              marginTop: "0.75rem",
            }}
          >
            <span className="p-float-label">
              <InputText
                id="vehicleType"
                name="vehicleType"
                value={formik.values.vehicleType}
                onChange={formik.handleChange}
                className={classNames({
                  "p-invalid": isFormFieldValid("vehicleType"),
                })}
                style={{ width: "100%" }}
                disabled={readOnly}
              />
              <label
                htmlFor="vehicleType"
                className={classNames({
                  "p-error": isFormFieldValid("vehicleType"),
                })}
              >
                Vehicle Type*
              </label>
            </span>
          </div>
        ) : null}

        {rowData.totalServiceCost ? (
          <span>{rowData.totalServiceCost}</span>
        ) : null}
      </>
    );
  };

  const serviceCostBody = (rowData: any) => {
    return (
      <>
        {rowData.checked ? (
          <div
            className="p-field"
            style={{
              width: "100%",
              margin: 0,
            }}
          >
            <span className="p-float-label">
              <InputText
                id={`amountField${rowData.id}`}
                name={`amountField${rowData.id}`}
                value={rowData.amount}
                onChange={(e: any) => {
                  e.target.value = normalizeOnlyNums(e.target.value) / 100;

                  e.target.value = formatter.format(e.target.value);

                  const newServiceItems = serviceItems.map((x: any) => {
                    if (x.id === rowData.id) {
                      return {
                        ...x,
                        amount: e.target.value,
                      };
                    }
                    return x;
                  });
                  setServiceItems(newServiceItems);
                }}
                className={classNames({
                  "p-invalid": !Number(normalizeOnlyNums(rowData.amount) / 100),
                })}
                style={{ width: "100%" }}
              />
            </span>
          </div>
        ) : null}
      </>
    );
  };

  const InputComponentTimePicker = (props: any) => {
    return (
      <span className="p-float-label">
        <InputText
          className={classNames({
            "p-invalid": isFormFieldValid(props.name),
          })}
          style={{ width: "100%" }}
          {...props}
        />
        <label
          htmlFor={props.name}
          className={classNames({
            "p-error": isFormFieldValid(props.name),
          })}
        >
          {props.label}
        </label>
      </span>
    );
  };

  const ServiceNameTemplate = (props: any) => {
    return (
      <div
        style={{ display: "flex", flexDirection: "row", alignItems: "center" }}
      >
        <Typography>{props.serviceName}</Typography>

        <Tooltip
          open={isTooltipOpen === props.id}
          onClose={() => {}}
          arrow
          title={
            <Typography
              gutterBottom
              style={{ margin: "0.5rem" }}
              variant="body1"
            >
              {props.serviceDescription}
            </Typography>
          }
        >
          <IconButton
            onClick={() => {
              setIsTooltipOpen((prevState: any) =>
                prevState === props.id ? false : props.id
              );
            }}
          >
            <InfoIcon />
          </IconButton>
        </Tooltip>
      </div>
    );
  };

  return (
    <form
      onSubmit={formik.handleSubmit}
      style={{ width: "100%", fontSize: "0.85rem" }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "row",
        }}
      >
        <div
          className="p-field"
          style={{
            width: "100%",
            marginRight: "0.5rem",
          }}
        >
          <span className="p-float-label">
            <InputText
              id="customOrderId"
              name="customOrderId"
              value={formik.values.customOrderId}
              onChange={(e) => {
                e.target.value = normalizeOnlyNums(e.target.value);
                formik.handleChange(e);
              }}
              className={classNames({
                "p-invalid": isFormFieldValid("customOrderId"),
              })}
              style={{ width: "100%" }}
              disabled={readOnly}
            />
            <label
              htmlFor="customOrderId"
              className={classNames({
                "p-error": isFormFieldValid("customOrderId"),
              })}
            >
              Custom Order Id*
            </label>
          </span>
          {getFormErrorMessage("customOrderId")}
        </div>

        <div
          className="p-field"
          style={{
            width: "100%",
            marginRight: "0.5rem",
          }}
        >
          <span className="p-float-label">
            <InputText
              id="totalWeight"
              name="totalWeight"
              value={formik.values.totalWeight}
              onChange={(e) => {
                e.target.value = normalizeOnlyNums(e.target.value);
                formik.handleChange(e);
              }}
              className={classNames({
                "p-invalid": isFormFieldValid("totalWeight"),
              })}
              style={{ width: "100%" }}
              disabled={readOnly}
            />
            <label
              htmlFor="totalWeight"
              className={classNames({
                "p-error": isFormFieldValid("totalWeight"),
              })}
            >
              Total Shipment Weight (in Lbs)*
            </label>
          </span>
          {getFormErrorMessage("totalWeight")}
        </div>

        <div
          className="p-field"
          style={{ width: "100%", marginLeft: "0.5rem" }}
        >
          <span className="p-float-label">
            <InputText
              id="totalPalletsRequired"
              name="totalPalletsRequired"
              value={formik.values.totalPalletsRequired}
              onChange={(e) => {
                e.target.value = normalizeOnlyNums(e.target.value);
                formik.handleChange(e);
              }}
              className={classNames({
                "p-invalid": isFormFieldValid("totalPalletsRequired"),
              })}
              style={{ width: "100%" }}
              disabled={readOnly}
            />
            <label
              htmlFor="totalPalletsRequired"
              className={classNames({
                "p-error": isFormFieldValid("totalPalletsRequired"),
              })}
            >
              Total Pallets Required*
            </label>
          </span>
          {getFormErrorMessage("totalPalletsRequired")}
        </div>
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
        }}
      >
        <div
          className="p-field"
          style={{
            width: "100%",
            marginRight: "0.5rem",
          }}
        >
          <span className="p-float-label">
            <Calendar
              id="scheduledDate"
              name="scheduledDate"
              value={formik.values.scheduledDate}
              onChange={formik.handleChange}
              className={classNames({
                "p-invalid": isFormFieldValid("scheduledDate"),
              })}
              style={{ width: "100%" }}
              monthNavigator
              yearNavigator
              showIcon
              yearRange={`${moment().toDate().getFullYear()}:${moment()
                .add(20, "year")
                .toDate()
                .getFullYear()}`}
            />
            <label
              htmlFor="scheduledDate"
              className={classNames({
                "p-error": isFormFieldValid("scheduledDate"),
              })}
            >
              Scheduled Date*
            </label>
          </span>
          {getFormErrorMessage("scheduledDate")}
        </div>

        <div
          className="p-field"
          style={{ width: "100%", marginLeft: "0.5rem" }}
        >
          <span className="p-float-label">
            <TimePicker
              id="scheduledTimeFrom"
              name="scheduledTimeFrom"
              label="Time From*"
              defaultValue={null}
              TextFieldComponent={InputComponentTimePicker}
              value={formik.values.scheduledTimeFrom || null}
              onChange={(event: any) => {
                formik.setFieldValue("scheduledTimeFrom", event);
                const scheduledTimeTo = moment(formik.values?.scheduledTimeTo);
                const scheduledTimeFrom = moment(
                  formik.values?.scheduledTimeFrom
                );
                if (
                  scheduledTimeTo &&
                  Math.sign(
                    moment
                      .duration(scheduledTimeTo.diff(scheduledTimeFrom))
                      .asHours()
                  ) === -1
                ) {
                  formik.setFieldValue("scheduledTimeTo", event);
                }
              }}
            />

            {/* <InputMask
              id="scheduledTimeFrom"
              name="scheduledTimeFrom"
              value={formik.values.scheduledTimeFrom}
              onChange={formik.handleChange}
              className={classNames({
                "p-invalid": isFormFieldValid("scheduledTimeFrom"),
              })}
              style={{ width: "100%" }}
              autoClear
              mask="99:99"
              // timeOnly
              // showTime
              // hourFormat="12"
            /> */}
            {/* <label
              htmlFor="scheduledTimeFrom"
              className={classNames({
                "p-error": isFormFieldValid("scheduledTimeFrom"),
              })}
            >
              Time From*
            </label> */}
          </span>
          {getFormErrorMessage("scheduledTimeFrom")}
        </div>
        <div
          className="p-field"
          style={{ width: "100%", marginLeft: "0.5rem" }}
        >
          <span className="p-float-label">
            <TimePicker
              id="scheduledTimeTo"
              name="scheduledTimeTo"
              label="Time To*"
              defaultValue={null}
              TextFieldComponent={InputComponentTimePicker}
              value={formik.values.scheduledTimeTo || null}
              onChange={(event: any) =>
                formik.setFieldValue("scheduledTimeTo", event)
              }
            />
            {/* <InputMask
              id="scheduledTimeTo"
              name="scheduledTimeTo"
              value={formik.values.scheduledTimeTo}
              onChange={formik.handleChange}
              className={classNames({
                "p-invalid": isFormFieldValid("totalPalletsRequired"),
              })}
              style={{ width: "100%" }}
              autoClear
              mask="99:99"
              // timeOnly
              // showTime
              // hourFormat="12"
            /> */}
            {/* <label
              htmlFor="scheduledTimeTo"
              // className={classNames({
              //   "p-error": isFormFieldValid("scheduledTimeTo"),
              // })}
            >
              Time To*
            </label> */}
          </span>
          {getFormErrorMessage("scheduledTimeTo")}
        </div>
      </div>

      {/* <div className="p-field" style={{ width: "100%" }}>
        <span className="p-float-label">
          <InputTextarea
            id="itemsToDeliver"
            name="itemsToDeliver"
            value={formik.values.itemsToDeliver}
            onChange={formik.handleChange}
            className={classNames({
              "p-invalid": isFormFieldValid("itemsToDeliver"),
            })}
            autoResize
            style={{
              width: "100%",
              maxHeight: "5rem",
              overflowX: "hidden",
              overflowY: "auto",
              scrollbarWidth: "thin",
            }}
            disabled={readOnly}
          />
          <label
            htmlFor="itemsToDeliver"
            className={classNames({
              "p-error": isFormFieldValid("itemsToDeliver"),
            })}
          >
            Shipment Description{requireAllFields ? "*" : ""}
          </label>
        </span>
        {getFormErrorMessage("itemsToDeliver")}
      </div> */}

      <DataTable
        loading={!serviceItems.length}
        value={
          // requireAllFields
          //   ? [
          //       ...serviceItems,
          //       {
          //         totalServiceCost: "Total Service Cost: ",
          //         amount: formatter.format(
          //           serviceItems?.reduce((a: any, c: any) => {
          //             return normalizeOnlyNums(c.amount) / 100
          //               ? Number(normalizeOnlyNums(c.amount) / 100) + Number(a)
          //               : Number(a);
          //           }, 0)
          //         ),
          //         checked: true,
          //       },
          //     ]
          //   :
          serviceItems
        }
        sortMode="multiple"
        dataKey="id"
        resizableColumns
        columnResizeMode="expand"
        tableStyle={{
          position: "relative",
          tableLayout: "auto",
          overflow: "auto",
          width: "100%",
        }}
        selection={serviceItems.filter((x: any) => x.checked)}
        onSelectionChange={(e) => {
          // do not let user change if readOnly true
          if (readOnly) {
            return;
          }

          let newServiceItemsArray: any = [...serviceItems];

          newServiceItemsArray = newServiceItemsArray.map((x: any) => {
            if (e.value?.some((y: any) => y.id === x.id)) {
              return {
                ...x,
                checked: true,
              };
            } else {
              return {
                ...x,
                checked: ["Pickup", "Fuel", "Pack", "Labor", "Inside"].includes(
                  x.serviceName
                )
                  ? true
                  : false,
              };
            }
          });

          setServiceItems(newServiceItemsArray);
        }}
      >
        <Column
          selectionMode="multiple"
          style={{ width: "3rem" }}
          className={classes.dataTableColumnStyle}
          headerClassName={classes.dataTableHeaderStyle}
        />
        <Column
          field="serviceName"
          header="Service Name"
          className={classes.dataTableColumnStyle}
          headerClassName={classes.dataTableHeaderStyle}
          body={ServiceNameTemplate}
        ></Column>
        <Column
          field=""
          header=""
          body={inputOneTemplate}
          className={classes.dataTableColumnStyle}
          headerClassName={classes.dataTableHeaderStyle}
        ></Column>
        <Column
          field="totalServiceCost"
          header=""
          body={inputTwoTemplate}
          className={classes.dataTableColumnStyle}
          headerClassName={classes.dataTableHeaderStyle}
        ></Column>
        <Column
          field="serviceCost"
          header="Service Cost"
          body={serviceCostBody}
          className={classes.dataTableColumnStyle}
          headerClassName={classes.dataTableHeaderStyle}
        ></Column>
      </DataTable>
      {requireAllFields && (
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            width: "100%",
            justifyContent: "flex-end",
            border: "1px solid #e9ecef",
            padding: "0.75rem",
            fontSize: "0.9rem",
          }}
        >
          <div
            className="p-field"
            style={{
              width: "100%",
              margin: 0,
            }}
          ></div>
          <div
            className="p-field"
            style={{
              width: "100%",
              margin: 0,
            }}
          ></div>
          <div
            className="p-field"
            style={{
              width: "100%",
              margin: 0,
            }}
          >
            {totalServiceCost.totalServiceCost}
          </div>
          <div
            className="p-field"
            style={{
              width: "100%",
              margin: 0,
            }}
          >
            <span className="p-float-label">
              <InputText
                value={totalServiceCost.amount || 0}
                onChange={(e: any) => {
                  // e.target.value = normalizeOnlyNums(e.target.value) / 100;
                  // e.target.value = formatter.format(e.target.value);
                  // setTotalServiceCost({
                  //   totalServiceCost: "Total Service Cost: ",
                  //   amount: e.target.value,
                  //   checked: true,
                  // });
                }}
                className={classNames({
                  "p-invalid": !Number(
                    normalizeOnlyNums(totalServiceCost.amount) / 100
                  ),
                })}
                style={{ width: "100%" }}
              />
            </span>
          </div>
        </div>
      )}

      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          width: "100%",
          justifyContent: "flex-end",
          marginTop: "1rem",
        }}
      >
        <Button
          label={"Provide Qoutation"}
          icon="pi pi-check"
          style={{
            backgroundColor: "#1769aa",
            borderColor: "#1769aa",
            marginLeft: "2rem",
          }}
          onClick={() => {
            const payload = {
              scheduledDate: formik.values.scheduledDate,
              scheduledTimeFrom: formik.values.scheduledTimeFrom,
              scheduledTimeTo: formik.values.scheduledTimeTo,
              serviceItems: serviceItems.map((x: any) => {
                if (x.checked) {
                  return {
                    ...x,
                    amount: Number(normalizeOnlyNums(x.amount) / 100),
                  };
                }

                return x;
              }),
            };
            handleOnProvideQoute(payload);
          }}
          disabled={
            !formik.values.scheduledDate ||
            !formik.values.scheduledTimeFrom ||
            !formik.values.scheduledTimeTo ||
            serviceItems.find(
              (x: any) =>
                x.checked && !Number(normalizeOnlyNums(x.amount) / 100)
            )
          }
          loading={isSubmittingForm}
        />
      </div>
    </form>
  );
}
