import * as types from "./types";

export const createSchedulerAction = (
  partialStateName: any,
  partialStateValue: any
) => ({
  type: types.SCHEDULER_STATE_CHANGE_ACTION,
  payload: {
    partialStateName,
    partialStateValue,
  },
});
