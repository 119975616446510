import React, { useState } from "react";
import { useFormik } from "formik";
import { InputText } from "primereact/inputtext";
import { Dialog } from "primereact/dialog";
import { classNames } from "primereact/utils";
import { Typography, IconButton } from "@material-ui/core";
import { Clear } from "@material-ui/icons";
import { AutoComplete } from "primereact/autocomplete";
import { Button } from "primereact/button";

import { stateCodes } from "../../../constants/stateCodes";
import {
  normalizePhoneNumber,
  normalizeOnlyNums,
} from "../../../formUtils/normalizations";

type adminType = {
  companyName: string;
  firstName: string;
  lastName: string;
  primaryPhoneNumber: string;
  secondaryPhoneNumber: string;
  addressLine1: string;
  addressLine2: string;
  city: string;
  state: string;
  zipCode: string;
};

export default function ViewAdminDetails({
  initialValues,
  open,
  handleClose,
}: any) {
  const [filteredStates, setFilteredStates] = useState<any>(null);

  const formik = useFormik({
    initialValues: {
      ...initialValues,
      primaryPhoneNumber: normalizePhoneNumber(
        initialValues?.primaryPhoneNumber?.replace("+1", "") || ""
      ),
      secondaryPhoneNumber: normalizePhoneNumber(
        initialValues?.secondaryPhoneNumber?.replace("+1", "") || ""
      ),
      state: initialValues?.state
        ? stateCodes?.find((x) => x.value === initialValues?.state)
        : { label: "", value: "" },
    },
    validate: (data: adminType) => {
      let errors: any = {};

      if (!data.companyName) {
        errors.companyName = "This Field is required.";
      }
      if (!data.firstName) {
        errors.firstName = "This Field is required.";
      }
      if (!data.lastName) {
        errors.lastName = "This Field is required.";
      }
      if (!data.primaryPhoneNumber) {
        errors.primaryPhoneNumber = "This Field is required.";
      }

      return errors;
    },
    onSubmit: async (data: adminType) => {},
  });
  type adminStringType =
    | "companyName"
    | "firstName"
    | "lastName"
    | "primaryPhoneNumber"
    | "secondaryPhoneNumber"
    | "addressLine1"
    | "addressLine2"
    | "city"
    | "state"
    | "zipCode";

  const isFormFieldValid = (name: adminStringType) =>
    !!(formik.touched[name] && formik.errors[name]);
  const getFormErrorMessage = (name: adminStringType) => {
    return (
      isFormFieldValid(name) && (
        <small className="p-error">{formik.errors[name]}</small>
      )
    );
  };

  const searchState = (event: any) => {
    setTimeout(() => {
      let _filteredCountries;
      if (!event.query.trim().length) {
        _filteredCountries = [...stateCodes];
      } else {
        _filteredCountries = stateCodes.filter((state: any) => {
          return state.label
            .toLowerCase()
            .startsWith(event.query.toLowerCase());
        });
      }

      setFilteredStates(_filteredCountries);
    }, 250);
  };

  return (
    <Dialog
      visible={open}
      onHide={handleClose}
      modal
      draggable={false}
      style={{
        width: "50%",
      }}
      contentStyle={{
        padding: 0,
        borderRadius: "5px",
      }}
      showHeader={false}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "space-between",
          padding: "1rem 2rem 0 2rem",
        }}
      >
        <Typography variant="h6">Edit Admin Details</Typography>

        <IconButton
          onClick={handleClose}
          style={{
            position: "absolute",
            right: "-1rem",
            top: "-1rem",
            backgroundColor: "#1769aa",
            color: "#fff",
          }}
        >
          <Clear color="inherit" />
        </IconButton>
      </div>
      <form
        style={{
          width: "100%",
        }}
        onSubmit={formik.handleSubmit}
      >
        <div
          style={{
            width: "100%",
            padding: "2rem 2rem 0 2rem",
            maxHeight: "60vh",
            overflowY: "scroll",
          }}
        >
          <div
            className="p-field"
            style={{
              width: "100%",
              marginBottom: "2rem",
            }}
          >
            <span
              className="p-float-label"
              style={{
                width: "100%",
              }}
            >
              <InputText
                id="companyName"
                name="companyName"
                value={formik.values.companyName}
                onChange={formik.handleChange}
                className={classNames({
                  "p-invalid": isFormFieldValid("companyName"),
                })}
                style={{
                  width: "100%",
                }}
              />
              <label
                htmlFor="companyName"
                className={classNames({
                  "p-error": isFormFieldValid("companyName"),
                })}
              >
                Company Name*
              </label>
            </span>
            {getFormErrorMessage("companyName")}
          </div>

          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              // marginBottom: "2rem",
            }}
          >
            <div
              className="p-field"
              style={{
                width: "100%",
                marginRight: "0.5rem",
              }}
            >
              <span
                className="p-float-label"
                style={{
                  width: "100%",
                }}
              >
                <InputText
                  id="firstName"
                  name="firstName"
                  value={formik.values.firstName}
                  onChange={formik.handleChange}
                  className={classNames({
                    "p-invalid": isFormFieldValid("firstName"),
                  })}
                  style={{
                    width: "100%",
                  }}
                />
                <label
                  htmlFor="firstName"
                  className={classNames({
                    "p-error": isFormFieldValid("firstName"),
                  })}
                >
                  First Name*
                </label>
              </span>
              {getFormErrorMessage("firstName")}
            </div>

            <div
              className="p-field"
              style={{
                width: "100%",
                marginLeft: "0.5rem",
              }}
            >
              <span
                className="p-float-label"
                style={{
                  width: "100%",
                }}
              >
                <InputText
                  id="lastName"
                  name="lastName"
                  value={formik.values.lastName}
                  onChange={formik.handleChange}
                  className={classNames({
                    "p-invalid": isFormFieldValid("lastName"),
                  })}
                  style={{
                    width: "100%",
                  }}
                />
                <label
                  htmlFor="lastName"
                  className={classNames({
                    "p-error": isFormFieldValid("lastName"),
                  })}
                >
                  Last Name*
                </label>
              </span>
              {getFormErrorMessage("lastName")}
            </div>
          </div>

          <div
            style={{
              display: "flex",
              flexDirection: "row",
              // marginBottom: "2rem",
            }}
          >
            <div
              className="p-field"
              style={{
                width: "100%",
                marginRight: "0.5rem",
              }}
            >
              <span
                className="p-input-icon-left p-float-label"
                style={{
                  width: "100%",
                }}
              >
                <i className="pi">+1</i>
                <InputText
                  id="primaryPhoneNumber"
                  name="primaryPhoneNumber"
                  value={formik.values.primaryPhoneNumber}
                  onChange={(e) => {
                    e.target.value = normalizePhoneNumber(e.target.value);
                    formik.handleChange(e);
                  }}
                  className={classNames({
                    "p-invalid": isFormFieldValid("primaryPhoneNumber"),
                  })}
                  style={{
                    width: "100%",
                  }}
                />
                <label
                  htmlFor="primaryPhoneNumber"
                  className={classNames({
                    "p-error": isFormFieldValid("primaryPhoneNumber"),
                  })}
                  style={{
                    marginLeft: formik.values.primaryPhoneNumber
                      ? "-1.5rem"
                      : 0,
                  }}
                >
                  Primary Phone Number*
                </label>
              </span>
              {getFormErrorMessage("primaryPhoneNumber")}
            </div>

            <div
              className="p-field"
              style={{
                width: "100%",
                marginLeft: "0.5rem",
              }}
            >
              <span
                className="p-input-icon-left p-float-label"
                style={{
                  width: "100%",
                }}
              >
                <i className="pi">+1</i>
                <InputText
                  id="secondaryPhoneNumber"
                  name="secondaryPhoneNumber"
                  value={formik.values.secondaryPhoneNumber}
                  onChange={(e) => {
                    e.target.value = normalizePhoneNumber(e.target.value);
                    formik.handleChange(e);
                  }}
                  className={classNames({
                    "p-invalid": isFormFieldValid("secondaryPhoneNumber"),
                  })}
                  style={{
                    width: "100%",
                  }}
                />
                <label
                  htmlFor="secondaryPhoneNumber"
                  className={classNames({
                    "p-error": isFormFieldValid("secondaryPhoneNumber"),
                  })}
                  style={{
                    marginLeft: formik.values.secondaryPhoneNumber
                      ? "-1.5rem"
                      : 0,
                  }}
                >
                  Alternate Phone Number
                </label>
              </span>
              {getFormErrorMessage("secondaryPhoneNumber")}
            </div>
          </div>

          <div
            className="p-field"
            style={{
              width: "100%",
              marginBottom: "2rem",
            }}
          >
            <span
              className="p-float-label"
              style={{
                width: "100%",
              }}
            >
              <InputText
                id="addressLine1"
                name="addressLine1"
                value={formik.values.addressLine1}
                onChange={formik.handleChange}
                className={classNames({
                  "p-invalid": isFormFieldValid("addressLine1"),
                })}
                style={{
                  width: "100%",
                }}
              />
              <label
                htmlFor="addressLine1"
                className={classNames({
                  "p-error": isFormFieldValid("addressLine1"),
                })}
              >
                Address Line 1
              </label>
            </span>
            {getFormErrorMessage("addressLine1")}
          </div>

          <div
            className="p-field"
            style={{
              width: "100%",
              marginBottom: "2rem",
            }}
          >
            <span
              className="p-float-label"
              style={{
                width: "100%",
              }}
            >
              <InputText
                id="addressLine2"
                name="addressLine2"
                value={formik.values.addressLine2}
                onChange={formik.handleChange}
                className={classNames({
                  "p-invalid": isFormFieldValid("addressLine2"),
                })}
                style={{
                  width: "100%",
                }}
              />
              <label
                htmlFor="addressLine2"
                className={classNames({
                  "p-error": isFormFieldValid("addressLine2"),
                })}
              >
                Address Line 2
              </label>
            </span>
            {getFormErrorMessage("addressLine2")}
          </div>

          <div
            className="p-field"
            style={{
              width: "100%",
              marginBottom: "2rem",
            }}
          >
            <span
              className="p-float-label"
              style={{
                width: "100%",
              }}
            >
              <InputText
                id="city"
                name="city"
                value={formik.values.city}
                onChange={formik.handleChange}
                className={classNames({
                  "p-invalid": isFormFieldValid("city"),
                })}
                style={{
                  width: "100%",
                }}
              />
              <label
                htmlFor="city"
                className={classNames({
                  "p-error": isFormFieldValid("city"),
                })}
              >
                City
              </label>
            </span>
            {getFormErrorMessage("city")}
          </div>

          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              // marginBottom: "2rem",
            }}
          >
            <div
              className="p-field"
              style={{
                width: "100%",
                marginRight: "0.5rem",
              }}
            >
              <span
                className="p-float-label"
                style={{
                  width: "100%",
                }}
              >
                <AutoComplete
                  id="state"
                  name="state"
                  field="label"
                  value={formik.values.state}
                  suggestions={filteredStates}
                  completeMethod={searchState}
                  onChange={formik.handleChange}
                  className={classNames({
                    "p-invalid": isFormFieldValid("state"),
                  })}
                  dropdown
                  forceSelection
                  style={{ width: "100%" }}
                />
                <label
                  htmlFor="state"
                  className={classNames({
                    "p-error": isFormFieldValid("state"),
                  })}
                >
                  State
                </label>
              </span>
              {getFormErrorMessage("state")}
            </div>

            <div
              className="p-field"
              style={{
                width: "100%",
                marginLeft: "0.5rem",
              }}
            >
              <span
                className="p-float-label"
                style={{
                  width: "100%",
                }}
              >
                <InputText
                  id="zipCode"
                  name="zipCode"
                  value={formik.values.zipCode}
                  onChange={(e) => {
                    e.target.value = normalizeOnlyNums(e.target.value);
                    formik.handleChange(e);
                  }}
                  className={classNames({
                    "p-invalid": isFormFieldValid("zipCode"),
                  })}
                  style={{
                    width: "100%",
                  }}
                />
                <label
                  htmlFor="zipCode"
                  className={classNames({
                    "p-error": isFormFieldValid("zipCode"),
                  })}
                >
                  Zip Code
                </label>
              </span>
              {getFormErrorMessage("zipCode")}
            </div>
          </div>
        </div>

        <div
          style={{
            display: "flex",
            flexDirection: "row",
            padding: "2rem 3rem 2rem 2rem",
            justifyContent: "flex-end",
          }}
        >
          <Button
            label="Cancel"
            icon="pi pi-times"
            style={{
              borderColor: "#d65c49",
              backgroundColor: "#d65c49",
            }}
            onClick={() => {
              formik.resetForm();
              handleClose();
            }}
          />

          <Button
            type="submit"
            label="Save Changes"
            icon="pi pi-check"
            style={{
              marginLeft: "2rem",
              backgroundColor: "#1769aa",
            }}
          />
        </div>
      </form>
    </Dialog>
  );
}
