// import moment from "moment";

// const date = "2021-07-31";

// const today = new Date(date);

// const year = today.getFullYear();
// const month = today.getMonth();
// const day = today.getDate();
// let dayMinus1: any = moment(date).subtract(2, "days");
// dayMinus1 = new Date(dayMinus1).getDate();

// let dayMinus2: any = moment(date).subtract(3, "days");
// dayMinus2 = new Date(dayMinus2).getDate();

// let dayMinus3: any = moment(date).subtract(4, "days");
// dayMinus3 = new Date(dayMinus3).getDate();

// let dayMinus4: any = moment(date).subtract(5, "days");
// dayMinus4 = new Date(dayMinus4).getDate();

export const appointments = [
  // {
  //   title: "Work Order Number OR0002",
  //   startDate: new Date(year, month, day, 8, 0),
  //   endDate: new Date(year, month, day, 9, 0),
  //   id: 0,
  //   location: "Truck 1",
  // },
  // {
  //   title: "Work Order Number OR0001",
  //   startDate: new Date(year, month, day, 9, 0),
  //   endDate: new Date(year, month, day, 10, 0),
  //   id: 1,
  //   location: "Truck 2",
  // },
  // {
  //   title: "Work Order Number OR0003",
  //   startDate: new Date(year, month, day, 10, 0),
  //   endDate: new Date(year, month, day, 12, 0),
  //   id: 2,
  //   location: "Truck 3",
  // },
  // {
  //   title: "Work Order Number OR0004",
  //   startDate: new Date(year, month, dayMinus1, 8, 0),
  //   endDate: new Date(year, month, dayMinus1, 10, 0),
  //   id: 3,
  //   location: "Truck 1",
  // },
  // {
  //   title: "Work Order Number OR0005",
  //   startDate: new Date(year, month, dayMinus1, 11, 0),
  //   endDate: new Date(year, month, dayMinus1, 13, 0),
  //   id: 4,
  //   location: "Truck 2",
  // },
  // {
  //   title: "Work Order Number OR0006",
  //   startDate: new Date(year, month, dayMinus1, 14, 0),
  //   endDate: new Date(year, month, dayMinus1, 18, 0),
  //   id: 5,
  //   location: "Truck 3",
  // },
  // {
  //   title: "Work Order Number OR0007",
  //   startDate: new Date(year, month, dayMinus2, 9, 0),
  //   endDate: new Date(year, month, dayMinus2, 12, 0),
  //   id: 6,
  //   location: "Truck 1",
  // },
  // {
  //   title: "Work Order Number OR0008",
  //   startDate: new Date(year, month, dayMinus2, 12, 0),
  //   endDate: new Date(year, month, dayMinus2, 17, 0),
  //   id: 7,
  //   location: "Truck 2",
  // },
  // {
  //   title: "Work Order Number OR0009",
  //   startDate: new Date(year, month, dayMinus2, 8, 0),
  //   endDate: new Date(year, month, dayMinus2, 12, 0),
  //   id: 8,
  //   location: "Truck 3",
  // },
  // {
  //   title: "Work Order Number OR00010",
  //   startDate: new Date(year, month, dayMinus3, 8, 0),
  //   endDate: new Date(year, month, dayMinus3, 12, 0),
  //   id: 9,
  //   location: "Truck 1",
  // },
  // {
  //   title: "Work Order Number OR00011",
  //   startDate: new Date(year, month, dayMinus3, 9, 0),
  //   endDate: new Date(year, month, dayMinus3, 12, 0),
  //   id: 10,
  //   location: "Truck 2",
  // },
  // {
  //   title: "Work Order Number OR00012",
  //   startDate: new Date(year, month, dayMinus3, 12, 0),
  //   endDate: new Date(year, month, dayMinus3, 17, 0),
  //   id: 11,
  //   location: "Truck 3",
  // },
  // {
  //   title: "Work Order Number OR00013",
  //   startDate: new Date(year, month, dayMinus4, 12, 0),
  //   endDate: new Date(year, month, dayMinus4, 17, 0),
  //   id: 12,
  //   location: "Truck 1",
  // },
  // {
  //   title: "Work Order Number OR00014",
  //   startDate: new Date(year, month, dayMinus4, 9, 0),
  //   endDate: new Date(year, month, dayMinus4, 12, 0),
  //   id: 13,
  //   location: "Truck 2",
  // },
  // {
  //   title: "Work Order Number OR00015",
  //   startDate: new Date(year, month, dayMinus4, 8, 0),
  //   endDate: new Date(year, month, dayMinus4, 12, 0),
  //   id: 14,
  //   location: "Truck 3",
  // },
];
