import Axios from "../config/interceptors/axiosInterceptor";

/**
 * This function is used to send message
 * @returns
 */
export const createNewMessageGroup = async (payload: any) => {
  try {
    return await Axios.post(
      `${process.env.REACT_APP_API_BASE_URL}/message/create-message-group`,
      payload
    );
  } catch (error: any) {
    return {
      status: 500,
      data: null,
      statusText: "Oops! something went wrong",
    };
  }
};

/**
 * This function is used to get all message
 * @returns
 */
export const getMessageGroups = async (messagesType: "ACTIVE" | "ARCHIVED") => {
  try {
    return await Axios.get(
      `${process.env.REACT_APP_API_BASE_URL}/message/get-all?type=${messagesType}`
    );
  } catch (error: any) {
    return {
      status: 500,
      data: null,
      statusText: "Oops! something went wrong",
    };
  }
};

/**
 * This function is used to send new message in group
 * @returns
 */
export const sendNewMessage = async (payload: {
  groupId: string;
  message: string;
}) => {
  try {
    return await Axios.post(
      `${process.env.REACT_APP_API_BASE_URL}/message/send-new-message`,
      payload
    );
  } catch (error: any) {
    return {
      status: 500,
      data: null,
      statusText: "Oops! something went wrong",
    };
  }
};
