import React, { useState } from "react";
import { Typography } from "@material-ui/core";
import { Button } from "primereact/button";

import { normalizePhoneNumber } from "../../../formUtils/normalizations";
import ShipmentDetails from "./ShipmentDetails";
import {
  previewFileFromFirebase,
  downloadFileFromFirebaseToLocal,
} from "../../../services/downloadDocument.service";

const getFullName = (details: any) => {
  if (details?.primaryContactFirstName || details?.primaryContactLastName) {
    return (
      details?.primaryContactFirstName + " " + details?.primaryContactLastName
    );
  }

  return "N/A";
};

const getAlternateFullName = (details: any) => {
  if (details?.secondaryContactFirstName || details?.secondaryContactLastName) {
    return (
      details?.secondaryContactFirstName +
      " " +
      details?.secondaryContactLastName
    );
  }

  return "N/A";
};

const getAddress = (details: any) => {
  if (
    details?.addressLine1 ||
    details?.addressLine2 ||
    details?.city ||
    details?.state ||
    details?.zipCode
  ) {
    return `${details?.addressLine1 ? details?.addressLine1 + ", " : ""}${
      details?.addressLine2 ? details?.addressLine2 + ", " : ""
    }${details?.city ? details?.city + ", " : ""}${
      details?.state ? details?.state + " " : ""
    }${details?.zipCode}`;
  }

  return "N/A";
};

export default function Confirmation({
  serviceRequestData,
  handleOnProvideQoute,
  isSubmittingForm,
}: any) {
  console.log(serviceRequestData);
  const [isDownloadingFile, setIsDownloadingFile] = useState(false);
  const [isPreviewingFile, setIsPreviewingFile] = useState(false);

  const ItemTemplate = ({ doc }: any) => {
    return (
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          flexWrap: "wrap",
          width: "100%",
          marginBottom: "1rem",
        }}
      >
        <div style={{ display: "flex", alignItems: "center", width: "40%" }}>
          <i
            className={`pi ${
              (doc?.documentName || doc.name)?.includes("pdf")
                ? "pi-file-pdf"
                : "pi-image"
            }`}
            style={{ fontSize: "2em" }}
          ></i>

          <span
            style={{
              display: "flex",
              flexDirection: "column",
              textAlign: "left",
              marginLeft: "1rem",
            }}
          >
            {doc?.documentName || doc.name}
          </span>
        </div>

        <div
          style={{
            display: "flex",
            alignItems: "center",
          }}
        >
          <Button
            type="button"
            icon="pi pi-search-plus"
            className="p-button-primary"
            style={{
              marginRight: "1rem",
              color: "#fff",
            }}
            loading={isPreviewingFile === doc?.documentName}
            onClick={async () => {
              setIsPreviewingFile(doc?.documentName);
              await previewFileFromFirebase(doc.documentUrl, doc.documentName);
              setIsPreviewingFile(false);
            }}
          />

          <Button
            type="button"
            icon="pi pi-download"
            className="p-button-warning"
            style={{
              color: "#fff",
            }}
            loading={isDownloadingFile === doc?.documentName}
            onClick={async () => {
              setIsDownloadingFile(doc?.documentName);
              await downloadFileFromFirebaseToLocal(
                doc.documentUrl,
                doc.documentName
              );
              setIsDownloadingFile(false);
            }}
          />
        </div>
      </div>
    );
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        width: "100%",
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          width: "100%",
          marginBottom: "1rem",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            width: "100%",
            marginRight: "0.5rem",
          }}
        >
          <Typography
            variant="h6"
            style={{
              borderBottom: "1px solid lightgrey",
              marginBottom: "0.5rem",
            }}
          >
            Pickup Details
          </Typography>

          <Typography style={{ fontSize: "0.85rem" }}>
            <b>Primary Contact Name:</b>{" "}
            {getFullName(serviceRequestData?.pickupDetails)}
          </Typography>
          <Typography style={{ fontSize: "0.85rem" }}>
            <b>Primary Contact Phone Number:</b>{" "}
            {serviceRequestData?.pickupDetails?.primaryContactPhoneNumber
              ? normalizePhoneNumber(
                  serviceRequestData?.pickupDetails?.primaryContactPhoneNumber?.split(
                    "+1"
                  )?.[1]
                )
              : "N/A"}
          </Typography>
          <Typography style={{ fontSize: "0.85rem" }}>
            <b>Alternate Contact Name:</b>{" "}
            {getAlternateFullName(serviceRequestData?.pickupDetails)}
          </Typography>
          <Typography style={{ fontSize: "0.85rem" }}>
            <b>Alternate Contact Phone Number:</b>{" "}
            {serviceRequestData?.pickupDetails?.secondaryContactPhoneNumber
              ? normalizePhoneNumber(
                  serviceRequestData?.pickupDetails?.secondaryContactPhoneNumber?.split(
                    "+1"
                  )?.[1]
                )
              : "N/A"}
          </Typography>
          <Typography style={{ fontSize: "0.85rem" }}>
            <b>Pickup Address:</b>{" "}
            {getAddress(serviceRequestData?.pickupDetails)}
          </Typography>
        </div>

        <div
          style={{
            display: "flex",
            flexDirection: "column",
            width: "100%",
            marginLeft: "0.5rem",
          }}
        >
          <Typography
            variant="h6"
            style={{
              borderBottom: "1px solid lightgrey",
              marginBottom: "0.5rem",
            }}
          >
            Destination Details
          </Typography>

          <Typography style={{ fontSize: "0.85rem" }}>
            <b>Primary Contact Name:</b>{" "}
            {getFullName(serviceRequestData?.destinationDetails)}
          </Typography>
          <Typography style={{ fontSize: "0.85rem" }}>
            <b>Primary Contact Phone Number:</b>{" "}
            {serviceRequestData?.destinationDetails?.primaryContactPhoneNumber
              ? normalizePhoneNumber(
                  serviceRequestData?.destinationDetails?.primaryContactPhoneNumber?.split(
                    "+1"
                  )?.[1]
                )
              : "N/A"}
          </Typography>
          <Typography style={{ fontSize: "0.85rem" }}>
            <b>Alternate Contact Name:</b>{" "}
            {getAlternateFullName(serviceRequestData?.destinationDetails)}
          </Typography>
          <Typography style={{ fontSize: "0.85rem" }}>
            <b>Alternate Contact Phone Number:</b>{" "}
            {serviceRequestData?.destinationDetails?.secondaryContactPhoneNumber
              ? normalizePhoneNumber(
                  serviceRequestData?.destinationDetails?.secondaryContactPhoneNumber?.split(
                    "+1"
                  )?.[1]
                )
              : "N/A"}
          </Typography>
          <Typography style={{ fontSize: "0.85rem" }}>
            <b>Pickup Address:</b>{" "}
            {getAddress(serviceRequestData?.destinationDetails)}
          </Typography>
        </div>
      </div>

      <div
        style={{
          display: "flex",
          flexDirection: "column",
          width: "100%",
        }}
      >
        <Typography
          variant="h6"
          style={{
            borderBottom: "1px solid lightgrey",
            marginBottom: "0.5rem",
          }}
        >
          Instructions
        </Typography>

        <Typography style={{ fontSize: "0.85rem" }}>
          <b>General Instructions:</b>{" "}
          {!serviceRequestData?.instructions?.generalInstructions && "N/A"}
        </Typography>
        <Typography
          style={{
            fontSize: "0.85rem",
            marginBottom: "1rem",
          }}
        >
          {serviceRequestData?.instructions?.generalInstructions &&
            serviceRequestData?.instructions?.generalInstructions}
        </Typography>
        <Typography style={{ fontSize: "0.85rem" }}>
          <b>Prior to pickup instructions:</b>{" "}
          {!serviceRequestData?.instructions?.priorToPickupInstructions &&
            "N/A"}
        </Typography>
        <Typography
          style={{
            fontSize: "0.85rem",
            marginBottom: "1rem",
          }}
        >
          {serviceRequestData?.instructions?.priorToPickupInstructions &&
            serviceRequestData?.instructions?.priorToPickupInstructions}
        </Typography>
        <Typography style={{ fontSize: "0.85rem" }}>
          <b>On site instructions:</b>{" "}
          {!serviceRequestData?.instructions?.onSiteInstructions && "N/A"}
        </Typography>
        <Typography
          style={{
            fontSize: "0.85rem",
            marginBottom: "1rem",
          }}
        >
          {serviceRequestData?.instructions?.onSiteInstructions &&
            serviceRequestData?.instructions?.onSiteInstructions}
        </Typography>
        <Typography style={{ fontSize: "0.85rem" }}>
          <b>After pick up instructions:</b>{" "}
          {!serviceRequestData?.instructions?.afterPickupInstructions && "N/A"}
        </Typography>
        <Typography
          style={{
            fontSize: "0.85rem",
            marginBottom: "1rem",
          }}
        >
          {serviceRequestData?.instructions?.afterPickupInstructions &&
            serviceRequestData?.instructions?.afterPickupInstructions}
        </Typography>
      </div>

      <div
        style={{
          display: "flex",
          flexDirection: "column",
          width: "100%",
          marginBottom: "1rem",
        }}
      >
        <Typography
          variant="h6"
          style={{
            borderBottom: "1px solid lightgrey",
            fontSize: "1rem",
            marginBottom: "1rem",
          }}
        >
          Documents Uploaded
        </Typography>

        {serviceRequestData?.instructions?.documents?.length ? (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              width: "100%",
              marginBottom: "1rem",
            }}
          >
            <div
              style={{
                border: "1px solid lightgrey",
                padding: "1rem 1rem 0 1rem",
                borderRadius: "5px",
              }}
            >
              {serviceRequestData?.instructions?.documents.map(
                (document: any) => (
                  <ItemTemplate
                    key={document?.id || document?.name}
                    doc={document}
                  />
                )
              )}
            </div>
          </div>
        ) : (
          <Typography>N/A</Typography>
        )}
      </div>

      <div
        style={{
          display: "flex",
          flexDirection: "column",
          width: "100%",
        }}
      >
        <Typography
          variant="h6"
          style={{
            borderBottom: "1px solid lightgrey",
            marginBottom: "2rem",
            fontSize: "1rem",
          }}
        >
          Shipment Details
        </Typography>

        <Typography style={{ fontSize: "0.85rem", marginBottom: '1rem' }}>
          <b>Waybill #:</b>{" "}
          {!serviceRequestData?.shipmentDetails?.wayBillNumber && "N/A"}
          {serviceRequestData?.shipmentDetails?.wayBillNumber &&
            serviceRequestData?.shipmentDetails?.wayBillNumber}
        </Typography>
     
        <Typography style={{ fontSize: "0.85rem", marginBottom: '1rem' }}>
          <b>Shipper Ref #:</b>{" "}
          {!serviceRequestData?.shipmentDetails?.shipperRefNumber &&
            "N/A"}
          {serviceRequestData?.shipmentDetails?.shipperRefNumber &&
            serviceRequestData?.shipmentDetails?.shipperRefNumber}
        </Typography>

        <Typography style={{ fontSize: "0.85rem" , marginBottom: '1rem'}}>
          <b>Bill-To #:</b>{" "}
          {!serviceRequestData?.shipmentDetails?.billToRefNumber &&
            "N/A"}
            {serviceRequestData?.shipmentDetails?.billToRefNumber &&
            serviceRequestData?.shipmentDetails?.billToRefNumber}
        </Typography>

        <Typography
          variant="h6"
          style={{
            borderBottom: "1px solid lightgrey",
            marginBottom: "2rem",
            fontSize: "1rem",
          }}
        >
        </Typography>

        <ShipmentDetails
          formName="shipmentDetails"
          handleEachStepNext={() => {}}
          currentFormRef={() => {}}
          initialValues={serviceRequestData?.shipmentDetails}
          readOnly
          requireAllFields
          handleOnProvideQoute={handleOnProvideQoute}
          isSubmittingForm={isSubmittingForm}
        />
      </div>
    </div>
  );
}
