import React,{ useEffect, useState , useRef} from "react";
import {
  Grid,
  Paper,
  // InputBase,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Tooltip,
} from "@material-ui/core";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import BingMap from "./BingMap";
import BingMapsReact from 'bingmaps-react';
// import { Search as SearchIcon } from "@material-ui/icons";

// import Map from "../../assets/images/Map.png";
import { getVehicles } from "../../services/vehicle.service";
import { getOrderData } from "../../services/orders.service";
import { Warning } from "@material-ui/icons";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
    },
    paper: {
      backgroundColor: theme.palette.background.paper,
      padding: theme.spacing(2),
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
    },
    map: {},
    select: {
      width: "300px",
      marginRight: "40px",
      marginBottom: "10px",
    },
    search: {
      position: "relative",
      border: `1px solid ${theme.palette.grey[400]}`,
      borderRadius: theme.shape.borderRadius,
      backgroundColor: theme.palette.background.paper,
      width: "300px",
    },
    searchIcon: {
      padding: theme.spacing(0, 2),
      height: "100%",
      position: "absolute",
      pointerEvents: "none",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      color: theme.palette.text.primary,
    },
    inputInput: {
      padding: theme.spacing(1, 1, 1, 0),
      // vertical padding + font size from searchIcon
      paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
      transition: theme.transitions.create("width"),
      width: "100%",
      [theme.breakpoints.up("md")]: {
        width: "20ch",
      },
    },
    row: {
      display: "flex",
      flexDirection: "row",
      width: "100%",
      justifyContent: "center",
      alignItems: "center",
      marginBottom: "20px",
    },
    directionsPanel:{
      overflow:'auto',
      maxHeight:'100vh'
    }
  })
);

export default function DriverRoute({ history, location }: any) {
 
  const classes = useStyles();
  const mapRef= useRef<any>();
  const urlRef = useRef<any>();
  const [pushPins, setPushPins] = useState<any>([]);

  const mapReadyEvent = (event:any) => {
      mapRef.current = event.map.current;
      const Microsoft = (window as any).Microsoft;
      const search = location.search; 
      const params = new URLSearchParams(search);
      urlRef.current = params;
      if (params.get('type') === 'directions') {
        Microsoft.Maps.loadModule('Microsoft.Maps.Directions', function () {
          var directionsManager = new Microsoft.Maps.Directions.DirectionsManager(event.map.current);
          directionsManager.setRenderOptions({ itineraryContainer: document.getElementById('printoutPanel') });
          directionsManager.setRequestOptions({
              routeMode: Microsoft.Maps.Directions.RouteMode.truck,
              vehicleSpec: {
                  dimensionUnit: 'ft',
                  weightUnit: 'lb',
                  vehicleHeight: 5,
                  vehicleWidth: 3.5,
                  vehicleLength: 30,
                  vehicleWeight: 30000,
                  vehicleAxles: 3,
                  vehicleTrailers: 2,
                  vehicleSemi: true,
                  vehicleMaxGradient: 10,
                  vehicleMinTurnRadius: 15,
                  vehicleAvoidCrossWind: true,
                  vehicleAvoidGroundingRisk: true,
                  vehicleHazardousMaterials: 'F',
                  vehicleHazardousPermits: 'F'
              }
          });
          var wp1 = new Microsoft.Maps.Directions.Waypoint({
              address: params.get('start') || '590 Crane Ave, Pittsburgh, PA',
              location: new Microsoft.Maps.Location(Number(params.get('startLatitude')) || 40.419228,Number(params.get('startLongitude')) || -80.018138)
          });
          var wp2 = new Microsoft.Maps.Directions.Waypoint({
              address: params.get('end') ||'600 Forbes Ave, Pittsburgh, PA',
              location: new Microsoft.Maps.Location(Number(params.get('endLatitude')) || 40.437550,Number(params.get('endLongitude')) || -79.993610)
          });
          directionsManager.addWaypoint(wp1);
          directionsManager.addWaypoint(wp2);
          directionsManager.calculateDirections();
        });
      } else if (params.get('type') === 'locations') {
        setPushPins([{ 'center': {latitude: Number(params.get('latitude')) ,longitude: Number(params.get('longitude'))} }])
      }
  }

  return (
    <Grid container className={classes.root} spacing={4}>
      <Grid item sm={12}>
        <Paper>
          {/* <div className={classes.row}>
            <FormControl className={classes.select}>
              <InputLabel id="simple-select-label">Choose Asset</InputLabel>
              <Select
                labelId="simple-select-label"
                id="simple-select"
                value={asset}
                onChange={(e: any) => {
                  setAsset(e.target.value);
                  setValueVehicle("");
                  setSelectedVehicle("");
                  setValueWO("");
                  setSelectedWO("");
                  setWarning(false);
                }}
              >
                <MenuItem value="Vehicle">Vehicle (License Number)</MenuItem>
                <MenuItem value="Driver">Driver</MenuItem> 
                <MenuItem value="Order Number">Order Number</MenuItem>
                <MenuItem value="Pickup Location History">
                  Pickup Location History
                </MenuItem>
                <MenuItem value="Inventory Location">
                  Inventory Location
                </MenuItem>
              </Select>
            </FormControl>
            {asset === "Order Number" && (
              <>
                <FormControl className={classes.select} style={{marginRight:'10px'}}>
                  <InputLabel id="simple-select-label">
                    Order ID
                  </InputLabel>
                  <Select
                    labelId="simple-select-label"
                    id="simple-select"
                    value={valueWO}
                    onChange={(e: any) => {
                      setValueWO(e.target.value);
                      setSelectedWO(
                        workOrders.find(
                          (data: any) => data.value === e.target.value
                        )
                      );
                    }}
                  >
                    <MenuItem value="Truck">Truck</MenuItem>
                    <MenuItem value="Driver">Driver</MenuItem>
                    {workOrders.map((workOrder: any) => (
                      <MenuItem value={workOrder.value}>{workOrder.label}</MenuItem>
                    ))}
                    <MenuItem value="Pickup Location History">
                    Pickup Location History
                  </MenuItem>
                  <MenuItem value="Inventory Location">
                    Inventory Location
                  </MenuItem>
                  </Select>
                </FormControl>
                {
                  warning && (
                    <span>
                      <Tooltip title="Location is unavailable">
                        <Warning style={{fontSize:'30px',fill: '#ffcc00'}}/>
                      </Tooltip>
                    </span>
                  )
                }
              </>
            )}
            {asset === "Vehicle" && (
              <>
                <FormControl className={classes.select}>
                  <InputLabel id="simple-select-label">
                    Vehicle (License Number)
                  </InputLabel>
                  <Select
                    labelId="simple-select-label"
                    id="simple-select"
                    value={valueVehicle}
                    onChange={(e: any) => {
                      setValueVehicle(e.target.value);
                      setSelectedVehicle(
                        vehicles.find(
                          (data: any) => data.value === e.target.value
                        )
                      );
                    }}
                  >
                    {vehicles.map((vehicle: any) => (
                      <MenuItem value={vehicle.value}>{vehicle.label}</MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </>
            )}

            <div className={classes.search}>
              <div className={classes.searchIcon}>
                <SearchIcon />
              </div>
              <InputBase
                placeholder="Search ..."
                classes={{
                  input: classes.inputInput,
                }}
                inputProps={{ "aria-label": "search" }}
                value={searchValue}
                onChange={(e: any) => {
                  setSearchValue(e.target.value);
                }}
              />
            </div>
          </div> */}

          {/* <BingMap
            bingMapsKey="Atk7RXE5J3RmhV5BwD416JWE4I6aJ724Zc-5QsBC97PYtwC-smqOe3fx22RV_dHG"
            mapOptions={{
              center: [47.60357, -122.32945],
              credentials:
                "Atk7RXE5J3RmhV5BwD416JWE4I6aJ724Zc-5QsBC97PYtwC-smqOe3fx22RV_dHG"
            }}
          /> */}
          <div className={classes.row}>
              <BingMapsReact
                bingMapsKey="Atk7RXE5J3RmhV5BwD416JWE4I6aJ724Zc-5QsBC97PYtwC-smqOe3fx22RV_dHG"
                height="100vh"
                mapOptions={{
                  navigationBarMode: "square",
                }}
                onMapReady={mapReadyEvent}
                width="100vw"
                viewOptions={{
                  center: urlRef.current && pushPins.length > 0 ? {latitude: Number(urlRef.current.get('latitude')) ,longitude: Number(urlRef.current.get('longitude'))} : { latitude: 42.360081, longitude: -71.058884 },
                  mapTypeId: "road",
                }}
                pushPins={pushPins}
              />
              <div id='printoutPanel' className={classes.directionsPanel}></div>
          </div>
        </Paper>
      </Grid>
    </Grid>
  );
}
