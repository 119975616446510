import { AnyAction } from "redux";
import { ThunkAction } from "@reduxjs/toolkit";

import * as vehicleTypes from "./types";
import { getVehicles } from "../../services/vehicle.service";

const types = {
  ...vehicleTypes,
};


/**
 * Action to fetch the Vehicles.
 * @param searchText 
 * @param pageNumber 
 * @param pageSize 
 * @returns 
 */
export const fetchAllVehicles =
(searchText: string = '', pageNumber: number = 1,pageSize: number = 10): ThunkAction<void, any, unknown, AnyAction> =>
async (dispatch): Promise<void> => {
    const allVehicleResponse = await getVehicles(searchText, pageNumber, pageSize);
    if (allVehicleResponse.status === 200) {
        dispatch({
            type: types.FETCH_ALL_VEHICLES_SUCCESS,
            payload: allVehicleResponse.data
        });
    } else {
        dispatch({
            type: types.FETCH_ALL_VEHICLES_ERROR,
            payload: []
        }); 
    }
};