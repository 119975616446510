import React, { useEffect, useState } from "react";
import { Button } from "primereact/button";
import TextField from "@material-ui/core/TextField";
import { Dialog } from "primereact/dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import { updateVehicle } from "../../../services/vehicle.service";
import { classNames } from "primereact/utils";

import moment from "moment";
import {
  Select,
  MenuItem,
  createStyles,
  makeStyles,
  Theme,
  FormHelperText,
  IconButton,
  Typography,
} from "@material-ui/core";
import { validateRequired } from "../../../formUtils/validations";
import CloseIcon from "@material-ui/icons/Close";
import { useDispatch } from "react-redux";
import { showAlert } from "../../../redux/alerts/actions";
import { useFormik } from "formik";
import { Clear } from "@material-ui/icons";
import { InputText } from "primereact/inputtext";
import { Dropdown } from "primereact/dropdown";
import { Calendar } from 'primereact/calendar';


const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    selectBox: {
      padding: "10px 0px 5px 0px",
    },
  })
);

interface IProps {
  open: boolean;
  handleClose: (event: { show: boolean; refreshPage: boolean }) => void;
  vehicleToBeEdited: VehicleEdit | null;
}

interface VehicleEdit {
  vehicleId: string;
  vehicleVinNumber: string;
  vehicleLicensePlates: string;
  vehicleMake: string;
  vehicleModel: string;
  vehicleColor: string;
  vehicleType: string;
  vehicleRentedFrom: Date | null;
  vehicleRentedTill: Date | null;
};

const EditVehicleModal = (props: IProps) => {
  /**
   * Variable declaration.
   */
  const classes = useStyles();
  // const [vehicleDetails, setVehicleDetails] = useState<VehicleEdit>({
  //   ...initial_vehicle_details,
  // });
  const dispatch = useDispatch();
  const [editingVehicle, setEditingVehicle] = useState<boolean>(false);

  const formik: any = useFormik({
    initialValues: {
      vehicleId: "",
      vehicleVinNumber: "",
      vehicleLicensePlates: "",
      vehicleMake: "",
      vehicleModel: "",
      vehicleColor: "",
      vehicleType: "OWNED",
      vehicleRentedFrom: null,
      vehicleRentedTill: null,
    },
    validate: (data: VehicleEdit) => {
      let errors: any = {};

      if (!data.vehicleVinNumber) {
        errors.vehicleVinNumber = "This Field is required.";
      }

      if (!data.vehicleLicensePlates) {
        errors.vehicleLicensePlates = "This Field is required.";
      }
      if (!data.vehicleType) {
        errors.vehicleType = "This Field is required.";
      }

      return errors;
    },
    onSubmit: async (data: VehicleEdit) => {
      try {
        await updateVehicleEvent(data);
      } catch (error: any) {
        console.error(error);
      }
    },
  });
  type vehicleStringTypes =
    | "vehicleVinNumber"
    | "vehicleLicensePlates"
    | "vehicleMake"
    | "vehicleModel"
    | "vehicleColor"
    | "vehicleType"
    | "secondaryPhoneNumber"
    | "vehicleRentedFrom"
    | "vehicleRentedTill";

  const isFormFieldValid = (name: vehicleStringTypes) =>
    !!(formik.touched[name] && formik.errors[name]);
  const getFormErrorMessage = (name: vehicleStringTypes) => {
    return (
      isFormFieldValid(name) && (
        <small className="p-error">{formik.errors[name]}</small>
      )
    );
  };
  /**
   * Component did mount.
   */
  useEffect(() => {
    if (props.vehicleToBeEdited) {
      formik.setValues({
        ...props.vehicleToBeEdited,
        vehicleRentedFrom: props.vehicleToBeEdited.vehicleRentedFrom ? moment(props.vehicleToBeEdited.vehicleRentedFrom).toDate() : null,
        vehicleRentedTill: props.vehicleToBeEdited.vehicleRentedTill ? moment(props.vehicleToBeEdited.vehicleRentedTill).toDate() : null
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  /**
   * Edit vehicle api call.
   */
  const updateVehicleEvent = async (vehicleDetails: VehicleEdit) => {
    try {
      setEditingVehicle(true);
      const editVehicleResponse = await updateVehicle(vehicleDetails);
      setEditingVehicle(false);
      if (editVehicleResponse.status === 200) {
        dispatch(
          showAlert({ message: "Vehicle edited successfully", type: "success" })
        );
        formik.resetForm()
        props.handleClose({ show: false, refreshPage: true });
      } else {
        dispatch(
          showAlert({
            message: "Oops! something went wrong. Please try again.",
            type: "error",
          })
        );
      }
    } catch (error: any) {
      console.error(error);
    }
  };

  /**
   * On change handler of input elements.
   * @param event
   */
  // const onChangeHandler = (event: any) => {
  //   const payload: any = {
  //     ...vehicleDetails,
  //   };
  //   payload[event.target.id] = event.target.value;
  //   setVehicleDetails(payload);
  // };

  /**
   * On change handler of dropdown elements
   * @param event
   * @param fieldName
   */
  // const onChangeHandlerDropdown = (event: any, fieldName: string) => {
  //   const payload: any = {
  //     ...vehicleDetails,
  //   };
  //   payload[fieldName] = event.target.value;
  //   setVehicleDetails(payload);
  // };

  // const validateFields = () => {
  //   if (
  //     vehicleDetails.vehicleLicensePlates &&
  //     vehicleDetails.vehicleType &&
  //     vehicleDetails.vehicleVinNumber
  //   ) {
  //     return false;
  //   }
  //   return true;
  // };

  return (
    <Dialog
      visible={props.open}
      onHide={() => {
        formik.resetForm()
        props.handleClose({ show: false, refreshPage: false })
      }}
      draggable={false}
      style={{
        width: "50%",
      }}
      contentStyle={{
        padding: 0,
        borderRadius: "5px",
      }}
      showHeader={false}
    >
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "space-between",
        padding: "1rem 2rem 0 2rem",
      }}
    >
      <Typography variant="h6">Edit Vehicle</Typography>

      <IconButton
        onClick={() =>
          {
            formik.resetForm()
            props.handleClose({ show: false, refreshPage: false })
          }
        }
        style={{
          position: "absolute",
          right: "-1rem",
          top: "-1rem",
          backgroundColor: "#1769aa",
          color: "#fff",
        }}
      >
        <Clear color="inherit" />
      </IconButton>
    </div>
    <form
      style={{
        width: "100%",
      }}
      onSubmit={formik.handleSubmit}
    >
      <div
        style={{
          width: "100%",
          padding: "2rem 2rem 0 2rem",
          maxHeight: "60vh",
          overflowY: "auto",
        }}
      > 
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            marginBottom: "2rem",
          }}
        >
          <div
            className="p-field"
            style={{
              width: "100%",
              marginRight: "0.5rem",
            }}
          >
            <span
              className="p-float-label"
              style={{
                width: "100%",
              }}
            >
              <InputText
                id="vehicleVinNumber"
                name="vehicleVinNumber"
                value={formik.values.vehicleVinNumber}
                onChange={formik.handleChange}
                className={classNames({
                  "p-invalid": isFormFieldValid("vehicleVinNumber"),
                })}
                style={{
                  width: "100%",
                }}
              />
              <label
                htmlFor="vehicleVinNumber"
                className={classNames({
                  "p-error": isFormFieldValid("vehicleVinNumber"),
                })}
              >
                VIN Number*
              </label>
            </span>
            {getFormErrorMessage("vehicleVinNumber")}
          </div>
          <div
            className="p-field"
            style={{
              width: "100%",
              marginLeft: "0.5rem",
            }}
          >
            <span
              className="p-float-label"
              style={{
                width: "100%",
              }}
            >
              <InputText
                id="vehicleLicensePlates"
                name="vehicleLicensePlates"
                value={formik.values.vehicleLicensePlates}
                onChange={formik.handleChange}
                className={classNames({
                  "p-invalid": isFormFieldValid("vehicleLicensePlates"),
                })}
                style={{
                  width: "100%",
                }}
              />
              <label
                htmlFor="vehicleLicensePlates"
                className={classNames({
                  "p-error": isFormFieldValid("vehicleLicensePlates"),
                })}
              >
                License Plates*
              </label>
            </span>
            {getFormErrorMessage("vehicleLicensePlates")}
          </div>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            marginBottom: "2rem",
          }}
        >
          <div
            className="p-field"
            style={{
              width: "100%",
              marginRight: "0.5rem",
            }}
          >
            <span
              className="p-float-label"
              style={{
                width: "100%",
              }}
            >
              <InputText
                id="vehicleMake"
                name="vehicleMake"
                value={formik.values.vehicleMake}
                onChange={formik.handleChange}
                className={classNames({
                  "p-invalid": isFormFieldValid("vehicleMake"),
                })}
                style={{
                  width: "100%",
                }}
              />
              <label
                htmlFor="vehicleMake"
                className={classNames({
                  "p-error": isFormFieldValid("vehicleMake"),
                })}
              >
                Make
              </label>
            </span>
            {getFormErrorMessage("vehicleMake")}
          </div>
          <div
            className="p-field"
            style={{
              width: "100%",
              marginLeft: "0.5rem",
            }}
          >
            <span
              className="p-float-label"
              style={{
                width: "100%",
              }}
            >
              <InputText
                id="vehicleModel"
                name="vehicleModel"
                value={formik.values.vehicleModel}
                onChange={formik.handleChange}
                className={classNames({
                  "p-invalid": isFormFieldValid("vehicleModel"),
                })}
                style={{
                  width: "100%",
                }}
              />
              <label
                htmlFor="vehicleModel"
                className={classNames({
                  "p-error": isFormFieldValid("vehicleModel"),
                })}
              >
                Model
              </label>
            </span>
            {getFormErrorMessage("vehicleModel")}
          </div>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            marginBottom: "2rem",
          }}
        >
          <div
            className="p-field"
            style={{
              width: "100%",
              marginRight: "0.5rem",
            }}
          >
            <span
              className="p-float-label"
              style={{
                width: "100%",
              }}
            >
              <InputText
                id="vehicleColor"
                name="vehicleColor"
                value={formik.values.vehicleColor}
                onChange={formik.handleChange}
                className={classNames({
                  "p-invalid": isFormFieldValid("vehicleColor"),
                })}
                style={{
                  width: "100%",
                }}
              />
              <label
                htmlFor="vehicleColor"
                className={classNames({
                  "p-error": isFormFieldValid("vehicleColor"),
                })}
              >
                Color
              </label>
            </span>
            {getFormErrorMessage("vehicleColor")}
          </div>
          <div
            className="p-field"
            style={{
              width: "100%",
              marginLeft: "0.5rem",
            }}
          >
            <span
              className="p-float-label"
              style={{
                width: "100%",
              }}
            >
              <Dropdown 
                id="vehicleType"
                name="vehicleType"
                value={formik.values.vehicleType}
                options={['OWNED','RENTED']}
                onChange={(e: any)=> {
                  formik.handleChange(e);
                  if (e.value === 'OWNED') {
                    formik.setFieldValue('vehicleRentedTill', null);
                    formik.setFieldValue('vehicleRentedFrom', null);
                  }
                }}
                className={classNames({
                  "p-invalid": isFormFieldValid("vehicleType"),
                })}
                style={{
                  width: "100%",
                }}  
              />
               <label
                htmlFor="vehicleType"
                className={classNames({
                  "p-error": isFormFieldValid("vehicleType"),
                })}
              >
                Type*
              </label>
            </span>
            {getFormErrorMessage("vehicleType")}
          </div>
        </div>
        {formik.values.vehicleType === 'RENTED' && (
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            marginBottom: "2rem",
          }}
        >
          <div
            className="p-field"
            style={{
              width: "100%",
              marginRight: "0.5rem",
            }}
          >
            <span
              className="p-float-label"
              style={{
                width: "100%",
              }}
            >
              <Calendar
                id="vehicleRentedFrom"
                name="vehicleRentedFrom"
                value={formik.values.vehicleRentedFrom}
                onChange={(event: any) => {
                  formik.handleChange(event);
                  if (formik.values.vehicleRentedTill) {
                    formik.setFieldValue('vehicleRentedTill', null);
                  }
                }}
                className={classNames({
                  "p-invalid": isFormFieldValid("vehicleRentedFrom"),
                })}
                style={{
                  width: "100%",
                }}
                showIcon
                showTime
                hourFormat="12" 
                hideOnDateTimeSelect
                readOnlyInput
                monthNavigator
                yearNavigator
                yearRange="2010:2030"
              />
              <label
                htmlFor="vehicleRentedFrom"
                className={classNames({
                  "p-error": isFormFieldValid("vehicleRentedFrom"),
                })}
              >
                Rented From
              </label>
            </span>
            {getFormErrorMessage("vehicleRentedFrom")}
          </div>
          <div
            className="p-field"
            style={{
              width: "100%",
              marginBottom: "2rem",
            }}
          >
           <span
              className="p-float-label"
              style={{
                width: "100%",
                marginLeft: "0.5rem",
              }}
            >
              <Calendar
                id="vehicleRentedTill"
                name="vehicleRentedTill"
                disabled={!formik.values.vehicleRentedFrom}
                value={formik.values.vehicleRentedTill}
                onChange={formik.handleChange}
                className={classNames({
                  "p-invalid": isFormFieldValid("vehicleRentedTill"),
                })}
                style={{
                  width: "100%",
                }}
                showIcon
                showTime
                hourFormat="12" 
                minDate={formik.values.vehicleRentedFrom}
                hideOnDateTimeSelect
                readOnlyInput
                monthNavigator
                yearNavigator
                yearRange="2010:2030"
              />
              <label
                htmlFor="vehicleRentedTill"
                className={classNames({
                  "p-error": isFormFieldValid("vehicleRentedTill"),
                })}
              >
                Rented Till
              </label>
            </span>
            {getFormErrorMessage("vehicleRentedTill")}
          </div>
        </div>)}
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          padding: "2rem 3rem 2rem 2rem",
          justifyContent: "flex-end",
        }}
      >
        <Button
          label="Cancel"
          icon="pi pi-times"
          style={{
            borderColor: "#d65c49",
            backgroundColor: "#d65c49",
          }}
          onClick={() => {
            formik.resetForm();
            props.handleClose({ show: false, refreshPage: false })
          }}
          disabled={editingVehicle}
        />

        <Button
          type="submit"
          label="Continue"
          icon="pi pi-check"
          loading={editingVehicle}
          style={{
            marginLeft: "2rem",
            backgroundColor: "#1769aa",
          }}
        />
      </div>
    </form>
  </Dialog>
    // <Dialog
    //   open={props.open}
    //   disableBackdropClick={true}
    //   onClose={() => props.handleClose({ show: false, refreshPage: false })}
    //   aria-labelledby="form-dialog-title"
    // >
    //   <DialogTitle id="form-dialog-title">
    //     <div style={{ display: "flex", justifyContent: "space-between" }}>
    //       <div style={{ alignSelf: "center" }}>Edit Vehicle</div>
    //       <IconButton
    //         aria-label="close"
    //         onClick={() =>
    //           props.handleClose({ show: false, refreshPage: false })
    //         }
    //       >
    //         <CloseIcon />
    //       </IconButton>
    //     </div>
    //   </DialogTitle>
    //   <DialogContent>
    //     <TextField
    //       error={!vehicleDetails.vehicleVinNumber}
    //       autoFocus
    //       margin="dense"
    //       id="vehicleVinNumber"
    //       label="VIN Number"
    //       type="text"
    //       fullWidth
    //       value={vehicleDetails.vehicleVinNumber || ""}
    //       onChange={onChangeHandler}
    //       helperText={validateRequired(vehicleDetails.vehicleVinNumber)}
    //     />
    //     <TextField
    //       error={!vehicleDetails.vehicleLicensePlates}
    //       margin="dense"
    //       id="vehicleLicensePlates"
    //       label="License Plates"
    //       type="text"
    //       fullWidth
    //       value={vehicleDetails.vehicleLicensePlates || ""}
    //       onChange={onChangeHandler}
    //       helperText={validateRequired(vehicleDetails.vehicleLicensePlates)}
    //     />

    //     <TextField
    //       margin="dense"
    //       id="vehicleMake"
    //       label="Make"
    //       type="text"
    //       fullWidth
    //       value={vehicleDetails.vehicleMake || ""}
    //       onChange={onChangeHandler}
    //     />
    //     <TextField
    //       margin="dense"
    //       id="vehicleModel"
    //       label="Model"
    //       type="text"
    //       fullWidth
    //       value={vehicleDetails.vehicleModel || ""}
    //       onChange={onChangeHandler}
    //     />
    //     <TextField
    //       margin="dense"
    //       id="vehicleColor"
    //       label="Color"
    //       type="text"
    //       fullWidth
    //       value={vehicleDetails.vehicleColor || ""}
    //       onChange={onChangeHandler}
    //     />
    //     <Select
    //       labelId="vehicleType"
    //       label="Type"
    //       className={classes.selectBox}
    //       id="vehicleType"
    //       value={vehicleDetails.vehicleType}
    //       error={!vehicleDetails.vehicleType}
    //       onChange={(event: any) =>
    //         onChangeHandlerDropdown(event, "vehicleType")
    //       }
    //       fullWidth
    //       margin="dense"
    //     >
    //       <MenuItem value={"OWNED"}>OWNED</MenuItem>
    //       <MenuItem value={"RENTED"}>RENTED</MenuItem>
    //     </Select>
    //     <FormHelperText>
    //       {validateRequired(vehicleDetails.vehicleVinNumber)}
    //     </FormHelperText>

    //     {vehicleDetails?.vehicleType === "RENTED" && (
    //       <>
    //         <TextField
    //           id="vehicleRentedTill"
    //           label="Rented Till"
    //           type="datetime-local"
    //           fullWidth
    //           margin="dense"
    //           value={moment(vehicleDetails.vehicleRentedTill).format(
    //             "yyyy-MM-DDThh:mm:ss.SSS"
    //           )}
    //           InputLabelProps={{
    //             shrink: true,
    //           }}
    //           onChange={onChangeHandler}
    //         />

    //         <TextField
    //           id="vehicleRentedFrom"
    //           label="Rented From"
    //           type="datetime-local"
    //           fullWidth
    //           margin="dense"
    //           value={moment(vehicleDetails.vehicleRentedFrom).format(
    //             "yyyy-MM-DDThh:mm:ss.SSS"
    //           )}
    //           InputLabelProps={{
    //             shrink: true,
    //           }}
    //           onChange={onChangeHandler}
    //         />
    //       </>
    //     )}
    //   </DialogContent>
    //   <DialogActions>
    //     <Button
    //       onClick={() => props.handleClose({ show: false, refreshPage: false })}
    //       color="primary"
    //     >
    //       Cancel
    //     </Button>
    //     <Button
    //       disabled={validateFields()}
    //       onClick={updateVehicleEvent}
    //       color="primary"
    //     >
    //       Save
    //     </Button>
    //   </DialogActions>
    // </Dialog>
  );
};

export default EditVehicleModal;
