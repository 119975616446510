import Axios from "../config/interceptors/axiosInterceptor";

/**
 * This function is used to get all the customers
 * @returns
 */
export const getAllCustomers = async (
  searchText: string,
  pageNumber: number,
  pageSize: number
) => {
  try {
    return await Axios.get(
      `${
        process.env.REACT_APP_API_BASE_URL
      }/customer/get-all?page_number=${pageNumber}&page_size=${pageSize}&search_term=${
        searchText || ""
      }`
    );
  } catch (error: any) {
    return { status: 500, data: [], statusText: "Oops! something went wrong" };
  }
};

/**
 * This function is used to get all the customer profile
 * @returns
 */
export const getCustomerProfile = async (profileID: string) => {
  try {
    return await Axios.get(
      `${process.env.REACT_APP_API_BASE_URL}/profile/get?id=${profileID}`
    );
  } catch (error: any) {
    return { status: 500, data: [], statusText: "Oops! something went wrong" };
  }
};

/**
 * This function is used to onboard a new customer
 * @returns
 */
export const onboardCustomer = async (payload: any) => {
  try {
    return await Axios.post(
      `${process.env.REACT_APP_API_BASE_URL}/customer/create`,
      payload
    );
  } catch (error: any) {
    return {
      status: 500,
      data: null,
      statusText: "Oops! something went wrong",
    };
  }
};
/**
 * This function is used to reset a customer password
 * @returns
 */
export const resetCustomerPassword = async (payload: any) => {
  try {
    return await Axios.post(
      `${process.env.REACT_APP_API_BASE_URL}/customer/update-password`,
      payload
    );
  } catch (error: any) {
    return {
      status: 500,
      data: null,
      statusText: "Oops! something went wrong",
    };
  }
};
