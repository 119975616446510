import * as vehicleTypes from "./types";

const types = {
  ...vehicleTypes,
};

const initialState = {
  totalVehicles: 0,
  list: [],
  totalPages: 1,
  currentPage: 1,
};

export default function vehiclesReducer(
  state = initialState,
  action: { type: string; payload: any }
) {
  switch (action.type) {
    case types.FETCH_ALL_VEHICLES_ERROR:
      return {
        ...state,
        totalVehicles: 0,
        list: [],
        totalPages: 0,
        currentPage: 0,
      };
    case types.FETCH_ALL_VEHICLES_SUCCESS:
      return {
        ...state,
        totalVehicles: action.payload.totalCount,
        list: action.payload.records,
        totalPages: action.payload.totalPages,
        currentPage: action.payload.currentPage,
      };
    default:
      return state;
  }
}
