import { AnyAction } from "redux";
import { ThunkAction } from "@reduxjs/toolkit";

import * as customerType from "./types";
import { getAllCustomers } from "../../services/customer.service";

const types = {
  ...customerType,
};


/**
 * Action to fetch the customers.
 * @param searchText 
 * @param pageNumber 
 * @param pageSize 
 * @returns 
 */
export const fetchAllCustomers =
(searchText: string = '', pageNumber: number = 1,pageSize: number = 10): ThunkAction<void, any, unknown, AnyAction> =>
async (dispatch): Promise<void> => {
    const allCustomerResponse = await getAllCustomers(searchText, pageNumber, pageSize);
    if (allCustomerResponse.status === 200) {
        dispatch({
            type: types.FETCH_ALL_CUSTOMER_SUCCESS,
            payload: allCustomerResponse.data
        });
    } else {
        dispatch({
            type: types.FETCH_ALL_CUSTOMER_ERROR,
            payload: []
        }); 
    }
};