import { useSelector } from "react-redux";
import { Paper, Typography } from "@material-ui/core";
import React, { useState, useEffect } from "react";
import { InputTextarea } from "primereact/inputtextarea";
import { Button } from "primereact/button";
import moment from "moment";
import { getDatabase, ref, onChildAdded } from "firebase/database";
import { sendNewMessage } from "../../services/message.service";

export default function ChatBox({ currentMessageGroup }: any) {
  const [message, setmessage] = useState("");
  const [messages, setMessages] = useState<any>([]);
  const loggedInUserName = useSelector((state: any) => {
    return `${state.profile.firstName} ${state.profile.lastName}`;
  });

  useEffect(() => {
    if (currentMessageGroup) {
      const db = getDatabase();

      setMessages([]);

      onChildAdded(
        ref(
          db,
          `${process.env.REACT_APP_FIREBASE_MESSAGE_ROUTEIN_DATABSE}/${currentMessageGroup.id}/messages`
        ),
        (snapshot) => {
          if (!messages.find((x: any) => x.id === snapshot.val()?.id)) {
            setMessages((prev: any) => {
              const selectedMessages = [...prev, snapshot.val()]?.sort(
                (a: any, b: any) =>
                  new Date(b.createdAt).getTime() -
                  new Date(a.createdAt).getTime()
              );
              return selectedMessages;
            });
          }
        }
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentMessageGroup]);

  if (!currentMessageGroup) {
    return (
      <Paper
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          padding: "1rem",
          minHeight: "70vh",
          width: "70%",
        }}
      >
        <Typography variant="h6">
          Please select a message to view history.
        </Typography>
      </Paper>
    );
  }

  const MyMessage = ({ message }: any) => (
    <div
      style={{
        maxWidth: "50%",
      }}
    >
      <small>{message?.sentBy}</small>
      <Paper
        style={{
          backgroundColor: "#689F38",
          padding: "0.5rem",
          color: "#fff",
        }}
      >
        <Typography>{message?.message}</Typography>
      </Paper>

      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <small>{moment(message?.createdAt).format("MM/DD/YYYY hh:mm A")}</small>
        <small>{message?.deliveryStatus}</small>
      </div>
    </div>
  );

  const OthersMessage = ({ message }: any) => (
    <div
      style={{
        maxWidth: "50%",
        alignSelf: "flex-end",
      }}
    >
      <small>{message?.sentBy}</small>
      <Paper
        style={{
          backgroundColor: "#1769aa",
          padding: "0.5rem",
          color: "#fff",
        }}
      >
        <Typography>{message?.message}</Typography>
      </Paper>

      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <small>{moment(message?.createdAt).format("MM/DD/YYYY hh:mm A")}</small>
        <small>{message?.deliveryStatus}</small>
      </div>
    </div>
  );

  return (
    <Paper
      style={{
        display: "flex",
        flexDirection: "column",
        padding: "1rem",
        height: "100%",
        width: "70%",
      }}
    >
      <div
        style={{
          display: "flex",
          flex: 1,
          flexDirection: "column",
        }}
      >
        <div
          style={{
            marginBottom: "0.5rem",
          }}
        >
          <Typography variant="h6">{currentMessageGroup.subject}</Typography>
        </div>

        <div
          style={{
            display: "flex",
            flexDirection: "column-reverse",
            flex: 1,
            maxHeight: "52vh",
            overflowY: "auto",
            border: "1px solid lightgrey",
            borderRadius: "5px",
            marginBottom: "0.5rem",
            padding: "1rem",
          }}
        >
          {messages.map((message: any, index: number) =>
            message.sentBy === loggedInUserName ? (
              <MyMessage message={message} key={index} />
            ) : (
              <OthersMessage message={message} key={index} />
            )
          )}
        </div>
      </div>

      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
        }}
      >
        <div
          className="p-field"
          style={{
            width: "100%",
            marginTop: "0.3rem",
            marginBottom: 0,
            marginRight: "1rem",
          }}
        >
          <span className="p-float-label">
            <InputTextarea
              id="message"
              name="message"
              value={message}
              onChange={(e: any) => setmessage(e.target.value)}
              style={{ width: "100%", color: "black", opacity: 1 }}
              rows={1}
              autoResize
            />
          </span>
        </div>

        <Button
          icon="pi pi-send"
          iconPos="right"
          label="Send"
          className="p-button-success"
          onClick={() => {
            const newMessage = {
              groupId: currentMessageGroup.id,
              message,
            };

            sendNewMessage(newMessage);
            setmessage("");
          }}
        />
      </div>
    </Paper>
  );
}
