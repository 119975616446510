import * as dashboardTypes from "./types";

const types = {
  ...dashboardTypes,
};

export type DashboardTpe = {
  totalServiceRequests: number;
  serviceRequestsPendingQuote: number;
  workOrdersInProgress: number;
  workOrdersCompleted: number;
};

const initialState: DashboardTpe = {
  totalServiceRequests: 0,
  serviceRequestsPendingQuote: 0,
  workOrdersInProgress: 0,
  workOrdersCompleted: 0,
};

export default function dashboardReducer(
  state = initialState,
  action: { type: string; payload: any }
) {
  switch (action.type) {
    case types.SHOW_DASHBOARD_ERROR:
      return {...initialState};
    case types.SHOW_DASHBOARD_SUCCESS:
      return {
        ...state,
        ...action.payload
      };
    default:
      return state;
  }
}
