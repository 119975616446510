import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useFormik } from "formik";
import { InputText } from "primereact/inputtext";
import { Password } from "primereact/password";
import { Dialog } from "primereact/dialog";
import { Divider } from "primereact/divider";
import { classNames } from "primereact/utils";
import { Typography, IconButton } from "@material-ui/core";
import { Clear } from "@material-ui/icons";
import { AutoComplete } from "primereact/autocomplete";
import { Button } from "primereact/button";

import { stateCodes } from "../../../constants/stateCodes";
import { emailValidator } from "../../../formUtils/validations";
import {
  normalizePhoneNumber,
  normalizeOnlyNums,
} from "../../../formUtils/normalizations";
import { onboardAdmin, resetAdminPassword } from "../../../services/admin.service";
import { showAlert } from "../../../redux/alerts/actions";


export default function ResetPasswordModal({ open, handleClose, adminDetails }: any) {
    const [resettingPassword, setResettingPassword] = useState<boolean>(false);
    const dispatch = useDispatch();
    const formik = useFormik({
        initialValues: {
            newPassword: "",
            reEnterNewPassword: "",
        },
        validate: (data: {
            newPassword: string;
            reEnterNewPassword: string;
        }) => {
            let errors: any = {};

            if (!data.newPassword) {
                errors.newPassword = "This field is required.";
            }

            if (!data.reEnterNewPassword) {
                errors.reEnterNewPassword = "This field is required.";
            }

            if (
                data.reEnterNewPassword &&
                data.reEnterNewPassword !== data.newPassword
            ) {
                errors.newPassword = "Passwords do not match";
                errors.reEnterNewPassword = "Password do not match";
            }

            return errors;
        },
        onSubmit: async (data: {
            newPassword: string;
            reEnterNewPassword: string;
        }) => {
            const payload = {
               userGuid: adminDetails.userGuid,
               newPassword: data.newPassword
            }
            setResettingPassword(true);
            const response = await resetAdminPassword(payload)
            setResettingPassword(false);
            if (response.status === 200) {
                dispatch(
                    showAlert({
                    message: "Admin onboarded successfully",
                    type: "success",
                    })
                );
                formik.resetForm();
                handleClose({show: false, refreshPage: true});
            } else {
                dispatch(
                    showAlert({
                    message: "Oops! something went wrong. Please try again.",
                    type: "error",
                    })
                );
                handleClose({show: false, refreshPage: false});
            } 
        },
    });

        
    const isFormChangePasswordFieldValid = (
        name:  "newPassword" | "reEnterNewPassword"
    ) =>
        !!(
            formik.touched[name] &&
            formik.errors[name]
        );
    const getChangePasswordFormErrorMessage = (
        name:  "newPassword" | "reEnterNewPassword"
    ) => {
        return (
        isFormChangePasswordFieldValid(name) && (
            <small className="p-error">
            {formik.errors[name]}
            </small>
        )
        );
    };

    return (
        <Dialog
            visible={open}
            onHide={() => handleClose({show: false, refreshPage: false})}
            modal
            draggable={false}
            style={{
                width: "50%",
            }}
            contentStyle={{
                padding: 0,
                borderRadius: "5px",
            }}
            showHeader={false}
        >
        <div
            style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "space-between",
            padding: "1rem 2rem 0 2rem",
            }}
        >
            <Typography variant="h6">Reset Password</Typography>

            <IconButton
            onClick={() => handleClose({show:false, refreshPage: false})}
            style={{
                position: "absolute",
                right: "-1rem",
                top: "-1rem",
                backgroundColor: "#1769aa",
                color: "#fff",
            }}
            >
            <Clear color="inherit" />
            </IconButton>
        </div>
        <div
          style={{
            width: "100%",
            padding: "2rem 2rem 0 2rem",
            maxHeight: "60vh",
            overflowY: "auto",
          }}
        >
            <form
                style={{ width: "100%", marginTop: "2.25rem" }}
                onSubmit={formik.handleSubmit}
            >
                <div className="p-field" style={{ width: "100%" }}>
                    <span className="p-float-label">
                        <Password
                            id="newPassword"
                            name="newPassword"
                            value={formik.values.newPassword}
                            onChange={formik.handleChange}
                            toggleMask
                            className={classNames({
                                "p-invalid":
                                isFormChangePasswordFieldValid("newPassword"),
                            })}
                            style={{ width: "100%" }}
                            inputStyle={{ width: "100%" }}
                            header={<h6>Pick a password</h6>}
                            footer={
                                <>
                                <Divider />
                                <p className="p-mt-2">Suggestions</p>
                                <ul
                                    className="p-pl-2 p-ml-2 p-mt-0"
                                    style={{ lineHeight: "1.5" }}
                                >
                                    <li>At least one lowercase</li>
                                    <li>At least one uppercase</li>
                                    <li>At least one numeric</li>
                                    <li>Minimum 8 characters</li>
                                </ul>
                                </>
                            }
                        />
                        <label
                        htmlFor="newPassword"
                        className={classNames({
                            "p-error": isFormChangePasswordFieldValid("newPassword"),
                        })}
                        >
                        Enter New Password*
                        </label>
                    </span>
                    {getChangePasswordFormErrorMessage("newPassword")}
                </div>

                <div className="p-field" style={{ width: "100%" }}>
                    <span className="p-float-label">
                        <Password
                            id="reEnterNewPassword"
                            name="reEnterNewPassword"
                            value={formik.values.reEnterNewPassword}
                            onChange={formik.handleChange}
                            toggleMask
                            className={classNames({
                                "p-invalid":
                                isFormChangePasswordFieldValid("reEnterNewPassword"),
                            })}
                            feedback={false}
                            style={{ width: "100%" }}
                            inputStyle={{ width: "100%" }}
                        />
                        <label
                        htmlFor="reEnterNewPassword"
                        className={classNames({
                            "p-error":
                            isFormChangePasswordFieldValid("reEnterNewPassword"),
                        })}
                        >
                        Re-Enter New Password*
                        </label>
                    </span>
                    {getChangePasswordFormErrorMessage("reEnterNewPassword")}
                </div>

                    

                <div
                style={{
                    display: "flex",
                    flexDirection: "row",
                    padding: "2rem 3rem 2rem 2rem",
                    justifyContent: "flex-end",
                }}
                >
                <Button
                    label="Cancel"
                    icon="pi pi-times"
                    style={{
                    borderColor: "#d65c49",
                    backgroundColor: "#d65c49",
                    }}
                    onClick={() => {
                        formik.resetForm();
                        handleClose({show: false, refreshPage: false});
                    }}
                    disabled={resettingPassword}
                />

                <Button
                    type="submit"
                    label="Submit"
                    icon="pi pi-check"
                    loading={resettingPassword}
                    style={{
                    marginLeft: "2rem",
                    backgroundColor: "#1769aa",
                    }}
                />
                </div>
            </form>
        </div>
    </Dialog>
    );
}
