import * as messageType from "./types";

const types = {
  ...messageType,
};

export type MessageType = {
  list: Array<any>
};

const initialState: MessageType = {
 list: []
};

export default function messageReducer(
  state = initialState,
  action: { type: string; payload: any }
) {
  switch (action.type) {
    case types.GET_ALL_MESSAGES_SUCCESS:
      return {
        ...state,
        list: action?.payload
      }
    case types.GET_ALL_MESSAGES_ERROR:
      return initialState;
    default:
      return state;
  }
}
