import  { useEffect, useState } from "react";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import { IconButton, Typography } from "@material-ui/core";
import { useDispatch } from "react-redux";
import { useFormik } from "formik";
import { Clear } from "@material-ui/icons";
import { classNames } from "primereact/utils";
import { AutoComplete } from "primereact/autocomplete";
import { getDrivers } from "../../../services/driver.service";
import { getVehicles } from "../../../services/vehicle.service";
import { Card } from "primereact/card";
import { showAlert } from "../../../redux/alerts/actions";
import { assignDriverToWorkOrder, getOrderDataByID } from "../../../services/orders.service";

interface IProps {
  open: boolean;
  handleClose: (event: { show: boolean; refreshPage: boolean }) => void;
  selectedOrder: any
}

interface AssignDriverModel {
  licenseNumber: {label: string, value: string} | null;
  driverName: {label: string, value: string} | null;
}


const AssignDriver = (props: IProps) => {
  /**
   * Variable declaration
   */
 
  const [assigningDriver, setAssigningDriver] = useState<boolean>(false);
  const [vehicleList, setVehicleList] = useState<any>([]);
  const [driverList, setDriverList] = useState<any>([]);
  const [filteredDrivers, setFilteredDrivers] = useState<any>(null);
  const [filteredLicenses, setFilteredLicenses] = useState<any>(null);
  const dispatch = useDispatch();
  const formik: any = useFormik({
    initialValues: {
      licenseNumber: null,
      driverName: null,
    },
    validate: (data: AssignDriverModel) => {
      let errors: any = {};

      if (!data.licenseNumber) {
        errors.licenseNumber = "This Field is required.";
      }

      if (!data.driverName) {
        errors.driverName = "This Field is required.";
      }
 
      return errors;
    },
    onSubmit: async (data: AssignDriverModel) => {
      try {
        await assignDriver(data);
      } catch (error: any) {
        console.error(error);
      }
    },
  });
  type driverStringTypes =
    "driverName"
    | "licenseNumber";

  const isFormFieldValid = (name: driverStringTypes) =>
    !!(formik.touched[name] && formik.errors[name]);
  const getFormErrorMessage = (name: driverStringTypes) => {
    return (
      isFormFieldValid(name) && (
        <small className="p-error">{formik.errors[name]}</small>
      )
    );
  };

  useEffect(() => {
     (async function fetchDriversAndVehicles() {
        const driverResponse = await getDrivers('', 1, 25);
        const vehicleResponse = await getVehicles('', 1, 25);
        if (props.selectedOrder.status === 'CREW_ASSIGNED') {
          const orderDetailsResponse = await getOrderDataByID(props.selectedOrder.orderId);
          if (orderDetailsResponse.status === 200) {
            const orderDetails = orderDetailsResponse.data;
            if (orderDetails.vehicles.length > 0 && orderDetails.drivers.length > 0) {
              formik.setValues({
                licenseNumber: {label: orderDetails.vehicles[0].vehicleLicensePlates, value: orderDetails.vehicleIds[0]},
                driverName: {label: `${orderDetails.drivers[0].profile.firstName} ${orderDetails.drivers[0].profile.lastName}`,value: orderDetails.driverIds[0]},
              })
            }
          }
        }
        if (driverResponse.status === 200) {
            setDriverList(driverResponse.data.records.map((data:any) => ({
                label: `${data.profile.firstName} ${data.profile.lastName}`,
                value: data.id
            })));
        }
        if (vehicleResponse.status === 200){
            setVehicleList(vehicleResponse.data.records.map((data:any) => ({
                label: data.vehicleLicensePlates,
                value: data.id
            })));
        }
     })();
  }, [])

  /**
   * Assign a driver api call.
   */
  const assignDriver = async (driverDetails: AssignDriverModel) => {
    try {
      setAssigningDriver(true)
      const payload = {
        orderId: props.selectedOrder.orderId,
        driverIds: [driverDetails.driverName && driverDetails?.driverName.value],
        vehicleIds: [driverDetails.licenseNumber && driverDetails?.licenseNumber.value]
      };
      const assignDriverResponse = await assignDriverToWorkOrder(payload);
      setAssigningDriver(false);
      if (assignDriverResponse.status === 200) {
        dispatch(
          showAlert({ message: "Driver assigned successfully", type: "success" })
        );
        props.handleClose({ show: false, refreshPage: true });
      } else {
        dispatch(
          showAlert({
            message: "Oops! something went wrong. Please try again.",
            type: "error",
          })
        );
      }
    } catch (error: any) {
      console.error(error);
    }
  };

  /**
   * Search autocomplete of driver name
   */
  const searchDriverName = (event: any) => {
    setTimeout(() => {
        let filteredDriverNames;
        if (!event.query.trim().length) {
            filteredDriverNames = [...driverList];
        }
        else {
            filteredDriverNames = [...driverList].filter((driver: any) => {
                return driver.label.toLowerCase().startsWith(event.query.toLowerCase());
            });
        }

        setFilteredDrivers(filteredDriverNames);
    }, 250);
  }

  /**
   * Search autocomplete of license
   */
  const searchLicense = (event: any) => {
    setTimeout(() => {
        let filteredLicensePlates;
        if (!event.query.trim().length) {
            filteredLicensePlates = [...vehicleList];
        }
        else {
            filteredLicensePlates = [...vehicleList].filter((vehicle: any) => {
                return vehicle.label.toLowerCase().startsWith(event.query.toLowerCase());
            });
        }

        setFilteredLicenses(filteredLicensePlates);
    }, 250);
  }

  return (
    <Dialog
      visible={props.open}
      onHide={() => {
        formik.resetForm();
        props.handleClose({ show: false, refreshPage: false })
      }}
      modal
      draggable={false}
      style={{
        width: "50%",
      }}
      contentStyle={{
        padding: 0,
        borderRadius: "5px",
      }}
      showHeader={false}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "space-between",
          padding: "1rem 2rem 0 2rem",
        }}
      >
        <Typography variant="h6">Assign crew</Typography>

        <IconButton
          onClick={() => {
            formik.resetForm();
            props.handleClose({ show: false, refreshPage: false });
          }}
          style={{
            position: "absolute",
            right: "-1rem",
            top: "-1rem",
            backgroundColor: "#1769aa",
            color: "#fff",
          }}
        >
          <Clear color="inherit" />
        </IconButton>
      </div>
      <form
        style={{
          width: "100%",
        }}
        onSubmit={formik.handleSubmit}
      >
        <div
          style={{
            width: "100%",
            padding: "2rem 2rem 0 2rem",
            maxHeight: "60vh",
            overflowY: "auto",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
            }}
          >
            <div
              className="p-field"
              style={{
                width: "100%",
                marginBottom: "2rem",
                marginRight: '10px'
              }}
            >
                <span
                  className="p-float-label"
                  style={{
                      width: "100%",
                  }}
                >
                  <AutoComplete
                    id="driverName"
                    name="driverName"
                    field="label"
                    value={formik.values.driverName}
                    suggestions={filteredDrivers}
                    completeMethod={searchDriverName}
                    onChange={formik.handleChange}
                    className={classNames({
                        "p-invalid": isFormFieldValid("driverName"),
                    })}
                    dropdown
                    forceSelection
                    style={{ width: "100%" }}
                  />
                  <label
                    htmlFor="state"
                    className={classNames({
                        "p-error": isFormFieldValid("driverName"),
                    })}
                  >
                    Select Driver*
                  </label>
                </span>
                {getFormErrorMessage("driverName")}
            </div>

            <div
              className="p-field"
              style={{
                width: "100%",
                marginLeft: '10px'
              }}
            >
              <span
                className="p-float-label"
                style={{
                width: "100%",
                }}
              >
                <AutoComplete
                  id="licenseNumber"
                  name="licenseNumber"
                  field="label"
                  value={formik.values.licenseNumber}
                  suggestions={filteredLicenses}
                  completeMethod={searchLicense}
                  onChange={formik.handleChange}
                  className={classNames({
                      "p-invalid": isFormFieldValid("licenseNumber"),
                  })}
                  dropdown
                  forceSelection
                  style={{ width: "100%" }}
                />
                <label
                htmlFor="state"
                className={classNames({
                    "p-error": isFormFieldValid("licenseNumber"),
                })}
                >
                Select Vehicle*
                </label>
              </span>
              {getFormErrorMessage("licenseNumber")}
            </div> 
          </div>

          <Card style={{marginBottom:'1rem'}}>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                width: "100%"
              }}
            >
              <div
                style={{
                display: "flex",
                flexDirection: "column",
                width: "100%",
                marginRight: "0.5rem",
                }}
              >
                <Typography
                  variant="h6"
                  style={{
                    borderBottom: "1px solid lightgrey",
                    marginBottom: "0.5rem",
                  }}
                >
                  Order Details
                </Typography>

                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    width: "100%",
                    marginTop: "1rem",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      width: "100%",
                    }}
                  >
                    <Typography style={{ fontSize: "0.85rem" }}>
                      <b>Order ID:</b>{" "}
                      {props.selectedOrder && props.selectedOrder.orderId || 'N/A'}
                    </Typography>
                    <Typography style={{ fontSize: "0.85rem" }}>
                      <b>Service Cost ($):</b>{" "}
                      {props.selectedOrder && props.selectedOrder.totalServiceCost || 'N/A'}
                    </Typography>
                    <Typography style={{ fontSize: "0.85rem" }}>
                        <b>Requested By:</b>{" "}
                        {props.selectedOrder && props.selectedOrder.createdBy || 'N/A'}
                    </Typography>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      width: "100%",
                      marginBottom: "1rem",
                    }}
                  > 
                    <Typography style={{ fontSize: "0.85rem" }}>
                      <b>Total Weight (in lbs):</b>{" "}
                      {props.selectedOrder && props.selectedOrder.totalWeight || 'N/A'}
                    </Typography>
                    <Typography style={{ fontSize: "0.85rem" }}>
                      <b>Pickup Address:</b>{" "}
                      {props.selectedOrder && props.selectedOrder.pickupLocation || 'N/A'}
                    </Typography>
                    <Typography style={{ fontSize: "0.85rem" }}>
                      <b>Destination Address:</b>{" "}
                      {props.selectedOrder && props.selectedOrder.destinationLocation || 'N/A'}
                    </Typography>
                  </div>
                </div>
              </div>
            </div>
          </Card>
        </div>

        <div 
          style={{
            display: "flex",
            flexDirection: "row",
            padding: "2rem 3rem 2rem 2rem",
            justifyContent: "flex-end",
          }}
        >
          <Button
            label="Cancel"
            icon="pi pi-times"
            style={{
              borderColor: "#d65c49",
              backgroundColor: "#d65c49",
            }}
            onClick={() => {
              formik.resetForm();
              props.handleClose({ show: false, refreshPage: false })
            }}
            disabled={assigningDriver}
          />

          <Button
            type="submit"
            label="Submit"
            icon="pi pi-check"
            loading={assigningDriver}
            style={{
                marginLeft: "2rem",
                backgroundColor: "#1769aa",
            }}
          />
        </div>
      </form>
    </Dialog>
  );
};

export default AssignDriver;
