import { useEffect, useState } from "react";
import {
  Grid,
  Paper,
  // InputBase,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Tooltip,
} from "@material-ui/core";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
// import { Search as SearchIcon } from "@material-ui/icons";

// import Map from "../../assets/images/Map.png";
import GoogleMapComponent from "./GoogleMapComponent";
import { getVehicles } from "../../services/vehicle.service";
import { getOrderData } from "../../services/orders.service";
import { Warning } from "@material-ui/icons";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
    },
    paper: {
      backgroundColor: theme.palette.background.paper,
      padding: theme.spacing(2),
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
    },
    map: {},
    select: {
      width: "300px",
      marginRight: "40px",
      marginBottom: "10px",
    },
    search: {
      position: "relative",
      border: `1px solid ${theme.palette.grey[400]}`,
      borderRadius: theme.shape.borderRadius,
      backgroundColor: theme.palette.background.paper,
      width: "300px",
    },
    searchIcon: {
      padding: theme.spacing(0, 2),
      height: "100%",
      position: "absolute",
      pointerEvents: "none",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      color: theme.palette.text.primary,
    },
    inputInput: {
      padding: theme.spacing(1, 1, 1, 0),
      // vertical padding + font size from searchIcon
      paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
      transition: theme.transitions.create("width"),
      width: "100%",
      [theme.breakpoints.up("md")]: {
        width: "20ch",
      },
    },
    row: {
      display: "flex",
      flexDirection: "row",
      width: "100%",
      justifyContent: "center",
      alignItems: "center",
      marginBottom: "20px",
    },
  })
);

export default function Tracker({ history, match }: any) {
  const initialValues = history?.location?.state;

  const [asset, setAsset] = useState(initialValues?.asset);
  // const [searchValue, setSearchValue] = useState(initialValues?.searchValue);
  const [vehicles, setVehicles] = useState<any>([]);
  const [workOrders, setWorkOrders] = useState<any>([]);
  const [valueVehicle, setValueVehicle] = useState<any>("");
  const [valueWO, setValueWO] = useState<any>(
    history?.location?.state?.order_id || ""
  );
  const [selectedVehicle, setSelectedVehicle] = useState<string>("");
  const [selectedWO, setSelectedWO] = useState<string>("");
  const [warning, setWarning] = useState<boolean>(false);
  const classes = useStyles();

  useEffect(() => {
    (async function getVehiclesData() {
      const vehicleResponse = await getVehicles("", 1, 20);
      if (vehicleResponse.status === 200) {
        setVehicles(
          vehicleResponse.data.records.map((data: any, index: number) => ({
            label: data.vehicleLicensePlates,
            value: data.vehicleLicensePlates,
            lat: index === 1 ? 37.5248505 : 36.0020232,
            lng: index === 1 ? -77.6333488 : -79.0249986,
          }))
        );
      }
    })();
    let workOrdersResponse;
    (async function fetchOrders() {
      workOrdersResponse = await getOrderData("", 1, 20, "");
      if (workOrdersResponse.status === 200) {
        workOrdersResponse = workOrdersResponse.data.records.map(
          (data: any, index: number) => ({
            label: data.orderId,
            value: data.orderId,
            orderID: data.id,
            pickUp: data.pickupLocation,
            destination: data.destinationLocation,
          })
        );
        setWorkOrders(workOrdersResponse);

        const orderIDFromHistory = history?.location?.state?.order_id;
        if (orderIDFromHistory) {
          setValueWO(orderIDFromHistory);
          setSelectedWO(
            workOrdersResponse.find(
              (data: any) => data.value === orderIDFromHistory
            )
          );
        }
      }
    })();
    console.log(history);
  }, []);

  return (
    <Grid container className={classes.root} spacing={4}>
      <Grid item sm={12}>
        <Paper className={classes.paper}>
          <div className={classes.row}>
            <FormControl className={classes.select}>
              <InputLabel id="simple-select-label">Choose Asset</InputLabel>
              <Select
                labelId="simple-select-label"
                id="simple-select"
                value={asset}
                onChange={(e: any) => {
                  setAsset(e.target.value);
                  setValueVehicle("");
                  setSelectedVehicle("");
                  setValueWO("");
                  setSelectedWO("");
                  setWarning(false);
                }}
              >
                <MenuItem value="Vehicle">Vehicle (License Number)</MenuItem>
                {/* <MenuItem value="Driver">Driver</MenuItem> */}
                <MenuItem value="Order Number">Order Number</MenuItem>
                {/* <MenuItem value="Pickup Location History">
                  Pickup Location History
                </MenuItem>
                <MenuItem value="Inventory Location">
                  Inventory Location
                </MenuItem> */}
              </Select>
            </FormControl>
            {asset === "Order Number" && (
              <>
                <FormControl
                  className={classes.select}
                  style={{ marginRight: "10px" }}
                >
                  <InputLabel id="simple-select-label">Order ID</InputLabel>
                  <Select
                    labelId="simple-select-label"
                    id="simple-select"
                    value={valueWO}
                    onChange={(e: any) => {
                      setValueWO(e.target.value);
                      setSelectedWO(
                        workOrders.find(
                          (data: any) => data.value === e.target.value
                        )
                      );
                    }}
                  >
                    {/* <MenuItem value="Truck">Truck</MenuItem> */}
                    {/* <MenuItem value="Driver">Driver</MenuItem> */}
                    {workOrders.map((workOrder: any, index: number) => (
                      <MenuItem value={workOrder.value} key={index}>
                        {workOrder.label}
                      </MenuItem>
                    ))}
                    {/* <MenuItem value="Pickup Location History">
                    Pickup Location History
                  </MenuItem>
                  <MenuItem value="Inventory Location">
                    Inventory Location
                  </MenuItem> */}
                  </Select>
                </FormControl>
                {warning && (
                  <span>
                    <Tooltip title="Location is unavailable">
                      <Warning style={{ fontSize: "30px", fill: "#ffcc00" }} />
                    </Tooltip>
                  </span>
                )}
              </>
            )}
            {asset === "Vehicle" && (
              <>
                <FormControl className={classes.select}>
                  <InputLabel id="simple-select-label">
                    Vehicle (License Number)
                  </InputLabel>
                  <Select
                    labelId="simple-select-label"
                    id="simple-select"
                    value={valueVehicle}
                    onChange={(e: any) => {
                      setValueVehicle(e.target.value);
                      setSelectedVehicle(
                        vehicles.find(
                          (data: any) => data.value === e.target.value
                        )
                      );
                    }}
                  >
                    {vehicles.map((vehicle: any) => (
                      <MenuItem value={vehicle.value}>{vehicle.label}</MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </>
            )}

            {/* <div className={classes.search}>
              <div className={classes.searchIcon}>
                <SearchIcon />
              </div>
              <InputBase
                placeholder="Search ..."
                classes={{
                  input: classes.inputInput,
                }}
                inputProps={{ "aria-label": "search" }}
                value={searchValue}
                onChange={(e: any) => {
                  setSearchValue(e.target.value);
                }}
              />
            </div> */}
          </div>

          <GoogleMapComponent
            selectedVehicle={selectedVehicle}
            selectedWO={selectedWO}
            setWarning={setWarning}
          />
        </Paper>
      </Grid>
    </Grid>
  );
}
