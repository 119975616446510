import Axios from "../config/interceptors/axiosInterceptor";

/**
 * This function is used to get all the admins
 * @returns
 */
export const getAllAdmins = async (
  searchText: string,
  pageNumber: number,
  pageSize: number
) => {
  try {
    return await Axios.get(
      `${
        process.env.REACT_APP_API_BASE_URL
      }/admin-user/get-all?page_number=${pageNumber}&page_size=${pageSize}&search_term=${
        searchText || ""
      }`
    );
  } catch (error: any) {
    return { status: 500, data: [], statusText: "Oops! something went wrong" };
  }
};

/**
 * This function is used to get all the admin profile
 * @returns
 */
export const getAdminProfile = async (profileID: string) => {
  try {
    return await Axios.get(
      `${process.env.REACT_APP_API_BASE_URL}/profile/get?id=${profileID}`
    );
  } catch (error: any) {
    return { status: 500, data: [], statusText: "Oops! something went wrong" };
  }
};

/**
 * This function is used to onboard a new admin
 * @returns
 */
export const onboardAdmin = async (payload: any) => {
  try {
    return await Axios.post(
      `${process.env.REACT_APP_API_BASE_URL}/admin-user/create`,
      payload
    );
  } catch (error: any) {
    return {
      status: 500,
      data: null,
      statusText: "Oops! something went wrong",
    };
  }
};
/**
 * This function is used to reset a admin password
 * @returns
 */
export const resetAdminPassword = async (payload: any) => {
  try {
    return await Axios.post(
      `${process.env.REACT_APP_API_BASE_URL}/customer/update-password`,
      payload
    );
  } catch (error: any) {
    return {
      status: 500,
      data: null,
      statusText: "Oops! something went wrong",
    };
  }
};
