import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Paper, Typography, IconButton } from "@material-ui/core";
import { AccountCircleRounded } from "@material-ui/icons";
import { useFormik } from "formik";
import { InputText } from "primereact/inputtext";
import { classNames } from "primereact/utils";
import { Button } from "primereact/button";
import { Password } from "primereact/password";
import { Divider } from "primereact/divider";
import EditIcon from "@material-ui/icons/Edit";
import { AutoComplete } from "primereact/autocomplete";

import { fetchProfileAction } from "../../redux/profile/actions";
import { showAlert } from "../../redux/alerts/actions";
import { updateUserProfile } from "../../services/dashboard.service";
import { stateCodes } from "../../constants/stateCodes";
import {
  normalizePhoneNumber,
  normalizeOnlyNums,
} from "../../formUtils/normalizations";
import { changePasswordAction } from "../../redux/user/actions";

type ProfileType = {
  firstName: string;
  lastName: string;
  companyName: string;
  primaryPhoneNumber?: string;
  secondaryPhoneNumber?: string;
  addressLine1?: string;
  addressLine2?: string;
  city?: string;
  state?: { label: string; value: string };
  zipCode?: string;
};

export default function Profile() {
  const [isProfileDisabled, setIsProfileDisabled] = useState(true);
  const [filteredStates, setFilteredStates] = useState<any>(null);
  const dispatch = useDispatch();

  const profile: any = useSelector((state: any) => state?.profile);

  const formik = useFormik({
    initialValues: {
      firstName: profile?.firstName,
      lastName: profile?.lastName,
      companyName: profile?.companyName,
      addressLine1: profile?.addressLine1,
      addressLine2: profile?.addressLine2,
      city: profile?.city,
      zipCode: profile?.zipCode,
      primaryPhoneNumber: normalizePhoneNumber(
        profile?.primaryPhoneNumber?.replaceAll("-", "") || ""
      ),
      secondaryPhoneNumber: normalizePhoneNumber(
        profile?.secondaryPhoneNumber?.replaceAll("-", "") || ""
      ),
      state: profile?.state
        ? stateCodes?.find((x) => x.value === profile?.state)
        : { label: "", value: "" },
    },
    validate: (data: ProfileType) => {
      let errors: any = {};

      if (!data.firstName) {
        errors.firstName = "This field is required.";
      }

      if (!data.lastName) {
        errors.lastName = "This field is required.";
      }

      if (!data.companyName) {
        errors.companyName = "This field is required.";
      }

      return errors;
    },
    onSubmit: (data: ProfileType) => {
      (async () => {
        const updateResponse = await updateUserProfile({
          ...data,
          primaryPhoneNumber: data?.primaryPhoneNumber
            ? "+1" + data?.primaryPhoneNumber?.replace("-", "")
            : "",
          secondaryPhoneNumber: data?.secondaryPhoneNumber
            ? "+1" + data?.secondaryPhoneNumber?.replace("-", "")
            : "",
          state: data?.state?.value,
        });
        if (updateResponse.status === 200) {
          await dispatch(fetchProfileAction());
          dispatch(
            showAlert({
              type: "success",
              message: "Profile Updated Successfully",
            })
          );
        } else {
          dispatch(
            showAlert({ type: "error", message: "Oops! something went" })
          );
        }
      })();

      setIsProfileDisabled(true);
    },
  });

  const formikForChangePassword = useFormik({
    initialValues: {
      currentPassword: "",
      newPassword: "",
      reEnterNewPassword: "",
    },
    validate: (data: {
      currentPassword: string;
      newPassword: string;
      reEnterNewPassword: string;
    }) => {
      let errors: any = {};

      if (!data.currentPassword) {
        errors.currentPassword = "This field is required.";
      }

      if (!data.newPassword) {
        errors.newPassword = "This field is required.";
      }

      if (!data.reEnterNewPassword) {
        errors.reEnterNewPassword = "This field is required.";
      }

      if (data.newPassword && data.currentPassword === data.newPassword) {
        errors.newPassword = "New Password cannot be same as current password.";
      }

      if (
        data.reEnterNewPassword &&
        data.reEnterNewPassword !== data.newPassword
      ) {
        errors.newPassword = "Passwords do not match";
        errors.reEnterNewPassword = "Password do not match";
      }

      return errors;
    },
    onSubmit: async (data: {
      currentPassword: string;
      newPassword: string;
      reEnterNewPassword: string;
    }) => {
      await dispatch(changePasswordAction(data));
      formikForChangePassword.resetForm();
    },
  });

  type profileKeyType =
    | "firstName"
    | "lastName"
    | "companyName"
    | "primaryPhoneNumber"
    | "secondaryPhoneNumber"
    | "addressLine1"
    | "addressLine2"
    | "city"
    | "state"
    | "zipCode";

  const isFormFieldValid = (name: profileKeyType) =>
    !!(formik.touched[name] && formik.errors[name]);
  const getFormErrorMessage = (name: profileKeyType) => {
    return (
      isFormFieldValid(name) && (
        <small className="p-error">{formik.errors[name]}</small>
      )
    );
  };

  const isFormChangePasswordFieldValid = (
    name: "currentPassword" | "newPassword" | "reEnterNewPassword"
  ) =>
    !!(
      formikForChangePassword.touched[name] &&
      formikForChangePassword.errors[name]
    );
  const getChangePasswordFormErrorMessage = (
    name: "currentPassword" | "newPassword" | "reEnterNewPassword"
  ) => {
    return (
      isFormChangePasswordFieldValid(name) && (
        <small className="p-error">
          {formikForChangePassword.errors[name]}
        </small>
      )
    );
  };

  const searchState = (event: any) => {
    setTimeout(() => {
      let _filteredCountries;
      if (!event.query.trim().length) {
        _filteredCountries = [...stateCodes];
      } else {
        _filteredCountries = stateCodes.filter((state: any) => {
          return state.label
            .toLowerCase()
            .startsWith(event.query.toLowerCase());
        });
      }

      setFilteredStates(_filteredCountries);
    }, 250);
  };

  return (
    <div
      style={{
        position: "relative",
        display: "flex",
        flexDirection: "column",
        width: "100%",
      }}
    >
      <Paper
        style={{
          position: "absolute",
          width: "max-content",
          backgroundColor: "#1769aa",
          padding: "0.25rem 0.5rem",
          paddingBottom: 0,
          top: "1rem",
          left: "calc(50% - 4.5rem)",
          right: "calc(50% - 4.5rem)",
          borderRadius: "50%",
        }}
      >
        <AccountCircleRounded style={{ color: "#fff", fontSize: "8rem" }} />
      </Paper>

      <Paper
        style={{
          height: "100px",
          width: "100%",
          backgroundColor: "#1769aa",
          borderBottomLeftRadius: 0,
          borderBottomRightRadius: 0,
        }}
      ></Paper>

      <Paper
        style={{
          display: "flex",
          flexDirection: "row",
          width: "100%",
          padding: "5rem 1rem 1rem 1rem",
          borderTopLeftRadius: 0,
          borderTopRightRadius: 0,
          marginBottom: "4rem",
        }}
      >
        <Paper
          style={{
            display: "flex",
            flexDirection: "column",
            width: "100%",
            backgroundColor: "#f8f9fa",
            marginRight: "0.5rem",
            padding: "1rem",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              width: "100%",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <Typography variant="h6">Personal Information</Typography>

            <IconButton
              color="primary"
              onClick={() => setIsProfileDisabled(false)}
            >
              <EditIcon style={{ marginRight: "0.5rem" }} />
              <Typography>Edit</Typography>
            </IconButton>
          </div>

          <form
            style={{ width: "100%", marginTop: "2rem" }}
            onSubmit={formik.handleSubmit}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "row",
              }}
            >
              <div
                className="p-field"
                style={{
                  width: "100%",
                  marginRight: "0.5rem",
                }}
              >
                <span className="p-float-label">
                  <InputText
                    id="firstName"
                    name="firstName"
                    value={formik.values.firstName}
                    onChange={formik.handleChange}
                    className={classNames({
                      "p-invalid": isFormFieldValid("firstName"),
                    })}
                    style={{ width: "100%" }}
                    disabled={isProfileDisabled}
                  />
                  <label
                    htmlFor="firstName"
                    className={classNames({
                      "p-error": isFormFieldValid("firstName"),
                    })}
                  >
                    First Name
                  </label>
                </span>
                {getFormErrorMessage("firstName")}
              </div>

              <div
                className="p-field"
                style={{ width: "100%", marginLeft: "0.5rem" }}
              >
                <span className="p-float-label">
                  <InputText
                    id="lastName"
                    name="lastName"
                    value={formik.values.lastName}
                    onChange={formik.handleChange}
                    className={classNames({
                      "p-invalid": isFormFieldValid("lastName"),
                    })}
                    style={{ width: "100%" }}
                    disabled={isProfileDisabled}
                  />
                  <label
                    htmlFor="lastName"
                    className={classNames({
                      "p-error": isFormFieldValid("lastName"),
                    })}
                  >
                    Last Name
                  </label>
                </span>
                {getFormErrorMessage("lastName")}
              </div>
            </div>

            <div className="p-field" style={{ width: "100%" }}>
              <span className="p-float-label">
                <InputText
                  id="companyName"
                  name="companyName"
                  value={formik.values.companyName}
                  onChange={formik.handleChange}
                  className={classNames({
                    "p-invalid": isFormFieldValid("companyName"),
                  })}
                  style={{ width: "100%" }}
                  disabled={isProfileDisabled}
                />
                <label
                  htmlFor="companyName"
                  className={classNames({
                    "p-error": isFormFieldValid("companyName"),
                  })}
                >
                  Company Name
                </label>
              </span>
              {getFormErrorMessage("companyName")}
            </div>

            <div
              style={{
                display: "flex",
                flexDirection: "row",
              }}
            >
              <div
                className="p-field"
                style={{ width: "100%", marginRight: "0.5rem" }}
              >
                <span
                  className="p-float-label p-input-icon-left"
                  style={{ width: "100%" }}
                >
                  <i className="pi">+1</i>
                  <InputText
                    id="primaryPhoneNumber"
                    name="primaryPhoneNumber"
                    value={formik.values.primaryPhoneNumber}
                    onChange={(e) => {
                      e.target.value = normalizePhoneNumber(e.target.value);
                      formik.handleChange(e);
                    }}
                    className={classNames({
                      "p-invalid": isFormFieldValid("primaryPhoneNumber"),
                    })}
                    style={{ width: "100%" }}
                    disabled={isProfileDisabled}
                  />
                  <label
                    htmlFor="primaryPhoneNumber"
                    className={classNames({
                      "p-error": isFormFieldValid("primaryPhoneNumber"),
                    })}
                    style={{
                      marginLeft: formik.values.primaryPhoneNumber
                        ? "-1.5rem"
                        : 0,
                    }}
                  >
                    Primary Phone Number
                  </label>
                </span>
                {getFormErrorMessage("primaryPhoneNumber")}
              </div>

              <div
                className="p-field"
                style={{ width: "100%", marginLeft: "0.5rem" }}
              >
                <span
                  className="p-input-icon-left p-float-label"
                  style={{ width: "100%" }}
                >
                  <i className="pi">+1</i>
                  <InputText
                    id="secondaryPhoneNumber"
                    name="secondaryPhoneNumber"
                    value={formik.values.secondaryPhoneNumber}
                    onChange={(e) => {
                      e.target.value = normalizePhoneNumber(e.target.value);
                      formik.handleChange(e);
                    }}
                    className={classNames({
                      "p-invalid": isFormFieldValid("secondaryPhoneNumber"),
                    })}
                    style={{ width: "100%" }}
                    disabled={isProfileDisabled}
                  />
                  <label
                    htmlFor="secondaryPhoneNumber"
                    className={classNames({
                      "p-error": isFormFieldValid("secondaryPhoneNumber"),
                    })}
                    style={{
                      marginLeft: formik.values.secondaryPhoneNumber
                        ? "-1.5rem"
                        : 0,
                    }}
                  >
                    Alternate Phone Number
                  </label>
                </span>
                {getFormErrorMessage("secondaryPhoneNumber")}
              </div>
            </div>

            <div className="p-field" style={{ width: "100%" }}>
              <span className="p-float-label">
                <InputText
                  id="addressLine1"
                  name="addressLine1"
                  value={formik.values.addressLine1}
                  onChange={formik.handleChange}
                  className={classNames({
                    "p-invalid": isFormFieldValid("addressLine1"),
                  })}
                  style={{ width: "100%" }}
                  disabled={isProfileDisabled}
                />
                <label
                  htmlFor="addressLine1"
                  className={classNames({
                    "p-error": isFormFieldValid("addressLine1"),
                  })}
                >
                  Address Line 1
                </label>
              </span>
              {getFormErrorMessage("addressLine1")}
            </div>

            <div className="p-field" style={{ width: "100%" }}>
              <span className="p-float-label">
                <InputText
                  id="addressLine2"
                  name="addressLine2"
                  value={formik.values.addressLine2}
                  onChange={formik.handleChange}
                  className={classNames({
                    "p-invalid": isFormFieldValid("addressLine2"),
                  })}
                  style={{ width: "100%" }}
                  disabled={isProfileDisabled}
                />
                <label
                  htmlFor="addressLine2"
                  className={classNames({
                    "p-error": isFormFieldValid("addressLine2"),
                  })}
                >
                  Address Line 2
                </label>
              </span>
              {getFormErrorMessage("addressLine2")}
            </div>

            <div className="p-field" style={{ width: "100%" }}>
              <span className="p-float-label">
                <InputText
                  id="city"
                  name="city"
                  value={formik.values.city}
                  onChange={formik.handleChange}
                  className={classNames({
                    "p-invalid": isFormFieldValid("city"),
                  })}
                  style={{ width: "100%" }}
                  disabled={isProfileDisabled}
                />
                <label
                  htmlFor="city"
                  className={classNames({
                    "p-error": isFormFieldValid("city"),
                  })}
                >
                  City
                </label>
              </span>
              {getFormErrorMessage("city")}
            </div>

            <div
              style={{
                display: "flex",
                flexDirection: "row",
              }}
            >
              <div
                className="p-field"
                style={{ width: "100%", marginRight: "0.5rem" }}
              >
                <span className="p-float-label">
                  <AutoComplete
                    id="state"
                    name="state"
                    field="label"
                    value={formik.values.state}
                    suggestions={filteredStates}
                    completeMethod={searchState}
                    onChange={formik.handleChange}
                    className={classNames({
                      "p-invalid": isFormFieldValid("state"),
                    })}
                    dropdown
                    forceSelection
                    style={{ width: "100%" }}
                    disabled={isProfileDisabled}
                  />
                  <label
                    htmlFor="state"
                    className={classNames({
                      "p-error": isFormFieldValid("state"),
                    })}
                  >
                    State
                  </label>
                </span>
                {getFormErrorMessage("state")}
              </div>

              <div
                className="p-field"
                style={{ width: "100%", marginLeft: "0.5rem" }}
              >
                <span className="p-float-label">
                  <InputText
                    id="zipCode"
                    name="zipCode"
                    value={formik.values.zipCode}
                    onChange={(e) => {
                      e.target.value = normalizeOnlyNums(e.target.value);
                      formik.handleChange(e);
                    }}
                    className={classNames({
                      "p-invalid": isFormFieldValid("zipCode"),
                    })}
                    style={{ width: "100%" }}
                    disabled={isProfileDisabled}
                  />
                  <label
                    htmlFor="zipCode"
                    className={classNames({
                      "p-error": isFormFieldValid("zipCode"),
                    })}
                  >
                    Zip Code
                  </label>
                </span>
                {getFormErrorMessage("zipCode")}
              </div>
            </div>

            {!isProfileDisabled && (
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                }}
              >
                <Button
                  type="submit"
                  label="Save Changes"
                  icon="pi pi-check"
                  style={{
                    backgroundColor: "#1769aa",
                  }}
                />

                <Button
                  label="Cancel"
                  icon="pi pi-times"
                  style={{
                    marginLeft: "2rem",
                    borderColor: "#d65c49",
                    backgroundColor: "#d65c49",
                  }}
                  onClick={() => {
                    formik.resetForm();
                    setIsProfileDisabled(true);
                  }}
                />
              </div>
            )}
          </form>
        </Paper>

        <Paper
          style={{
            display: "flex",
            flexDirection: "column",
            width: "100%",
            backgroundColor: "#f8f9fa",
            marginLeft: "0.5rem",
            padding: "1rem",
          }}
        >
          <Typography variant="h6">Login Details</Typography>

          <div style={{ width: "100%", marginTop: "3rem" }}>
            <div className="p-field" style={{ width: "100%" }}>
              <span className="p-float-label">
                <InputText
                  id="emailAddress"
                  name="emailAddress"
                  value={profile?.emailAddress}
                  onChange={formik.handleChange}
                  style={{ width: "100%" }}
                  disabled
                />
                <label htmlFor="emailAddress">Login Id / Email Address</label>
              </span>
            </div>

            <Typography variant="h6">Change Password</Typography>

            <form
              style={{ width: "100%", marginTop: "2.25rem" }}
              onSubmit={formikForChangePassword.handleSubmit}
            >
              <div className="p-field" style={{ width: "100%" }}>
                <span className="p-float-label">
                  <Password
                    id="currentPassword"
                    name="currentPassword"
                    value={formikForChangePassword.values.currentPassword}
                    onChange={formikForChangePassword.handleChange}
                    toggleMask
                    className={classNames({
                      "p-invalid":
                        isFormChangePasswordFieldValid("currentPassword"),
                    })}
                    feedback={false}
                    style={{ width: "100%" }}
                    inputStyle={{ width: "100%" }}
                  />
                  <label
                    htmlFor="currentPassword"
                    className={classNames({
                      "p-error":
                        isFormChangePasswordFieldValid("currentPassword"),
                    })}
                  >
                    Enter Current Password*
                  </label>
                </span>
                {getChangePasswordFormErrorMessage("currentPassword")}
              </div>

              <div className="p-field" style={{ width: "100%" }}>
                <span className="p-float-label">
                  <Password
                    id="newPassword"
                    name="newPassword"
                    value={formikForChangePassword.values.newPassword}
                    onChange={formikForChangePassword.handleChange}
                    toggleMask
                    className={classNames({
                      "p-invalid":
                        isFormChangePasswordFieldValid("newPassword"),
                    })}
                    style={{ width: "100%" }}
                    inputStyle={{ width: "100%" }}
                    header={<h6>Pick a password</h6>}
                    footer={
                      <>
                        <Divider />
                        <p className="p-mt-2">Suggestions</p>
                        <ul
                          className="p-pl-2 p-ml-2 p-mt-0"
                          style={{ lineHeight: "1.5" }}
                        >
                          <li>At least one lowercase</li>
                          <li>At least one uppercase</li>
                          <li>At least one numeric</li>
                          <li>Minimum 8 characters</li>
                        </ul>
                      </>
                    }
                  />
                  <label
                    htmlFor="newPassword"
                    className={classNames({
                      "p-error": isFormChangePasswordFieldValid("newPassword"),
                    })}
                  >
                    Enter New Password*
                  </label>
                </span>
                {getChangePasswordFormErrorMessage("newPassword")}
              </div>

              <div className="p-field" style={{ width: "100%" }}>
                <span className="p-float-label">
                  <Password
                    id="reEnterNewPassword"
                    name="reEnterNewPassword"
                    value={formikForChangePassword.values.reEnterNewPassword}
                    onChange={formikForChangePassword.handleChange}
                    toggleMask
                    className={classNames({
                      "p-invalid":
                        isFormChangePasswordFieldValid("reEnterNewPassword"),
                    })}
                    feedback={false}
                    style={{ width: "100%" }}
                    inputStyle={{ width: "100%" }}
                  />
                  <label
                    htmlFor="reEnterNewPassword"
                    className={classNames({
                      "p-error":
                        isFormChangePasswordFieldValid("reEnterNewPassword"),
                    })}
                  >
                    Re-Enter New Password*
                  </label>
                </span>
                {getChangePasswordFormErrorMessage("reEnterNewPassword")}
              </div>

              <Button
                type="submit"
                label="Change Password"
                icon="pi pi-check"
                style={{
                  backgroundColor: "#1769aa",
                }}
              />
            </form>
          </div>
        </Paper>
      </Paper>
    </div>
  );
}
