import { useEffect, useRef, useState } from "react";
import {
  Grid,
  Paper,
  Typography,
  Drawer,
  IconButton,
  InputAdornment,
  TextField,
  Tooltip,
} from "@material-ui/core";
import {
  makeStyles,
  createStyles,
  Theme,
  alpha,
} from "@material-ui/core/styles";
import { useDispatch, useSelector } from "react-redux";
import { GetApp, Add, Search, Cached, LockOpen } from "@material-ui/icons";

import OnboardCustomerModal from "./OnboardCustomer/OnboardCustomer";
import { fetchAllCustomers } from "../../redux/customers/actions";
import moment from "moment";
import DeleteConfirmation from "./DeleteConfirmation";
import ViewCustomerProfile from "./ViewCustomerProfile/ViewCustomerProfile";
// import LoadingTable from "../../components/Table/LoadingTable";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import { Menu } from "primereact/menu";
import { Paginator } from "primereact/paginator";
import ResetPasswordModal from "./ResetPassword/ResetPasswordModal";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
    },
    table: {},
    paper: {
      padding: theme.spacing(2),
    },
    search: {
      position: "relative",
      border: `1px solid ${theme.palette.grey[400]}`,
      borderRadius: theme.shape.borderRadius,
      backgroundColor: theme.palette.background.paper,
      width: "100%",
    },
    searchIcon: {
      padding: theme.spacing(0, 2),
      height: "100%",
      position: "absolute",
      pointerEvents: "none",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      color: theme.palette.text.primary,
    },
    inputInput: {
      padding: theme.spacing(1, 1, 1, 0),
      // vertical padding + font size from searchIcon
      paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
      transition: theme.transitions.create("width"),
      width: "100%",
      [theme.breakpoints.up("md")]: {
        width: "20ch",
      },
    },
    rowStyle: {
      "&:hover": {
        backgroundColor: theme.palette.background.default,
      },
    },
    linkStyle: {
      fontSize: "16px",
      "&:hover": {
        cursor: "pointer",
      },
    },
    outlinedButtonStyle: {
      color: theme.palette.primary.main,
      borderColor: theme.palette.primary.main,
      "&:hover": {
        cursor: "pointer",
        borderColor: theme.palette.primary.light,
        color: theme.palette.primary.light,
      },
    },
    addBtn: {
      borderRadius: "5px",
      paddingRight: "0.5rem",
      color: "white",
      background: "#388e3c",
      marginRight: "1rem",
      "&:hover": {
        backgroundColor: alpha("#388e3c", 0.75),
      },
    },
    refreshbtn: {
      borderRadius: "5px",
      color: "white",
      background: "#d65c49",
      marginRight: "1rem",
      padding: "0 0.5rem",
      "&:hover": {
        backgroundColor: alpha("#d65c49", 0.75),
      },
    },
    exportBtn: {
      borderRadius: "5px",
      paddingRight: "0.5rem",
      color: "white",
      background: "#1769aa",
      marginRight: "1rem",
      "&:hover": {
        backgroundColor: alpha("#1769aa", 0.75),
      },
    },
    filterBtn: {
      borderRadius: "5px",
      paddingRight: "0.5rem",
      color: "white",
      background: "#ff9800",
      "&:hover": {
        backgroundColor: alpha("#ff9800", 0.75),
      },
    },
    buttonContainerStyle: {
      display: "flex",
      justifyContent: "flex-start",
      alignItems: "flex-end",
      height: "100%",
      padding: 0,
    },
    dataTableColumnStyle: {
      fontSize: "0.9rem",
      padding: "0.75rem !important",
      border: "1px solid #e9ecef !important",
    },
    dataTableHeaderStyle: {
      padding: "0.75rem !important",
      // border: "1px solid lightgrey !important",
      //backgroundColor: `${theme.palette.primary.main} !important`,
      //color: "#fff !important",
      whiteSpace: "nowrap",
    },
    dataTableRowStyle: {
      "&:nth-child(even)": {
        backgroundColor: "#BDBDBD !important",
      },
    },
    searchFieldStyle: {
      padding: "0.4rem 0 0.5rem 0.5rem",
    },
  })
);

export default function Customers() {
  const classes = useStyles();
  const totalCount = useSelector(
    (state: any) => state?.customers?.totalCustomers
  );
  const [loading, setLoading] = useState<boolean>(false);
  const [openFilter, setOpenFilter] = useState<boolean>(false);
  const [searchText, setSearchText] = useState<string>("");
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const customers = useSelector((state: any) => state?.customers?.list);
  // const [selectedCustomers,setSelectedCustomers] = useState<any>([]);
  const [resetCustomerPasswordData,setResetCustomerPasswordData] = useState<any>([]);
  const dispatch = useDispatch();
  const [isOnboardCustomerModalVisible, setIsOnboardCustomerModalVisible] =
    useState<boolean>(false);
  const [isViewCustomerModalVisible, setIsViewCustomerModalVisible] =
    useState<boolean>(false);
  const [isResetCustomerPasswordModalVisible, setIsResetCustomerPasswordModalVisible] = 
    useState<boolean>(false);
  const dataTableRef = useRef<any>();
  const menu = useRef<any>();

  // Paginations states
  const [first, setFirst] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  const onPageChage = (event: any) => {
    setFirst(event.first);
    setCurrentPage(event.page + 1);
    setRowsPerPage(event.rows);
  };

  /**
   * component did mount.
   */
  useEffect(() => {
    (async function fetchCustomers() {
      setLoading(true);
      await dispatch(
        fetchAllCustomers(searchText, currentPage || 1, rowsPerPage)
      );
      setLoading(false);
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage, rowsPerPage]);

  /**
   * on search event of filter data table.
   */
  const searchEvent = () => {
    (async function fetchCustomers() {
      setLoading(true);
      await dispatch(fetchAllCustomers(searchText, currentPage, rowsPerPage));
      setLoading(false);
    })();
  };

  /**
   * On click event of add new driver.
   * @param event
   */
  const onboardCustomerEvent = (event: any) => {
    try {
      // Open the add new driver modal.
      setIsOnboardCustomerModalVisible(true);
    } catch (error: any) {
      console.error(error);
    }
  };

  /**
   * Event triggered when modal is closed.
   * @param event
   */
  const handleCloseOnboardCustomerModal = (event: any) => {
    try {
      setIsOnboardCustomerModalVisible(false);

      (async function fetchCustomers() {
        setLoading(true);
        await dispatch(fetchAllCustomers());
        setLoading(false);
      })();
    } catch (error: any) {
      console.error(error);
    }
  };

  const openViewProfileModal = (event: any, profileData: any) => {
    event.stopPropagation();
    setIsViewCustomerModalVisible(profileData);
  };

  const handleCloseViewCustomerProfileModal = (event: boolean) => {
    setIsViewCustomerModalVisible(false);
  };

  const openResetPasswordModal = (event:any, rowData:any) => {
    event.stopPropagation();
    setResetCustomerPasswordData(rowData);
    setIsResetCustomerPasswordModalVisible(true);
  }

  const handleCloseResetCustomerPasswordModal = (event: {show: boolean, refreshPage: boolean}) => {
    setIsResetCustomerPasswordModalVisible(event.show);
    if (!event.show) {
      setResetCustomerPasswordData(null);
    }
    if (event.refreshPage) {
      (async function fetchCustomers() {
        setLoading(true);
        await dispatch(
          fetchAllCustomers(searchText, currentPage || 1, rowsPerPage)
        );
        setLoading(false);
      })();
    }
  }
  /**
   * Export as csv
   */
  const exportCSV = () => {
    if (dataTableRef && dataTableRef.current) {
      dataTableRef.current.exportCSV();
    }
  };

  const exportColumns = customers?.[0]
    ? Object.keys(customers?.[0]).reduce((a: any, c: string) => {
        const result = c?.replace(/([A-Z])/g, " $1");
        const finalResult = result.charAt(0).toUpperCase() + result.slice(1);

        a.push({
          title: finalResult,
          dataKey: c,
        });

        return a;
      }, [])
    : [];

  /**
   * Export as pdf
   */
  const exportPdf = () => {
    import("jspdf").then((jsPDF) => {
      import("jspdf-autotable").then(() => {
        const doc = new jsPDF.default(0, 0);
        doc.autoTable(exportColumns, customers);
        doc.save("customers.pdf");
      });
    });
  };

  const saveAsExcelFile = (buffer: any, fileName: string) => {
    import("file-saver").then((FileSaver) => {
      let EXCEL_TYPE =
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
      let EXCEL_EXTENSION = ".xlsx";
      const data = new Blob([buffer], {
        type: EXCEL_TYPE,
      });
      FileSaver.saveAs(
        data,
        fileName + "_export_" + new Date().getTime() + EXCEL_EXTENSION
      );
    });
  };

  /**
   * Export as excel
   */
  const exportExcel = () => {
    import("xlsx").then((xlsx) => {
      const worksheet = xlsx.utils.json_to_sheet(customers);
      const workbook = { Sheets: { data: worksheet }, SheetNames: ["data"] };
      const excelBuffer = xlsx.write(workbook, {
        bookType: "xlsx",
        type: "array",
      });
      saveAsExcelFile(excelBuffer, "customers");
    });
  };

  /**
   * Header template for the customer table.
   * @returns
   */
  const headerCustomerTable = (
    <Grid container>
      <Grid item sm={6} md={6}>
        <div className={classes.buttonContainerStyle}>
          <Tooltip title="Onboard new customer">
            <IconButton
              color="primary"
              aria-label="onboard-newcustomer"
              size="small"
              onClick={onboardCustomerEvent}
              className={classes.addBtn}
            >
              <Add />
              <Typography>New</Typography>
            </IconButton>
          </Tooltip>
        </div>
      </Grid>

      <Grid
        item
        sm={6}
        md={6}
        style={{ display: "flex", justifyContent: "flex-end" }}
      >
        <Tooltip title="Refresh Data">
          <IconButton
            color="primary"
            aria-label="refresh"
            size="small"
            onClick={searchEvent}
            className={classes.refreshbtn}
          >
            <Cached />
          </IconButton>
        </Tooltip>

        <Tooltip title="Export">
          <IconButton
            color="primary"
            aria-label="export"
            size="small"
            onClick={(event) => menu.current.toggle(event)}
            className={classes.exportBtn}
          >
            <GetApp />
            <Typography>Export</Typography>
          </IconButton>
        </Tooltip>

        <Menu
          model={[
            {
              label: "Export as CSV",
              icon: "pi pi-file-o",
              command: () => exportCSV(),
            },
            {
              label: "Export as Excel",
              icon: "pi pi-file-excel",
              command: () => exportExcel(),
            },
            {
              label: "Export as PDF",
              icon: "pi pi-file-pdf",
              command: () => exportPdf(),
            },
          ]}
          popup
          ref={menu}
          id="popup_menu"
        />

        <TextField
          placeholder="Search…"
          variant="outlined"
          onChange={(event: any) => setSearchText(event.target.value)}
          onKeyDown={(e) => {
            if (e.keyCode === 13) {
              searchEvent();
            }
          }}
          inputProps={{
            className: classes.searchFieldStyle,
          }}
          InputProps={{
            style: {
              padding: 0,
            },
            endAdornment: (
              <InputAdornment position="end" style={{ padding: 0, margin: 0 }}>
                <IconButton
                  onClick={searchEvent}
                  style={{
                    backgroundColor: "#388e3c",
                    padding: "0.25rem",
                    margin: 0,
                    borderRadius: "5px",
                    color: "#fff",
                  }}
                >
                  <Search />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      </Grid>
    </Grid>
  );

  /**
   * Action body template
   * @param row
   * @returns
   */
  const actionBody = (row: any) => (
    <div style={{ display: "inline-flex" }}>
      <Tooltip title="Reset Password">
        <IconButton
          color="primary"
          size="small"
          style={{color: 'blueviolet'}}
          onClick={(event: any) =>
            openResetPasswordModal(event, row)
          }
        >
          <LockOpen />
        </IconButton>
      </Tooltip>
      <Tooltip title="Edit Customer">
        <IconButton
          color="primary"
          size="small"
          onClick={(event: any) =>
            openViewProfileModal(event, row.profileDetails)
          }
        >
          <EditIcon />
        </IconButton>
      </Tooltip>
      <Tooltip title="Delete Customer">
        <IconButton
          color="secondary"
          size="small"
          onClick={(event: any) => {
            setShowDeleteConfirmation(true);
          }}
        >
          <DeleteIcon />
        </IconButton>
      </Tooltip>
    </div>
  );

  /**
   * Created at body
   * @param row
   * @returns
   */
  const createdAtBody = (row: any) =>
    moment(row.createdAt).format("MM/DD/YYYY");

  /**
   * Name
   * @param row
   * @returns
   */
  const nameBody = (row: any) =>
    `${row?.profileDetails?.firstName || ""} ${
      row?.profileDetails?.lastName || ""
    }`;

  return (
    <Grid container className={classes.root} spacing={4}>
      <Grid item sm={12}>
        <Paper style={{ padding: "0.25rem 1rem" }}>
          <Drawer
            anchor="right"
            open={openFilter}
            onClose={() => setOpenFilter(false)}
            elevation={5}
            PaperProps={{ style: { position: "absolute", width: "486px" } }}
            BackdropProps={{ style: { position: "absolute" } }}
            ModalProps={{
              container: document.getElementById("drawer-container"),
              style: { position: "absolute", zIndex: 100 },
            }}
            SlideProps={{
              onEnter: (node: any) => {
                node.style.webkitTransform = "scaleX(0)";
                node.style.transform = "scaleX(0)";
                node.style.transformOrigin = "top right ";
              },
              onExiting: (node: any) => {
                node.style.webkitTransform = "scaleX(0)";
                node.style.transform = "scaleX(0)";
                node.style.transformOrigin = "top right ";
              },
            }}
          ></Drawer>

          <DataTable
            ref={dataTableRef}
            loading={loading}
            value={customers}
            // selection={selectedCustomers}
            sortMode="multiple"
            // onSelectionChange={(e: any) => setSelectedCustomers(e.value)}
            dataKey="id"
            resizableColumns
            columnResizeMode="expand"
            tableStyle={{
              position: "relative",
              tableLayout: "auto",
              overflow: "auto",
              minWidth: "1000px",
              width: "100%",
            }}
            header={headerCustomerTable}
          >
            {/* <Column selectionMode="multiple" headerStyle={{ width: '3rem' }}></Column> */}
            <Column
              field="profileDetails.companyName"
              header="Company Name"
              sortable
              className={classes.dataTableColumnStyle}
              headerClassName={classes.dataTableHeaderStyle}
            ></Column>
            <Column
              field="loginId"
              header="Login ID"
              sortable
              className={classes.dataTableColumnStyle}
              headerClassName={classes.dataTableHeaderStyle}
            ></Column>
            <Column
              header="Name"
              sortable
              body={nameBody}
              className={classes.dataTableColumnStyle}
              headerClassName={classes.dataTableHeaderStyle}
            ></Column>
            <Column
              field="createdBy"
              header="Created By"
              sortable
              className={classes.dataTableColumnStyle}
              headerClassName={classes.dataTableHeaderStyle}
            ></Column>
            <Column
              field="createdAt"
              header="Added Date"
              sortable
              body={createdAtBody}
              className={classes.dataTableColumnStyle}
              headerClassName={classes.dataTableHeaderStyle}
            ></Column>
            <Column
              header="Actions"
              body={actionBody}
              className={classes.dataTableColumnStyle}
              headerClassName={classes.dataTableHeaderStyle}
            ></Column>
          </DataTable>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Paginator
              first={first}
              rows={rowsPerPage}
              totalRecords={totalCount}
              rowsPerPageOptions={[5, 10, 15, 20]}
              onPageChange={onPageChage}
            ></Paginator>

            <Typography>Total Records: {totalCount}</Typography>
          </div>
        </Paper>
      </Grid>
      {isOnboardCustomerModalVisible && (
        <OnboardCustomerModal
          open={isOnboardCustomerModalVisible}
          handleClose={handleCloseOnboardCustomerModal}
        />
      )}
      {isViewCustomerModalVisible && (
        <ViewCustomerProfile
          open={isViewCustomerModalVisible}
          initialValues={isViewCustomerModalVisible}
          handleClose={handleCloseViewCustomerProfileModal}
        />
      )}
      {showDeleteConfirmation && (
        <DeleteConfirmation
          open={showDeleteConfirmation}
          handleClose={() => {
            setShowDeleteConfirmation(false);
          }}
        />
      )}
      {
        isResetCustomerPasswordModalVisible &&
        resetCustomerPasswordData &&
        <ResetPasswordModal
          open={isResetCustomerPasswordModalVisible}
          customerDetails={resetCustomerPasswordData}
          handleClose={handleCloseResetCustomerPasswordModal}
        />
      }
    </Grid>
  );
}
