// import { Container } from "@material-ui/core";
// import { makeStyles } from "@material-ui/core/styles";

import { Route, Redirect } from "react-router-dom";
import { useSelector } from "react-redux";
// import AppBarComponent from "./AppBar";

// const useStyles = makeStyles((theme) => ({
//   containerStyle: {
//     display: "flex",
//     flexDirection: "column",
//     flexGrow: 1,
//     width: "99vw",
//     padding: 0,
//     margin: 0,
//     backgroundColor: theme.palette.background.default,
//   },
// }));

type ComponentType = JSX.Element;

export default function UnprotectedRoute({
  component: Component,
  ...rest
}: { component: ComponentType } & any): JSX.Element {
  const isUserLoggedIn = useSelector((state: any) => state.user.userId);
  // const classes = useStyles();

  return (
    <Route
      {...rest}
      render={(componentProps) =>
        !isUserLoggedIn ? (
          <Component {...componentProps} />
        ) : (
          <Redirect to="/dashboard" />
        )
      }
    />
  );
}
