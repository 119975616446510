import Axios from "../config/interceptors/axiosInterceptor";

/**
 * This function is used to fetch vehicles
 * @returns
 */
export const getVehicles = async (
  searchText: string,
  pageNumber: number,
  pageSize: number
) => {
  try {
    return await Axios.get(
      `${
        process.env.REACT_APP_API_BASE_URL
      }/vehicle/get?page_number=${pageNumber}&page_size=${pageSize}&search_term=${
        searchText || ""
      }`
    );
  } catch (error: any) {
    return {
      status: 500,
      data: null,
      statusText: "Oops! something went wrong",
    };
  }
};
/**
 * This function is used to add a new vehicle
 * @returns
 */
export const addNewVehicle = async (payload: any) => {
  try {
    return await Axios.post(
      `${process.env.REACT_APP_API_BASE_URL}/vehicle/create`,
      payload
    );
  } catch (error: any) {
    return {
      status: 500,
      data: null,
      statusText: "Oops! something went wrong",
    };
  }
};
/**
 * This function is used to update an existing vehicle
 * @returns
 */
export const updateVehicle = async (payload: any) => {
  try {
    return await Axios.post(
      `${process.env.REACT_APP_API_BASE_URL}/vehicle/update`,
      payload
    );
  } catch (error: any) {
    return {
      status: 500,
      data: null,
      statusText: "Oops! something went wrong",
    };
  }
};
/**
 * This function is used to delete a vehicle
 * @returns
 */
export const deleteVehicle = async (vehicleID: string) => {
  try {
    return await Axios.delete(
      `${process.env.REACT_APP_API_BASE_URL}/vehicle/delete?vehicleId=${vehicleID}`
    );
  } catch (error: any) {
    return {
      status: 500,
      data: null,
      statusText: "Oops! something went wrong",
    };
  }
};
