import * as customerTypes from "./types";

const types = {
  ...customerTypes,
};

const initialState = {
  totalCustomers: 0,
  list: [],
  totalPages: 1,
  currentPage: 1,
};

export default function customerReducer(
  state = initialState,
  action: { type: string; payload: any }
) {
  switch (action.type) {
    case types.FETCH_ALL_CUSTOMER_ERROR:
      return {
        ...state,
        totalCustomers: 0,
        list: [],
        totalPages: 0,
        currentPage: 0,
      };
    case types.FETCH_ALL_CUSTOMER_SUCCESS:
      return {
        ...state,
        totalCustomers: action.payload.totalCount,
        list: action.payload.records,
        totalPages: action.payload.totalPages,
        currentPage: action.payload.currentPage,
      };
    default:
      return state;
  }
}
