import * as userType from "./types";

const types = {
  ...userType,
};

export type UserType = {
  userId?: string | null;
  emailAddress?: string | null;
  password?: string;
  role?: string;
  theme?: string;
};

const initialState: UserType = {
  userId: null,
  emailAddress: null,
  role: "",
  theme: "light",
};

export default function userReducer(
  state = initialState,
  action: { type: String; payload: any }
) {
  switch (action.type) {
    case types.AUTHENTICATE_USER_SUCCESS:
      return {
        userId: action.payload?.user?.userId,
        emailAddress: action.payload?.user?.emailAddress,
        role: action.payload?.user?.role,
        theme: action.payload?.user?.theme,
      };

    case types.LOGOUT_USER_SUCCESS:
      localStorage.removeItem('id_token');
      localStorage.removeItem('access_token');
      localStorage.removeItem('refresh_token');
      return initialState;

    case types.TOGGLE_DARK_MODE:
      return {
        ...state,
        theme: state.theme === "dark" ? "light" : "dark",
      };

    default:
      return state;
  }
}
