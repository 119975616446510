import {
  Grid,
  Paper,
  IconButton,
  Typography,
  Tooltip,
  InputAdornment,
  TextField,
} from "@material-ui/core";
import {
  makeStyles,
  createStyles,
  Theme,
  alpha,
} from "@material-ui/core/styles";
import { useDispatch, useSelector } from "react-redux";
import { Search, Add, GetApp, Cached } from "@material-ui/icons";
import AddDriverModal from "./AddNewDrivers/AddDriverModal";
import { useState, useEffect, useRef } from "react";
import { fetchAllDrivers } from "../../redux/drivers/actions";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import EditDriverModal from "./EditDriver/EditDriverModal";
// import LoadingTable from "../../components/Table/LoadingTable";
import moment from "moment";
import DeleteDriverModal from "./DeleteDriver/DeleteDriverModal";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Paginator } from "primereact/paginator";
import { Menu } from "primereact/menu";

import { normalizePhoneNumber } from "../../formUtils/normalizations";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
    },
    table: {},
    paper: {
      padding: theme.spacing(2),
    },
    search: {
      position: "relative",
      border: `1px solid ${theme.palette.grey[400]}`,
      borderRadius: theme.shape.borderRadius,
      backgroundColor: theme.palette.background.paper,
      width: "100%",
    },
    searchIcon: {
      padding: theme.spacing(0, 2),
      height: "100%",
      position: "absolute",
      pointerEvents: "none",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      color: theme.palette.text.primary,
    },
    inputInput: {
      padding: theme.spacing(1, 1, 1, 0),
      // vertical padding + font size from searchIcon
      paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
      transition: theme.transitions.create("width"),
      width: "100%",
      [theme.breakpoints.up("md")]: {
        width: "20ch",
      },
    },
    rowStyle: {
      "&:hover": {
        backgroundColor: theme.palette.background.default,
      },
    },
    linkStyle: {
      fontSize: "16px",
      "&:hover": {
        cursor: "pointer",
      },
    },
    outlinedButtonStyle: {
      color: theme.palette.primary.main,
      borderColor: theme.palette.primary.main,
      "&:hover": {
        cursor: "pointer",
        borderColor: theme.palette.primary.light,
        color: theme.palette.primary.light,
      },
    },
    addBtn: {
      borderRadius: "5px",
      paddingRight: "0.5rem",
      color: "white",
      background: "#388e3c",
      marginRight: "1rem",
      "&:hover": {
        backgroundColor: alpha("#388e3c", 0.75),
      },
    },
    refreshbtn: {
      borderRadius: "5px",
      color: "white",
      background: "#d65c49",
      marginRight: "1rem",
      padding: "0 0.5rem",
      "&:hover": {
        backgroundColor: alpha("#d65c49", 0.75),
      },
    },
    exportBtn: {
      borderRadius: "5px",
      paddingRight: "0.5rem",
      color: "white",
      background: "#1769aa",
      marginRight: "1rem",
      "&:hover": {
        backgroundColor: alpha("#1769aa", 0.75),
      },
    },
    filterBtn: {
      borderRadius: "5px",
      paddingRight: "0.5rem",
      color: "white",
      background: "#ff9800",
      "&:hover": {
        backgroundColor: alpha("#ff9800", 0.75),
      },
    },
    buttonContainerStyle: {
      display: "flex",
      justifyContent: "flex-start",
      alignItems: "flex-end",
      height: "100%",
      padding: 0,
    },
    dataTableColumnStyle: {
      fontSize: "0.9rem",
      padding: "0.75rem !important",
      border: "1px solid #e9ecef !important",
    },
    dataTableHeaderStyle: {
      padding: "0.75rem !important",
      // border: "1px solid lightgrey !important",
      //backgroundColor: `${theme.palette.primary.main} !important`,
      //color: "#fff !important",
      whiteSpace: "nowrap",
    },
    dataTableRowStyle: {
      "&:nth-child(even)": {
        backgroundColor: "#BDBDBD !important",
      },
    },
    searchFieldStyle: {
      padding: "0.4rem 0 0.5rem 0.5rem",
    },
  })
);

export default function Drivers() {
  /**
   * Variable declaration
   */
  const classes = useStyles();
  const drivers = useSelector((state: any) => state?.drivers?.list);
  const totalCount = useSelector((state: any) => state?.drivers?.totalDrivers);
  const [loading, setLoading] = useState<boolean>(false);
  const [searchText, setSearchText] = useState<string>("");
  const [isAddDriverModalVisible, setIsAddDriverModalVisible] =
    useState<boolean>(false);
  const [isEditDriverModalVisible, setIsEditDriverModalVisible] =
    useState<boolean>(false);
  const [isDeleteDriverModalVisible, setIsDeleteDriverModalVisible] =
    useState<boolean>(false);
  const [driverToBeEdited, setDriverToBeEdited] = useState<any>(null);
  const [driverToBeDeleted, setDriverToBeDeleted] = useState<any>(null);
  const dispatch = useDispatch();
  const dataTableRef = useRef<any>();
  const menu = useRef<any>();

  // Paginations states
  const [first, setFirst] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  const onPageChage = (event: any) => {
    setFirst(event.first);
    setCurrentPage(event.page + 1);
    setRowsPerPage(event.rows);
  };

  /**
   * component did mount.
   */
  useEffect(() => {
    (async function fetchDrivers() {
      setLoading(true);
      await dispatch(
        fetchAllDrivers(searchText, currentPage || 1, rowsPerPage)
      );
      setLoading(false);
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage, rowsPerPage]);

  /**
   * on search event of filter data table.
   */
  const searchEvent = () => {
    (async function fetchDrivers() {
      setLoading(true);
      await dispatch(fetchAllDrivers(searchText, currentPage, rowsPerPage));
      setLoading(false);
    })();
  };

  /**
   * On click event of add new driver.
   * @param event
   */
  const addNewDriverEvent = (event: any) => {
    try {
      // Open the add new driver modal.
      setIsAddDriverModalVisible(true);
    } catch (error: any) {
      console.error(error);
    }
  };

  /**
   * Event triggered when add modal is closed.
   * @param event
   */
  const handleCloseAddDriverModal = (event: {
    show: boolean;
    refreshPage: boolean;
  }) => {
    try {
      setIsAddDriverModalVisible(event.show);
      if (event.refreshPage) {
        (async function fetchDrivers() {
          setLoading(true);
          await dispatch(fetchAllDrivers());
          setLoading(false);
        })();
      }
    } catch (error: any) {
      console.error(error);
    }
  };
  /**
   * Event triggered when edit modal is closed.
   * @param event
   */
  const handleCloseEditDriverModal = (event: {
    show: boolean;
    refreshPage: boolean;
  }) => {
    try {
      setIsEditDriverModalVisible(event.show);
      if (event.refreshPage) {
        (async function fetchDrivers() {
          setLoading(true);
          await dispatch(fetchAllDrivers());
          setLoading(false);
        })();
      }
      if (!event.show) {
        setDriverToBeEdited(null);
      }
    } catch (error: any) {
      console.error(error);
    }
  };

  /**
   * On delete confirmation event of driver delete
   * @param event
   */
  const handleCloseDeleteDriverModal = (event: {
    show: boolean;
    refreshPage: boolean;
  }) => {
    try {
      setIsDeleteDriverModalVisible(event.show);
      if (event.refreshPage) {
        (async function fetchDrivers() {
          setLoading(true);
          await dispatch(fetchAllDrivers());
          setLoading(false);
        })();
      }
      if (!event.show) {
        setDriverToBeDeleted(null);
      }
    } catch (error: any) {
      console.error(error);
    }
  };

  /**
   * on edit event of the edit driver button click
   */
  const editDriverEvent = (event: any, driverData: any) => {
    try {
      event.stopPropagation();
      const payload = {
        driverId: driverData?.driverId,
        profileId: driverData?.profileId,
        companyName: driverData?.profile?.companyName,
        firstName: driverData?.profile?.firstName,
        lastName: driverData?.profile?.lastName,
        emailAddress: driverData?.profile?.emailAddress,
        primaryPhoneNumber: driverData?.profile?.primaryPhoneNumber,
        secondaryPhoneNumber: driverData?.profile?.secondaryPhoneNumber,
        dateOfBirth: driverData?.dateOfBirth,
        licenseNumber: driverData?.licenseNumber,
      };
      setDriverToBeEdited(payload);
      setIsEditDriverModalVisible(true);
    } catch (error: any) {
      console.error(error);
    }
  };

  /**
   * Delete driver click event
   * @param driverData
   */
  const deleteDriverEvent = async (event: any, driverData: any) => {
    try {
      event.stopPropagation();
      setDriverToBeDeleted(driverData);
      setIsDeleteDriverModalVisible(true);
    } catch (error: any) {
      console.error(error);
    }
  };

  /**
   * Export as csv
   */
  const exportCSV = () => {
    if (dataTableRef && dataTableRef.current) {
      dataTableRef.current.exportCSV();
    }
  };

  const exportColumns = drivers?.[0]
    ? Object.keys(drivers?.[0]).reduce((a: any, c: string) => {
        const result = c?.replace(/([A-Z])/g, " $1");
        const finalResult = result.charAt(0).toUpperCase() + result.slice(1);

        a.push({
          title: finalResult,
          dataKey: c,
        });

        return a;
      }, [])
    : [];

  /**
   * Export as pdf
   */
  const exportPdf = () => {
    import("jspdf").then((jsPDF) => {
      import("jspdf-autotable").then(() => {
        const doc = new jsPDF.default(0, 0);
        doc.autoTable(exportColumns, drivers);
        doc.save("drivers.pdf");
      });
    });
  };

  const saveAsExcelFile = (buffer: any, fileName: string) => {
    import("file-saver").then((FileSaver) => {
      let EXCEL_TYPE =
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
      let EXCEL_EXTENSION = ".xlsx";
      const data = new Blob([buffer], {
        type: EXCEL_TYPE,
      });
      FileSaver.saveAs(
        data,
        fileName + "_export_" + new Date().getTime() + EXCEL_EXTENSION
      );
    });
  };

  /**
   * Export as excel
   */
  const exportExcel = () => {
    import("xlsx").then((xlsx) => {
      const worksheet = xlsx.utils.json_to_sheet(drivers);
      const workbook = { Sheets: { data: worksheet }, SheetNames: ["data"] };
      const excelBuffer = xlsx.write(workbook, {
        bookType: "xlsx",
        type: "array",
      });
      saveAsExcelFile(excelBuffer, "drivers");
    });
  };

  /**
   * header template for the drivers data table.
   */
  const headerDriversTable = (
    <Grid container>
      <Grid item sm={6} md={6}>
        <div className={classes.buttonContainerStyle}>
          <Tooltip title="Add New Driver">
            <IconButton
              color="primary"
              aria-label="add-new-driver"
              size="small"
              onClick={addNewDriverEvent}
              className={classes.addBtn}
            >
              <Add />
              <Typography>New</Typography>
            </IconButton>
          </Tooltip>
        </div>
      </Grid>

      <Grid
        item
        sm={6}
        md={6}
        style={{ display: "flex", justifyContent: "flex-end" }}
      >
        <Tooltip title="Refresh Data">
          <IconButton
            color="primary"
            aria-label="refresh"
            size="small"
            onClick={searchEvent}
            className={classes.refreshbtn}
          >
            <Cached />
          </IconButton>
        </Tooltip>

        <Tooltip title="Export">
          <IconButton
            color="primary"
            aria-label="export"
            size="small"
            onClick={(event) => menu.current.toggle(event)}
            className={classes.exportBtn}
          >
            <GetApp />
            <Typography>Export</Typography>
          </IconButton>
        </Tooltip>

        <Menu
          model={[
            {
              label: "Export as CSV",
              icon: "pi pi-file-o",
              command: () => exportCSV(),
            },
            {
              label: "Export as Excel",
              icon: "pi pi-file-excel",
              command: () => exportExcel(),
            },
            {
              label: "Export as PDF",
              icon: "pi pi-file-pdf",
              command: () => exportPdf(),
            },
          ]}
          popup
          ref={menu}
          id="popup_menu"
        />

        <TextField
          placeholder="Search…"
          variant="outlined"
          onChange={(event: any) => setSearchText(event.target.value)}
          onKeyDown={(e) => {
            if (e.keyCode === 13) {
              searchEvent();
            }
          }}
          inputProps={{
            className: classes.searchFieldStyle,
          }}
          InputProps={{
            style: {
              padding: 0,
            },
            endAdornment: (
              <InputAdornment position="end" style={{ padding: 0, margin: 0 }}>
                <IconButton
                  onClick={searchEvent}
                  style={{
                    backgroundColor: "#388e3c",
                    padding: "0.25rem",
                    margin: 0,
                    borderRadius: "5px",
                    color: "#fff",
                  }}
                >
                  <Search />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      </Grid>
    </Grid>
  );

  /**
   * Action body of the data table.
   * @param row
   * @returns
   */
  const actionBody = (row: any) => (
    <div style={{ display: "inline-flex" }}>
      <Tooltip title="Edit Driver">
        <IconButton
          color="primary"
          size="small"
          onClick={(event: any) => editDriverEvent(event, row)}
        >
          <EditIcon />
        </IconButton>
      </Tooltip>
      <Tooltip title="Delete Driver">
        <IconButton
          color="secondary"
          size="small"
          onClick={(event: any) => deleteDriverEvent(event, row)}
        >
          <DeleteIcon />
        </IconButton>
      </Tooltip>
    </div>
  );

  /**
   * DOB body.
   * @param row
   * @returns
   */
  const dateOfBirthBody = (row: any) =>
    moment(row?.dateOfBirth).format("MM/DD/YYYY");

  /**
   * Name
   * @param row
   * @returns
   */
  const nameBody = (row: any) =>
    `${row?.profile?.firstName || ""} ${row?.profile?.lastName || ""}`;

  return (
    <Grid container className={classes.root} spacing={4}>
      <Grid item sm={12}>
        <Paper style={{ padding: "0.25rem 1rem" }}>
          <DataTable
            ref={dataTableRef}
            loading={loading}
            value={drivers}
            // selection={selectedDrivers}
            sortMode="multiple"
            // onSelectionChange={(e: any) => setSelectedDrivers(e.value)}
            dataKey="id"
            resizableColumns
            columnResizeMode="expand"
            tableStyle={{
              position: "relative",
              tableLayout: "auto",
              overflow: "auto",
              minWidth: "1000px",
              width: "100%",
            }}
            header={headerDriversTable}
          >
            <Column
              header="Name"
              sortable
              body={nameBody}
              className={classes.dataTableColumnStyle}
              headerClassName={classes.dataTableHeaderStyle}
            ></Column>
            <Column
              field="profile.companyName"
              header="Company Name"
              sortable
              className={classes.dataTableColumnStyle}
              headerClassName={classes.dataTableHeaderStyle}
            ></Column>
            <Column
              field="licenseNumber"
              header="License Number"
              sortable
              className={classes.dataTableColumnStyle}
              headerClassName={classes.dataTableHeaderStyle}
            ></Column>
            <Column
              field="profile.emailAddress"
              header="Email Address"
              sortable
              className={classes.dataTableColumnStyle}
              headerClassName={classes.dataTableHeaderStyle}
            ></Column>
            <Column
              field="dateOfBirth"
              header="Date Of Birth"
              sortable
              body={dateOfBirthBody}
              className={classes.dataTableColumnStyle}
              headerClassName={classes.dataTableHeaderStyle}
            ></Column>
            <Column
              field="profile.primaryPhoneNumber"
              header="Phone Number"
              sortable
              body={(row: any) =>
                row.profile.primaryPhoneNumber
                  ? "+1 " + normalizePhoneNumber(row.profile.primaryPhoneNumber)
                  : ""
              }
              className={classes.dataTableColumnStyle}
              headerClassName={classes.dataTableHeaderStyle}
            ></Column>
            <Column
              header="Actions"
              body={actionBody}
              className={classes.dataTableColumnStyle}
              headerClassName={classes.dataTableHeaderStyle}
            ></Column>
          </DataTable>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Paginator
              first={first}
              rows={rowsPerPage}
              totalRecords={totalCount}
              rowsPerPageOptions={[5, 10, 15, 20]}
              onPageChange={onPageChage}
            ></Paginator>

            <Typography>Total Records: {totalCount}</Typography>
          </div>
        </Paper>
      </Grid>
      {isAddDriverModalVisible && (
        <AddDriverModal
          open={isAddDriverModalVisible}
          handleClose={handleCloseAddDriverModal}
        />
      )}
      {isEditDriverModalVisible && driverToBeEdited && (
        <EditDriverModal
          open={isEditDriverModalVisible}
          handleClose={handleCloseEditDriverModal}
          driverToBeEdited={driverToBeEdited}
        />
      )}
      {isDeleteDriverModalVisible && driverToBeDeleted && (
        <DeleteDriverModal
          open={isDeleteDriverModalVisible}
          handleClose={handleCloseDeleteDriverModal}
          driverToBeDeleted={driverToBeDeleted}
        />
      )}
    </Grid>
  );
}
