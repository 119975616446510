import { Grid, Paper, Typography } from "@material-ui/core";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import {
  Assignment,
  RestoreOutlined,
  HourglassEmpty,
  TurnedIn,
} from "@material-ui/icons";
import { Skeleton } from "@material-ui/lab";
import { useEffect, useRef, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { fetchDashboardData } from "../../redux/dashboard/actions";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";
import moment from "moment";
import { OverlayPanel } from "primereact/overlaypanel";
import OrderSummaryPanel from "../Orders/OrderSummary/panel";
import OrderSummaryModal from "../Orders/OrderSummary/modal";
import { getCalenderEvents } from "../../services/orders.service";
// import { fetchProfileAction } from "../../redux/profile/actions";

// import BarChart from "./BarChart";
// import Scheduler from "./Scheduler";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
    },
    paper: {
      backgroundColor: "#f8f9fa",
      padding: theme.spacing(2),
      "&:hover": {
        cursor: "pointer",
      },
    },
    notificationsCenter: {
      padding: theme.spacing(2),
    },
    paperHeaderStyle: {
      color: theme.palette.text.primary,
    },
    barchartHeaderStyle: {
      color: theme.palette.text.primary,
      marginBottom: "20px",
    },
    paperSubHeaderStyle: {
      color: theme.palette.text.primary,
      fontSize: "14px",
      fontWeight: "bold",
    },
    paperSubValues: {
      fontSize: "14px",
    },
    control: {
      padding: theme.spacing(2),
    },
    subvaluesContainer: {
      marginTop: "10px",
    },
    table: {},
    rowStyle: {
      "&:hover": {
        backgroundColor: theme.palette.background.default,
      },
    },
    linkStyle: {
      fontSize: "16px",
      "&:hover": {
        cursor: "pointer",
      },
    },
    timelineContainer: {
      position: "absolute",
      width: "80%",
      padding: theme.spacing(2),
      maxHeight: "90%",
      overflow: "scroll",
    },
    schedulePaper: {
      padding: theme.spacing(2),
      borderLeft: `5px solid ${theme.palette.primary.main}`,
      "&:hover": {
        cursor: "pointer",
      },
      maxHeight: 488,
      overflow: "scroll",
    },
  })
);

export default function DashBoard({ history }: any) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const dashboardData = useSelector((state: any) => ({
    totalServiceRequests: state?.dashboard?.totalServiceRequests || 0,
    serviceRequestsPendingQuote:
      state?.dashboard?.serviceRequestsPendingQuote || 0,
    workOrdersInProgress: state?.dashboard?.workOrdersInProgress || 0,
    workOrdersCompleted: state?.dashboard?.workOrdersCompleted || 0,
  }));
  const [loading, setLoading] = useState<boolean>(false);
  const [orderSummaryModalVisible, setOrderSummaryModalVisible] = useState<boolean>(false);
  const [selectedRow, setSelectedRow] = useState<any>(null);
  const [events, setEvents] = useState<any>([]);
  
  useEffect(() => {
    (async function fetchDashboard() {
      setLoading(true);
      await dispatch(fetchDashboardData());
      const fetchEventsResponse = await getCalenderEvents();
      if (fetchEventsResponse.status === 200) {
        setEvents(fetchEventsResponse.data);
      }
      setLoading(false);
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const redirectTo = (event:any, row: any) => {
    setOrderSummaryModalVisible(true);
    setSelectedRow({orderId: row});
  };

  const handleCloseEventOrderSummaryModal = (event:any) => {
    setOrderSummaryModalVisible(event.show);
    setSelectedRow(null);
  }

  return (
    <Grid container className={classes.root} spacing={4}>
      <Grid item xs={12} sm={3}>
        <Paper
          className={classes.paper}
          onClick={() => {
            history.push("/service-requests?status=all");
          }}
        >
          <Typography className={classes.paperHeaderStyle} variant="subtitle2">
            Total Service Requests
          </Typography>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <Typography variant="h4">
              {loading ? (
                <Skeleton width={50} />
              ) : (
                dashboardData?.totalServiceRequests
              )}
            </Typography>
            <Assignment style={{ fontSize: "2rem", color: "#1769aa" }} />
          </div>
        </Paper>
      </Grid>
      <Grid item xs={12} sm={3}>
        <Paper
          className={classes.paper}
          onClick={() => {
            history.push("/service-requests?status=pending_quotation");
          }}
        >
          <Typography className={classes.paperHeaderStyle} variant="subtitle2">
            Pending Quotation
          </Typography>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <Typography variant="h4">
              {loading ? (
                <Skeleton width={50} />
              ) : (
                dashboardData?.serviceRequestsPendingQuote
              )}
            </Typography>
            <HourglassEmpty style={{ fontSize: "2rem", color: "#388e3c" }} />
          </div>
        </Paper>
      </Grid>
      <Grid item xs={12} sm={3}>
        <Paper
          className={classes.paper}
          onClick={() => {
            history.push("/orders?status=in_progress");
          }}
        >
          <Typography className={classes.paperHeaderStyle} variant="subtitle2">
            Work Orders In Progress
          </Typography>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <Typography variant="h4">
              {loading ? (
                <Skeleton width={50} />
              ) : (
                dashboardData?.workOrdersInProgress
              )}
            </Typography>
            <RestoreOutlined style={{ fontSize: "2rem", color: "#9b1de2" }} />
          </div>
        </Paper>
      </Grid>
      <Grid item xs={12} sm={3}>
        <Paper
          className={classes.paper}
          onClick={() => {
            history.push("/orders?status=completed");
          }}
        >
          <Typography className={classes.paperHeaderStyle} variant="subtitle2">
            Work Orders Completed
          </Typography>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <Typography variant="h4">
              {loading ? (
                <Skeleton width={50} />
              ) : (
                dashboardData?.workOrdersCompleted
              )}
            </Typography>
            <TurnedIn style={{ fontSize: "2rem", color: "#d65c49" }} />
          </div>
        </Paper>
      </Grid>

      <Grid item sm={12} md={12} xl={12} xs={12}>
        <Paper className={classes.schedulePaper} id="paper_cal" style={{position: 'relative'}}>
          <Typography className={classes.barchartHeaderStyle} variant="h6">
            Schedule
          </Typography>
          <FullCalendar
            events={events}
            eventClick={(info) => {
              info.jsEvent.preventDefault(); // don't let the browser navigate
              redirectTo(info.jsEvent,info.event.title)
            }}
            initialDate={moment().format("YYYY-MM-DD")}
            initialView="dayGridMonth"
            plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
            headerToolbar={{
              left: "prev,next today",
              center: "title",
              right: "dayGridMonth,timeGridWeek,timeGridDay",
            }}
            displayEventTime={false}
            selectable
            selectMirror
            dayMaxEvents
          />
          {/* <Scheduler /> */}
        </Paper>
      </Grid>
      {
        orderSummaryModalVisible &&
        selectedRow &&
        <OrderSummaryModal
          open={orderSummaryModalVisible}
          handleClose={handleCloseEventOrderSummaryModal}
          selectedOrder={selectedRow}
          history={history}
        />
      }
    </Grid>
  );
}
