import { AnyAction } from "redux";
import { ThunkAction } from "redux-thunk";
import { getAllServiceRequests } from "../../services/serviceRequests.service";
import * as ordersTypes from "./types";

const types = {
  ...ordersTypes,
};

export const changeSelectedServiceRequest = (orderNumber: String) => ({
  type: types.CHANGE_SELECTED_SERVICE_REQUEST,
  payload: { orderNumber },
});


/**
 * action to fetch service requests
 * @returns 
 */
 export const fetchServiceRequest =
 (searchText: string = '', pageNumber: number = 1,pageSize: number = 10,filters: string = ''): ThunkAction<void, any, unknown, AnyAction> =>
 async (dispatch): Promise<void> => {
   const serviceRequestResponse = await getAllServiceRequests(searchText,pageNumber,pageSize,filters);
   if (serviceRequestResponse.status === 200) {
     dispatch({ type: types.FETCH_SERVICE_REQUEST_SUCCESS, payload: serviceRequestResponse.data });
   } else {
     dispatch({ type: types.FETCH_SERVICE_REQUEST_SUCCESS });
   }
 };
