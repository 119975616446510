import { AnyAction } from "redux";
import { ThunkAction } from "@reduxjs/toolkit";

import * as driverTypes from "./types";
import { getDrivers } from "../../services/driver.service";

const types = {
  ...driverTypes,
};


/**
 * Action to fetch the Drivers.
 * @param searchText 
 * @param pageNumber 
 * @param pageSize 
 * @returns 
 */
export const fetchAllDrivers =
(searchText: string = '', pageNumber: number = 1,pageSize: number = 10): ThunkAction<void, any, unknown, AnyAction> =>
async (dispatch): Promise<void> => {
    const allDriverResponse = await getDrivers(searchText, pageNumber, pageSize);
    if (allDriverResponse.status === 200) {
        dispatch({
            type: types.FETCH_ALL_DRIVER_SUCCESS,
            payload: allDriverResponse.data
        });
    } else {
        dispatch({
            type: types.FETCH_ALL_DRIVER_ERROR,
            payload: []
        }); 
    }
};