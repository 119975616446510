import React, { useRef, useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useFormik } from "formik";
import { InputText } from "primereact/inputtext";
import { Password } from "primereact/password";
import { Card } from "primereact/card";
import { Button } from "primereact/button";
import { classNames } from "primereact/utils";
// import { Divider } from "primereact/divider";
import { Messages } from "primereact/messages";

import "./index.css";
import Itad_Destruction from "./Itad_destruciton.png";
import Logo from "./CaegisLogo.png";
import { authenticateUser } from "../../redux/user/actions";
import { hideAlert } from "../../redux/alerts/actions";

export default function Login({ history }: any) {
  const messages = useRef<any>();
  const alertState = useSelector((state: any) => state?.alerts);
  const [loading, setLoading] = useState<boolean>(false);
  const dispatch = useDispatch();

  useEffect(() => {
    if (alertState.show) {
      messages.current.replace({
        sticky: true,
        severity: alertState.type,
        summary: "Authentication Failed: ",
        detail: alertState.message,
      });
    }
  }, [alertState]);

  const _handleUserLogin = async ({
    emailAddress,
    password,
  }: {
    emailAddress: string;
    password: string;
  }) => {
    setLoading(true);
    const isAuthenticationSuccess: any = await dispatch(
      authenticateUser({ emailAddress, password })
    );
    setLoading(false);

    if (isAuthenticationSuccess) {
      history.push("/dashboard");
    }
  };

  const _onCloseAlert = () => {
    dispatch(hideAlert());
  };

  const formik = useFormik({
    initialValues: {
      emailAddress: "",
      password: "",
    },
    validate: (data: { emailAddress: string; password: string }) => {
      let errors: {
        emailAddress?: string;
        password?: string;
      } = {};

      if (!data.emailAddress) {
        errors.emailAddress = "Email Address is required.";
      }

      if (!data.password) {
        errors.password = "Password is required.";
      }

      return errors;
    },
    onSubmit: (data: { emailAddress: string; password: string }) => {
      _handleUserLogin(data);

      formik.resetForm();
    },
  });

  const isFormFieldValid = (name: "emailAddress" | "password") =>
    !!(formik.touched[name] && formik.errors[name]);
  const getFormErrorMessage = (name: "emailAddress" | "password") => {
    return (
      isFormFieldValid(name) && (
        <small className="p-error">{formik.errors[name]}</small>
      )
    );
  };

  const passwordHeader = <h6>Enter password</h6>;
  // const passwordFooter = (
  //   <React.Fragment>
  //     <Divider />
  //     <p className="p-mt-2">Suggestions</p>
  //     <ul className="p-pl-2 p-ml-2 p-mt-0" style={{ lineHeight: "1.5" }}>
  //       <li>Minimum 8 characters</li>
  //     </ul>
  //   </React.Fragment>
  // );

  return (
    <div className="login-container">
      <div className="login-transparent-layer">
        <div
          className="login-form-container"
          style={{ backgroundImage: `url(${Itad_Destruction})` }}
        ></div>

        <div
          className="login-message-container"
          style={{
            backgroundImage:
              window.innerWidth < 1024 ? `url(${Itad_Destruction})` : "none",
          }}
        >
          <img src={Logo} className="login-logo" alt="logo" />

          {/* <h1>Sign in to start your session</h1> */}

          <Card className="login-form-card">
            <form
              onSubmit={formik.handleSubmit}
              className="p-fluid login-form-card"
            >
              <h2>Login</h2>

              <div className="p-field">
                <span className="p-float-label p-input-icon-right">
                  <i className="pi pi-envelope" />
                  <InputText
                    id="emailAddress"
                    name="emailAddress"
                    value={formik.values.emailAddress}
                    onChange={formik.handleChange}
                    className={classNames({
                      "p-invalid": isFormFieldValid("emailAddress"),
                    })}
                  />
                  <label
                    htmlFor="emailAddress"
                    className={classNames({
                      "p-error": isFormFieldValid("emailAddress"),
                    })}
                  >
                    Email Address *
                  </label>
                </span>
                {getFormErrorMessage("emailAddress")}
              </div>

              <div className="p-field">
                <span className="p-float-label">
                  <Password
                    id="password"
                    name="password"
                    value={formik.values.password}
                    onChange={formik.handleChange}
                    toggleMask
                    className={classNames({
                      "p-invalid": isFormFieldValid("password"),
                    })}
                    header={passwordHeader}
                    // footer={passwordFooter}
                  />
                  <label
                    htmlFor="password"
                    className={classNames({
                      "p-error": isFormFieldValid("password"),
                    })}
                  >
                    Password *
                  </label>
                </span>
                {getFormErrorMessage("password")}
              </div>

              <div className="login-form-buttons-container">
                <Button
                  type="submit"
                  label="Sign In"
                  icon="pi pi-check"
                  loading={loading}
                />

                <Button label="Forgot Password" className="p-button-link" />
              </div>
            </form>
          </Card>

          <Messages ref={messages} onRemove={_onCloseAlert}></Messages>
        </div>
      </div>
    </div>
  );
}
