import { getStorage, ref, getDownloadURL } from "firebase/storage";
import Axios from "../config/interceptors/axiosInterceptor";

/**
 * This function is used to download a single file to firebase
 * @returns
 */
export const downloadFileFromFirebase = async (filePath: string) => {
  try {
    const storage = getStorage();
    const url = await getDownloadURL(ref(storage, filePath));

    return await Axios.get(url);
  } catch (err) {
    console.log(err);
    return {
      status: 500,
      data: null,
      statusText: "Oops! something went wrong",
    };
  }
};

export const downloadFileFromFirebaseToLocal = async (
  filePath: string,
  fileName: string
) => {
  try {
    const storage = getStorage();
    const documentUrl = await getDownloadURL(ref(storage, filePath));

    const res = await Axios({
      method: "get",
      url: documentUrl,
      responseType: "blob",
    });

    console.log(res);

    const url = window.URL.createObjectURL(new Blob([res.data]));
    const link: any = document.createElement("a");
    link.href = url;
    link.setAttribute("download", fileName);

    // Append to html link element page
    document.body.appendChild(link);

    // Start download
    link.click();

    // Clean up and remove the link
    link.parentNode.removeChild(link);

    return {
      status: 200,
      data: res.data,
    };
  } catch (err) {
    console.log(err);
    return {
      status: 500,
      data: null,
      statusText: "Oops! something went wrong",
    };
  }
};

export const previewFileFromFirebase = async (
  filePath: string,
  fileName: string
) => {
  try {
    const storage = getStorage();
    const documentUrl = await getDownloadURL(ref(storage, filePath));

    const res = await Axios({
      method: "get",
      url: documentUrl,
      responseType: "blob",
    });

    var fileURL = window.URL.createObjectURL(res.data);
    let tab: any = window.open("_blank");
    tab.location.href = fileURL;

    return {
      status: 200,
      data: res.data,
    };
  } catch (err) {
    console.log(err);
    return {
      status: 500,
      data: null,
      statusText: "Oops! something went wrong",
    };
  }
};
