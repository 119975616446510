import React, { useEffect, useState } from "react";
import {
  DirectionsRenderer,
  DirectionsService,
  GoogleMap,
  LoadScript,
  Marker,
  Polyline,
} from "@react-google-maps/api";
import { getDatabase, ref, onValue, set, child } from "firebase/database";
import {
  getAllDriverLocations,
  getDriverLatestPosition,
} from "../../services/driver.service";

const containerStyle = {
  width: "100%",
  height: "65vh",
};

const center = {
  lat: 37.4316,
  lng: -78.6569,
};

function MyComponent(props: any) {
  const [vehicleMarker, setVehicleMarker] = useState<any>(null);
  const [woDirection, setwoDirection] = useState<any>(null);
  const [workOrder, setWorkOrder] = useState<any>(null);
  const [travelMode, setTravelMode] = useState<any>("DRIVING");
  const [mapInstance, setMapInstance] = useState<any>(null);
  const [driverPathPolyline, setDriverPathPolyline] = useState<Array<any>>([]);
  const [latestDriverPosition, setLatestDriverPosition] = useState<any>();

  //Fetch location
  const db = getDatabase();

  useEffect(() => {
    if (props.selectedVehicle) {
      setVehicleMarker({
        lat: props.selectedVehicle.lat,
        lng: props.selectedVehicle.lng,
      });
      if (mapInstance) {
        mapInstance.panTo(center);
        mapInstance.setZoom(7);
      }
    } else {
      setVehicleMarker(null);
    }
  }, [props.selectedVehicle]);

  useEffect(() => {
    if (props.selectedWO) {
      setWorkOrder(props.selectedWO);
      setwoDirection(null);
      fetchDriverLocationForOrder(props.selectedWO.orderID);
    } else {
      setWorkOrder(null);
    }
  }, [props.selectedWO]);

  /**
   * track driver location for a specific order
   * @param orderId
   */
  const fetchDriverLocationForOrder = async (orderId: Number) => {
    await setDriverPath(orderId);
    onValue(
      ref(
        db,
        `${process.env.REACT_APP_FIREBASE_DATABASE_DRIVER_LOCATION_PATH}/${orderId}`
      ),
      async () => {
        await setDriverPath(orderId);
      }
    );
  };

  /**
   * sets the location of the driver location from the api.
   * @param orderId
   */
  const setDriverPath = async (orderId: Number) => {
    const driverPolylineResponse = await getAllDriverLocations(orderId);
    const latestDriverPosition = await getDriverLatestPosition(orderId);
    if (driverPolylineResponse.status === 200) {
      const path = driverPolylineResponse.data.map((polylinePath: any) => ({
        lat: Number(polylinePath.latitude),
        lng: Number(polylinePath.longitude),
      }));
      setDriverPathPolyline(path || []);
    }
    if (latestDriverPosition.status === 200) {
      const latestPath = {
        lat: Number(latestDriverPosition.data.latitude),
        lng: Number(latestDriverPosition.data.longitude),
      };
      setLatestDriverPosition(latestPath);
    }
  };

  const onLoad = React.useCallback(function onLoad(mapInstance) {
    // do something with map
    setMapInstance(mapInstance);
  }, []);

  const directionsCallback = (response: any) => {
    if (response !== null) {
      if (response.status === "OK") {
        setwoDirection(response);
        props.setWarning(false);
      } else {
        props.setWarning(true);
      }
    }
  };

  return (
    <LoadScript googleMapsApiKey="AIzaSyCr3-1Zp_KYKiQz5w3QCAEV_GsG5SuVzYI">
      <GoogleMap
        mapContainerStyle={containerStyle}
        center={center}
        zoom={7}
        onLoad={onLoad}
      >
        {/* Child components, such as markers, info windows, etc. */}
        <>
          {vehicleMarker && (
            <Marker
              position={vehicleMarker}
              label={{
                text: "\ue558",
                fontFamily: "Material Icons",
                color: "#ffffff",
                fontSize: "18px",
              }}
            />
          )}
          {workOrder && woDirection === null && (
            <DirectionsService
              // required
              options={{
                destination: workOrder.pickUp || "Stamford, CT",
                origin: workOrder.destination || "Princeton, LA",
                travelMode: travelMode,
              }}
              // required
              callback={directionsCallback}
              // optional
              // onLoad={(directionsService: any) => {
              //   console.log('DirectionsService onLoad directionsService: ', directionsService)
              // }}
              // optional
              // onUnmount={(directionsService: any) => {
              //   console.log('DirectionsService onUnmount directionsService: ', directionsService)
              // }}
            />
          )}

          {woDirection && workOrder !== null && (
            <>
              <DirectionsRenderer
                // required
                options={{
                  directions: woDirection,
                }}
                // optional
                // onLoad={(directionsRenderer: any) => {
                //   console.log('DirectionsRenderer onLoad directionsRenderer: ', directionsRenderer)
                // }}
                // optional
                // onUnmount={(directionsRenderer: any) => {
                //   console.log('DirectionsRenderer onUnmount directionsRenderer: ', directionsRenderer)
                // }}
              />
              <Polyline
                path={driverPathPolyline}
                options={{
                  strokeColor: "#FF0000",
                  strokeOpacity: 0.8,
                  strokeWeight: 2,
                  clickable: false,
                  draggable: false,
                  editable: false,
                  visible: true,
                  zIndex: 1,
                }}
              />
              <Marker
                position={latestDriverPosition}
                label={{
                  text: "\ue558",
                  fontFamily: "Material Icons",
                  color: "#ffffff",
                  fontSize: "18px",
                }}
              />
            </>
          )}
        </>
      </GoogleMap>
    </LoadScript>
  );
}
export default React.memo(MyComponent);
