export const validateRequired = (value: String) =>
  value ? undefined : "This field is required";
export const confirmPasswordNotMatched = (
  password: string,
  confirmPassword: string
) => {
  if (password !== confirmPassword) {
    return "Password and confirm password does not match.";
  }
  return undefined;
};
export const validateLength = (value: string, length: number) =>
  value.length >= length
    ? undefined
    : `This field must be ${length} characters long.`;
export const emailValidator = (email: string) => {
  const re = /\S+@\S+\.\S+/;

  if (!email || email.length <= 0) return "Email cannot be empty.";
  if (!re.test(email)) return "Please enter a valid email address";

  return undefined;
};
