import React, { useState, useRef, useEffect } from "react";
import { Paper, Typography } from "@material-ui/core";
import { Button } from "primereact/button";
import { TieredMenu } from "primereact/tieredmenu";
import { getDatabase, ref, onValue } from "firebase/database";

import { getMessageGroups } from "../../services/message.service";
import ChatBox from "./ChatBox";

export default function MessageViewer() {
  const [currentMessageGroup, setCurrentMessageGroup] = useState(null);
  const [currentGroupSelected, setCurrentGroupSelected] = useState<
    "ACTIVE" | "ARCHIVED"
  >("ACTIVE");
  const [messageGroups, setMessageGroups] = useState([]);

  const menus = Array(100).fill(useRef());

  useEffect(() => {
    const db = getDatabase();

    onValue(
      ref(db, `${process.env.REACT_APP_FIREBASE_MESSAGE_ROUTEIN_DATABSE}/`),
      (snapshot) => {
        if (
          snapshot?.val() &&
          Object.values(snapshot.val()).length > messageGroups?.length
        ) {
          getMessageGroups(currentGroupSelected).then((res) => {
            if (res.status === 200) {
              setMessageGroups(res.data);
            }
          });
        } else {
          setMessageGroups([]);
        }
      }
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentGroupSelected]);

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        marginBottom: "2rem",
        height: "70vh",
      }}
    >
      <Paper
        style={{
          display: "flex",
          flexDirection: "column",
          height: "70vh",
          marginRight: "1rem",
          width: "30%",
          padding: "1rem",
          overflowY: "auto",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "space-between",
            marginBottom: "0.5rem",
          }}
        >
          <Typography variant="h6">Messages</Typography>
        </div>

        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            marginBottom: "0.5rem",
          }}
        >
          <Button
            label="Inbox"
            className={`p-button-text ${
              currentGroupSelected === "ACTIVE" ? "" : "p-button-secondary"
            }`}
            icon="pi pi-inbox"
            style={{
              width: "100%",
            }}
            onClick={() => {
              setCurrentGroupSelected("ACTIVE");
            }}
          />

          <Button
            label="Archived"
            icon="pi pi-briefcase"
            className={`p-button-text ${
              currentGroupSelected !== "ACTIVE" ? "" : "p-button-secondary"
            }`}
            style={{
              width: "100%",
            }}
            onClick={() => {
              setCurrentGroupSelected("ARCHIVED");
            }}
          />
        </div>

        {messageGroups.map((messagegroup: any, index) => (
          <div
            key={index}
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "space-between",
              border: "1px solid lightgrey",
              borderRadius: "5px",
              marginBottom: "1rem",
              paddingLeft: "1rem",
              cursor: "pointer",
            }}
            onClick={(e) => {
              e.stopPropagation();
              setCurrentMessageGroup(messagegroup);
            }}
          >
            <div
              style={{
                padding: "0.5rem 0",
              }}
            >
              <Typography variant="subtitle2">
                {messagegroup.subject}
              </Typography>
              <small>
                {messagegroup.participants.length <= 2
                  ? messagegroup.participants.reduce(
                      (a: any, c: any, currentIndex: any) =>
                        a +
                        (currentIndex === 0 ||
                        currentIndex === messagegroup.participants.length
                          ? ""
                          : ", ") +
                        c,
                      ""
                    )
                  : `${messagegroup.participants[0]}, ${
                      messagegroup.participants[1]
                    } +${messagegroup.participants.length - 2}`}
              </small>
            </div>

            <TieredMenu
              model={[
                {
                  label: "Move to Archived",
                  icon: "pi pi-briefcase",
                  command: () => {},
                },
              ]}
              popup
              ref={menus[index]}
            />

            <Button
              icon="pi pi-ellipsis-v"
              className="p-button-info p-button-text"
              style={{
                padding: "1.5rem 0",
              }}
              onClick={(e) => {
                e.stopPropagation();
                menus?.[index]?.current?.toggle(e);
              }}
            />
          </div>
        ))}
      </Paper>

      <ChatBox currentMessageGroup={currentMessageGroup} />
    </div>
  );
}
