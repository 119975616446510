import * as React from "react";
import { connect } from "react-redux";
import Paper from "@material-ui/core/Paper";
// import ButtonGroup from "@material-ui/core/ButtonGroup";
// import Button from "@material-ui/core/Button";
import {
  withStyles,
  StyledComponentProps,
  alpha,
} from "@material-ui/core/styles";
import { teal, orange, red } from "@material-ui/core/colors";
// import classNames from "clsx";
import { ViewState } from "@devexpress/dx-react-scheduler";
import {
  Scheduler,
  WeekView,
  Toolbar,
  DateNavigator,
  Appointments,
  DayView,
  ViewSwitcher,
  Resources,
} from "@devexpress/dx-react-scheduler-material-ui";
import { createSchedulerAction } from "../../../redux/scheduler/actions";
import moment from "moment";

export const LOCATIONS = ["Truck 1", "Truck 2", "Truck 3"];
export const LOCATIONS_SHORT = [1, 2, 3];
export const resources = [
  {
    fieldName: "location",
    title: "Location",
    instances: [
      { id: LOCATIONS[0], text: LOCATIONS[0], color: teal },
      { id: LOCATIONS[1], text: LOCATIONS[1], color: orange },
      { id: LOCATIONS[2], text: LOCATIONS[2], color: red },
    ],
  },
];

const styles: any = ({ spacing, palette }: any) => ({
  flexibleSpace: {
    margin: "0 auto 0 0",
    display: "flex",
    alignItems: "center",
  },
  textField: {
    width: "75px",
    marginLeft: spacing(1),
    marginTop: 0,
    marginBottom: 0,
    height: spacing(4.875),
  },
  locationSelector: {
    marginLeft: spacing(1),
    height: spacing(4.875),
  },
  button: {
    paddingLeft: spacing(1),
    paddingRight: spacing(1),
    width: spacing(14),
    "@media (max-width: 800px)": {
      width: spacing(1),
      fontSize: "0.75rem",
    },
  },
  selectedButton: {
    background: palette.primary[400],
    color: palette.primary[50],
    "&:hover": {
      backgroundColor: palette.primary[500],
    },
    border: `1px solid ${palette.primary[400]}!important`,
    borderLeft: `1px solid ${palette.primary[50]}!important`,
    "&:first-child": {
      borderLeft: `1px solid ${palette.primary[50]}!important`,
    },
  },
  longButtonText: {
    "@media (max-width: 800px)": {
      display: "none",
    },
  },
  shortButtonText: {
    "@media (min-width: 800px)": {
      display: "none",
    },
  },
  title: {
    fontWeight: "bold",
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
  },
  textContainer: {
    lineHeight: 1,
    whiteSpace: "pre-wrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    width: "100%",
  },
  time: {
    display: "inline-block",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  text: {
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
  },
  container: {
    width: "100%",
  },
  weekendCell: {
    backgroundColor: alpha(palette.action.disabledBackground, 0.04),
    "&:hover": {
      backgroundColor: alpha(palette.action.disabledBackground, 0.04),
    },
    "&:focus": {
      backgroundColor: alpha(palette.action.disabledBackground, 0.04),
    },
  },
  weekEnd: {
    backgroundColor: alpha(palette.action.disabledBackground, 0.06),
  },
});

const AppointmentContent = withStyles(styles, { name: "AppointmentContent" })(
  ({
    classes,
    data,
    formatDate,
    ...restProps
  }: Appointments.AppointmentContentProps & StyledComponentProps) => (
    <Appointments.AppointmentContent
      {...restProps}
      formatDate={formatDate}
      data={data}
    >
      <div className={classes?.container}>
        <div className={classes?.title}>{data.title}</div>
        <div className={classes?.text}>{data.location}</div>
        <div className={classes?.textContainer}>
          <div className={classes?.time}>
            {formatDate(data.startDate, { hour: "numeric", minute: "numeric" })}
          </div>
          <div className={classes?.time}>{" - "}</div>
          <div className={classes?.time}>
            {formatDate(data.endDate, { hour: "numeric", minute: "numeric" })}
          </div>
        </div>
      </div>
    </Appointments.AppointmentContent>
  )
);

// const handleButtonClick = (locationName: any, locations: any) => {
//   if (locations.indexOf(locationName) > -1) {
//     return locations.filter((location: any) => location !== locationName);
//   }
//   const nextLocations = [...locations];
//   nextLocations.push(locationName);
//   return nextLocations;
// };

// const getButtonClass = (locations: any, classes: any, location: any) =>
//   locations.indexOf(location) > -1 && classes.selectedButton;

// const LocationSelector = withStyles(styles, { name: "LocationSelector" })(
//   ({ onLocationsChange, locations, classes }: any) => (
//     <ButtonGroup className={classes.locationSelector}>
//       {LOCATIONS.map((location, index) => (
//         <Button
//           className={classNames(
//             classes.button,
//             getButtonClass(locations, classes, location)
//           )}
//           onClick={() =>
//             onLocationsChange(handleButtonClick(location, locations))
//           }
//           key={location}
//         >
//           <React.Fragment>
//             <span className={classes.shortButtonText}>
//               {LOCATIONS_SHORT[index]}
//             </span>
//             <span className={classes.longButtonText}>{location}</span>
//           </React.Fragment>
//         </Button>
//       ))}
//     </ButtonGroup>
//   )
// );

const mapStateToProps = (state: any) => {
  let data = state.schedulerReducer.data.filter(
    (dataItem: any) =>
      state.schedulerReducer.locations.indexOf(dataItem.location) > -1
  );
  const lowerCaseFilter = state.schedulerReducer.currentFilter.toLowerCase();
  data = data.filter(
    (dataItem: any) =>
      dataItem.title.toLowerCase().includes(lowerCaseFilter) ||
      dataItem.location.toLowerCase().includes(lowerCaseFilter)
  );
  return { ...state.schedulerReducer, data };
};

const mapDispatchToProps = (dispatch: any) => ({
  onCurrentDateChange: (currentDate: any) =>
    dispatch(
      createSchedulerAction(
        "currentDate",
        moment(currentDate).format("YYYY-MM-DD")
      )
    ),
  onCurrentViewNameChange: (currentViewName: any) =>
    dispatch(createSchedulerAction("currentViewName", currentViewName)),
  onCurrentFilterChange: (currentFilter: any) =>
    dispatch(createSchedulerAction("currentFilter", currentFilter)),
  onLocationsChange: (locations: any) =>
    dispatch(createSchedulerAction("locations", locations)),
});

// const ReduxLocationSelector = connect(
//   mapStateToProps,
//   mapDispatchToProps
// )(LocationSelector);

const FlexibleSpace = withStyles(styles, { name: "FlexibleSpace" })(
  ({
    classes,
    ...restProps
  }: Toolbar.FlexibleSpaceProps & StyledComponentProps) => (
    <Toolbar.FlexibleSpace {...restProps} className={classes?.flexibleSpace}>
      {/* <ReduxLocationSelector /> */}
    </Toolbar.FlexibleSpace>
  )
);

const isRestTime = (date: any) =>
  date.getDay() === 0 ||
  date.getDay() === 6 ||
  date.getHours() < 8 ||
  date.getHours() >= 17;

const TimeTableCell = withStyles(styles, { name: "TimeTableCell" })(
  ({
    classes,
    ...restProps
  }: WeekView.TimeTableCellProps & StyledComponentProps) => {
    const { startDate } = restProps;
    if (isRestTime(startDate)) {
      return (
        <WeekView.TimeTableCell
          {...restProps}
          className={classes?.weekendCell}
        />
      );
    }
    return <WeekView.TimeTableCell {...restProps} />;
  }
);

const DayScaleCell = withStyles(styles, { name: "DayScaleCell" })(
  ({
    classes,
    ...restProps
  }: WeekView.DayScaleCellProps & StyledComponentProps) => {
    const { startDate } = restProps;
    if (startDate.getDay() === 0 || startDate.getDay() === 6) {
      return (
        <WeekView.DayScaleCell {...restProps} className={classes?.weekEnd} />
      );
    }
    return <WeekView.DayScaleCell {...restProps} />;
  }
);

function SchedulerContainer({
  data,
  currentDate,
  onCurrentDateChange,
  currentViewName,
  onCurrentViewNameChange,
}: any) {
  return (
    <Paper>
      <Scheduler data={data}>
        <ViewState
          currentDate={currentDate}
          onCurrentDateChange={onCurrentDateChange}
          currentViewName={currentViewName}
          onCurrentViewNameChange={onCurrentViewNameChange}
        />
        <DayView startDayHour={8} endDayHour={18} />
        <WeekView
          startDayHour={8}
          endDayHour={18}
          timeTableCellComponent={TimeTableCell}
          dayScaleCellComponent={DayScaleCell}
        />

        <Appointments appointmentContentComponent={AppointmentContent} />
        <Resources data={resources} />

        <Toolbar flexibleSpaceComponent={FlexibleSpace} />
        <DateNavigator />
        <ViewSwitcher />
      </Scheduler>
    </Paper>
  );
}

const ReduxSchedulerContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(SchedulerContainer);

export default ReduxSchedulerContainer;
