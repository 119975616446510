import { AnyAction } from "redux";
import { ThunkAction } from "@reduxjs/toolkit";

import * as adminType from "./types";
import { getAllAdmins } from "../../services/admin.service";

const types = {
  ...adminType,
};


/**
 * Action to fetch the admins.
 * @param searchText 
 * @param pageNumber 
 * @param pageSize 
 * @returns 
 */
export const fetchAllAdmins =
(searchText: string = '', pageNumber: number = 1,pageSize: number = 10): ThunkAction<void, any, unknown, AnyAction> =>
async (dispatch): Promise<void> => {
    const allAdminResponse = await getAllAdmins(searchText, pageNumber, pageSize);
    if (allAdminResponse.status === 200) {
        dispatch({
            type: types.FETCH_ALL_ADMIN_SUCCESS,
            payload: allAdminResponse.data
        });
    } else {
        dispatch({
            type: types.FETCH_ALL_ADMIN_ERROR,
            payload: []
        }); 
    }
};